import { Control } from 'react-hook-form';
import React from 'react';
import PlayerProfile from './PlayerProfile/PlayerProfile';
import PaymentDetails from './PaymentDetails/PaymentDetails';
import Profile from './Profile/Profile';
import TransactionSummary from './TransactionSummary/TransactionSummary';

export const generateTitle = (title: string) => {
    switch (title) {
        case 'name_on_card':
            return 'Name on card';
        case 'postal_code':
            return 'Postal Code';
        case 'date_of_birth':
            return 'Date of Birth';
        default:
            return title.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => {
                return str.toUpperCase();
            });
    }
};

export const getComponent = (
    step: number,
    controls: Control[],
    handleNextStep: () => void
) => {
    switch (step) {
        case 0:
            return (
                <PlayerProfile
                    control={controls[0]}
                    handleOnSubmit={handleNextStep}
                />
            );
        case 1:
            return (
                <PaymentDetails
                    control={controls[1]}
                    handleOnSubmit={handleNextStep}
                />
            );
        case 2:
            return <Profile />;
        case 3:
            return <TransactionSummary />;
        default:
            return null;
    }
};

export const stepItems = (currentStep: number) => {
    return [
        {
            title: 'Player Profile',
            status: currentStep === 0 ? 'process' : 'wait',
        },
        {
            title: 'Payment Details',
            status: currentStep === 1 ? 'process' : 'wait',
        },
        {
            title: 'Profile',
            status: currentStep === 2 ? 'process' : 'wait',
        },
        {
            title: 'Transaction Summary',
            status: currentStep === 3 ? 'process' : 'wait',
        },
    ];
};
