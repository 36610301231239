import { ISwitchProp } from '../common';
import './switch.css';

const Switch = ({
    className,
    isToggled,
    handleCheck,
    disabled,
    isRuleEngine,
    isSideMenu,
    isActiveSwitch,
    ...props
}: ISwitchProp) => (
    <label
        className={`${className as string} toggle-switch ${
            isSideMenu ? 'toggle-switch-side-menu' : ''
        } ${isRuleEngine ? 'toggle-switch-rule' : ' '} `}
    >
        <input
            onClick={(e) => e.stopPropagation()}
            type="checkbox"
            onChange={handleCheck}
            className={className}
            checked={isToggled}
            disabled={disabled}
            {...props}
        />
        <span
            onClick={(e) => e.stopPropagation()}
            className={`${className as string} switch ${
                isRuleEngine ? 'switch-rule' : ''
            } ${isActiveSwitch ? 'active-switch' : ''}`}
        />
    </label>
);

export default Switch;
