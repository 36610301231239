import dayjs from 'dayjs';
import CryptoJS from 'crypto-js';
import utc from 'dayjs/plugin/utc';
import { IBrandsPermissions, IPagePermissions } from '../common';
import {
    CardConstant,
    CardConstantLabel,
} from '../constants/payment.constants';
import {
    TransactionTypeEnum,
    TransactionTypeUppercaseEnum,
} from '../../components/OnBoardCashier/OnBoardCashier.type';
import {
    TransactionStateEnum,
    TransactionStateEnumLabel,
} from '../../components/RuleEngine/RuleEngine.type';
import { CurrenciesConstant, UserRoles } from '../constants/common.constants';

export const validate = (
    key: string,
    value: string | null | IPagePermissions[] | IBrandsPermissions[] | string[]
) => {
    const emailRegx = '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';

    switch (key) {
        case 'email':
            if (!value) {
                return 'Email is required';
            }
            if (!new RegExp(emailRegx).test(value as string)) {
                return 'Please check the email address';
            }
            return '';
        case 'companyAdminEmail':
            if (!value) {
                return 'Please enter Company Email-address';
            }
            if (!new RegExp(emailRegx).test(value as string)) {
                return 'Please check the Email address';
            }
            return '';
        case 'role':
            if (!value) {
                return 'Please select user role';
            }
            return '';
        case 'paymentMethod':
            if (!value) {
                return 'Please select payment method';
            }
            return '';
        case 'companyTelephone':
            if (!value) {
                return 'Please enter company Phone number';
            }
            return '';

        case 'password':
            if (!value) {
                return 'Password is required';
            }
            return '';
        case 'job':
            if (!value) {
                return 'job is required';
            }
            return '';

        case 'confirmPassword':
            if (!value) {
                return 'confirmPassword is required';
            }
            return '';

        case 'name':
            if (!value) {
                return 'Please enter  name';
            }
            return '';
        case 'emailVerification':
            if (!value) {
                return 'Email Verification is required';
            }
            return '';
        case 'verificationDate':
            if (!value) {
                return 'Verification Date is required';
            }
            return '';

        case 'partnerName':
            if (!value) {
                return 'Partner Name is required';
            }
            return '';

        case 'userBalance':
            if (!value) {
                return 'User Balance is required';
            }
            return '';
        case 'firstName':
            if (!value) {
                return 'Please enter  first name';
            }
            return '';
        case 'lastName':
            if (!value) {
                return 'Please enter  last name';
            }
            return '';
        case 'groupName':
            if (value === '') {
                return 'Please enter your group name';
            }
            return '';

        case 'phone':
            if (!value) {
                return 'Please enter your phone number';
            }
            return '';
        case 'dateOfBirth':
            if (!value) {
                return 'Please enter your birthDate';
            }
            return '';
        case 'line1':
            if (!value) {
                return 'Please enter your line1';
            }
            return '';
        case 'postalcode':
            if (!value) {
                return 'Please enter your postalcode';
            }
            return '';
        case 'zipcode':
            if (!value) {
                return 'Please enter your zipcode';
            }
            return '';
        case 'countryCode':
            if (!value) {
                return 'Please enter your country code';
            }
            return '';
        case 'address':
            if (!value) {
                return 'Please enter your address';
            }
            return '';
        case 'city':
            if (!value) {
                return 'Please enter your city';
            }
            return '';
        case 'state':
            if (!value) {
                return 'Please enter your state';
            }
            return '';
        case 'region':
            if (!value) {
                return 'Please enter your region';
            }
            return '';
        case 'country':
            if (!value) {
                return 'Please enter your country';
            }
            return '';
        case 'gender':
            if (!value) {
                return 'Please enter your gender';
            }
            return '';

        case 'brandsPermissions':
            if ((value as IBrandsPermissions[])?.length < 1) {
                return 'Please select your Brands';
            }
            return '';

        case 'brandId':
            if (!value) {
                return 'Please select your Brand';
            }
            return '';

        case 'company':
            if (!value) {
                return 'Please select your company';
            }
            return '';
        case 'group':
            if (value === '') {
                return 'Please select your group';
            }
            return '';
        case 'superAdminCompany':
            if (value === '' || value === undefined) {
                return 'Please select your admin Company';
            }
            return '';

        case 'adminUser':
            if (value === '') {
                return 'Please select your Company admin';
            }
            return '';

        case 'pageAreasPermissions':
            if ((value as IPagePermissions[])?.length < 1) {
                return 'Please select your pageAreas';
            }
            return '';

        case 'companyName':
            if (!value) {
                return 'Please enter company name';
            }
            return '';
        case 'companyAdminFirstName':
            if (!value) {
                return 'Please enter company Admin-First Name';
            }
            return '';
        case 'companyAdminLastName':
            if (!value) {
                return 'Please enter company Admin-Last Name';
            }
            return '';

        case 'description':
            if (!value) {
                return 'Please enter company description';
            }
            return '';
        case 'brandName':
            if (!value) {
                return 'Please enter Brand name';
            }
            return '';

        case 'pspType':
            if (!value) {
                return 'Please enter pspType';
            }
            return '';

        case 'locals':
            if (!value) {
                return 'Please enter locals';
            }
            return '';
        case 'expiry':
            if (!value) {
                return 'Please enter expiry';
            }
            return '';
        case 'amount':
            if (!value) {
                return 'Please enter amount';
            }
            return '';
        case 'total':
            if (!value) {
                return 'Please enter total';
            }
            return '';
        case 'card_number':
            if (!value) {
                if (value?.length === 12) {
                    return 'Please enter 12 card number';
                }
                return 'Please enter card number';
            }
            return '';
        case 'cvv':
            if (!value) {
                if (value?.length === 12) {
                    return 'Please enter 3 cvv number';
                }
                return 'Please enter cvv';
            }
            return '';

        case 'providerTransactionId':
            if (!value) {
                return 'Please select your providerTransactionId';
            }
            return '';

        case 'currencies':
            if (!value || value.length === 0) {
                return 'Please enter currency';
            }
            return '';
        case 'currency':
            if (!value) {
                return 'Please enter currency';
            }
            return '';
        case 'merchant':
            if (!value) {
                return 'Please enter merchant';
            }
            return '';
        case 'prefix':
            if (!value) {
                return 'Please enter prefix';
            }
            return '';
        case 'countries':
            if (!value || value.length === 0) {
                return 'Please enter country';
            }
            return '';
        case 'comment':
            if (!value || value.length === 0) {
                return 'Please enter comment';
            }
            return '';
        default:
            return '';
    }
};

export const get = (obj: Record<string, any>, path: string, def?: any): any => {
    const fullPath = path
        .replace(/\[/g, '.')
        .replace(/]/g, '')
        .split('.')
        .filter(Boolean);

    if (obj == null) {
        return def || null;
    }

    function everyFunc(step: string): boolean | null {
        if (obj[step] !== null) {
            // eslint-disable-next-line no-param-reassign
            return !(step && (obj = obj[step]) === undefined);
        }
        return null;
    }

    return fullPath.every(everyFunc) ? obj : def;
};

export const getDateFormat = (dateValue: string) => {
    let dateObj;

    // Check if the date is in the "DD-MM-YYYY" format
    if (/^\d{2}-\d{2}-\d{4}$/.test(dateValue)) {
        const [day, month, year] = dateValue.split('-');
        dateObj = new Date(`${year}-${month}-${day}`);
    } else {
        // Assume it's in ISO format
        dateObj = new Date(dateValue);
    }

    const date = dateObj.getDate();
    const month = dateObj.getMonth() + 1; // getMonth() is zero-based
    const year = dateObj.getFullYear();
    return [
        month.toString().padStart(2, '0'),
        date.toString().padStart(2, '0'),
        year,
    ].join('/');
};

dayjs.extend(utc);

export const getDateFormats = (dateValue: string) => {
    const utcDate = dayjs.utc(dateValue);
    const utcDay = utcDate.format('DD');
    const utcMonth = utcDate.format('MM');
    const utcYear = utcDate.format('YYYY');

    return `${utcMonth}/${utcDay}/${utcYear}`;
};

export const getFirstDateFormat = (dateValue: string) => {
    const utcDate = dayjs.utc(dateValue);
    const utcDay = utcDate.format('DD');
    const utcMonth = utcDate.format('MM');
    const utcYear = utcDate.format('YYYY');

    return `${utcDay}/${utcMonth}/${utcYear}`;
};

export const formatNumber = (number: number, round?: number): string => {
    if (!number || Number.isNaN(number)) {
        return '0.00';
    }
    const roundDecimal = round ?? 2;
    return number.toFixed(roundDecimal);
};

export const isAdmin = (role: UserRoles | null): boolean =>
    role === UserRoles.ADMIN;
export const isUser = (role: UserRoles | null): boolean =>
    role === UserRoles.USER;
export const isSuperAdmin = (role: UserRoles | null): boolean =>
    role === UserRoles.SUPER_ADMIN;

export const getCurrencySign = (currency: CurrenciesConstant | 'EURO') => {
    switch (currency) {
        case CurrenciesConstant.CLP:
            return 'CLP';
        case CurrenciesConstant.EURO:
            return '€';
        case CurrenciesConstant.USD:
            return '$';
        default:
            return '';
    }
};

export const getDecryptValue = (value: string): string | null => {
    if (!value) {
        console.warn('No value provided for decryption');
        return null;
    }

    const encryptionKey = process.env.REACT_APP_ENCRYPTED_KEY;

    if (!encryptionKey) {
        console.error('Encryption key is missing in environment variables');
        return null;
    }

    try {
        const bytes = CryptoJS.AES.decrypt(value, encryptionKey);
        const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);

        if (!decryptedValue) {
            console.warn(
                'Failed to decrypt value: result is empty or incorrect'
            );
            return null;
        }

        return decryptedValue;
    } catch (error) {
        console.error('Error decrypting value:', error);
        return null;
    }
};

export const getListName = (list: string) => {
    switch (list) {
        case 'DEPOSIT':
            return 'Deposit';
        case 'WITHDRAWAL':
            return 'Withdrawal';
        case CardConstant.MASTERCARD:
            return CardConstantLabel.MASTERCARD;
        case CardConstant.VISA:
            return CardConstantLabel.VISA;
        case CardConstant.AMERICAN_EXPRESS:
            return CardConstantLabel.AMERICAN_EXPRESS;
        case CardConstant.DINERS_CLUB:
            return CardConstantLabel.DINERS_CLUB;
        case CardConstant.DISCOVER:
            return CardConstantLabel.DISCOVER;
        case CardConstant.JCB:
            return CardConstantLabel.JCB;
        case CardConstant.UNIONPAY:
            return CardConstantLabel.UNIONPAY;
        case CardConstant.MAESTRO:
            return CardConstantLabel.MAESTRO;
        case CardConstant.ELO:
            return CardConstantLabel.ELO;
        case CardConstant.MIR:
            return CardConstantLabel.MIR;
        case CardConstant.HIPER:
            return CardConstantLabel.HIPER;
        case CardConstant.HIPERCARD:
            return CardConstantLabel.HIPERCARD;
        case TransactionTypeUppercaseEnum.CREDIT_CARD_DEPOSIT:
            return TransactionTypeEnum.CREDIT_CARD_DEPOSIT;
        case TransactionTypeUppercaseEnum.CREDIT_CARD_WITHDRAWALS:
            return TransactionTypeEnum.CREDIT_CARD_WITHDRAWALS;
        case TransactionTypeUppercaseEnum.WEB_REDIRECT_DEPOSIT:
            return TransactionTypeEnum.WEB_REDIRECT_DEPOSIT;
        case TransactionTypeUppercaseEnum.WEB_REDIRECT_WITHDRAWALS:
            return TransactionTypeEnum.WEB_REDIRECT_WITHDRAWALS;
        case TransactionTypeUppercaseEnum.LOCAL_BANK_WITHDRAWALS:
            return TransactionTypeEnum.LOCAL_BANK_WITHDRAWALS;
        case TransactionTypeUppercaseEnum.BANK_WITHDRAWAL:
            return TransactionTypeEnum.BANK_WITHDRAWAL;
        case TransactionStateEnumLabel.ALL_PAYMENTS:
            return TransactionStateEnum.ALL_PAYMENTS;
        case TransactionStateEnumLabel.SUSPENDED:
            return TransactionStateEnum.SUSPENDED;
        case TransactionStateEnumLabel.PENDING:
            return TransactionStateEnum.PENDING;
        case TransactionStateEnumLabel.SUCCESS:
            return TransactionStateEnum.SUCCESS;
        case TransactionStateEnumLabel.EXPIRED:
            return TransactionStateEnum.EXPIRED;
        case TransactionStateEnumLabel.FAILED:
            return TransactionStateEnum.FAILED;
        case TransactionStateEnumLabel.ERROR:
            return TransactionStateEnum.ERROR;
        case TransactionStateEnumLabel.CAPTURED:
            return TransactionStateEnum.CAPTURED;
        default:
            return list;
    }
};

export const formatCamelCase = (key: string) => {
    return key
        .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space before uppercase letters
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
};
