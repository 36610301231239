import { showToast } from 'data/utils/toast';
import React, { useEffect, useState } from 'react';
import { Clipboard, Loader } from 'components/Common';
import { getApiKeys } from 'services/api/api';
import { useAuthContext } from 'context/Auth/AuthContext';
import { IGetApiKeysPayload } from 'data/types/request';

interface IApiKeys {
    api_key?: string;
    secret_key?: string;
    token?: string;
}

const ApiKeys = () => {
    const { auth } = useAuthContext();

    const [isLoading, setIsLoading] = useState(false);
    const [apiKeys, setApiKeys] = useState<IApiKeys>({});

    const getKeys = () => {
        setIsLoading(true);
        const payload: IGetApiKeysPayload = {};
        if (auth.selectedBrand?._id) {
            payload.brandId = auth?.selectedBrand?._id;
        } else if (auth.selectedCompany?._id) {
            payload.companyId = auth?.selectedCompany?._id;
        }
        getApiKeys({ payload })
            .then((res) => {
                setApiKeys?.(res?.keys);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    useEffect(() => {
        getKeys();
    }, [auth?.selectedCompany, auth?.selectedBrand]);

    // const generateCompanyKeys = () => {
    //     setIsLoading(true);
    //     if (auth?.isSuperAdmin && !auth?.selectedCompany?._id) {
    //         showToast('select company first', 'error');
    //         setIsLoading(false);
    //         return;
    //     }
    //     generateKeys(
    //         (auth?.authUser?.company as string) || auth?.selectedCompany?._id
    //     )
    //         .then((res: any) => {
    //             setApiKeys?.({
    //                 api_key: res?.response?.api_key,
    //                 secret_key: res?.response?.secret_key,
    //                 token: res?.response?.token,
    //             });
    //             setIsLoading(false);
    //         })
    //         .catch((err: any) => {
    //             setIsLoading(false);
    //             showToast(
    //                 err?.errors?.[0]?.message || 'something went wrong',
    //                 'error'
    //             );
    //         });
    // };

    return (
        <div className="p-5">
            {auth?.selectedCompany?._id || auth?.selectedBrand ? (
                !isLoading ? (
                    <>
                        <div className="font-merri-bold text-start pb-3 pt-2 font-merri-bold tracking-tighter text-2xl text-[#131119]">
                            API Credentials
                        </div>
                        <Clipboard
                            text={apiKeys?.token}
                            hidden
                            isPassword
                            title="Token"
                        />
                        <Clipboard text={apiKeys?.api_key} title="Api Key" />
                        <Clipboard
                            text={apiKeys?.secret_key}
                            title="Secret Key"
                        />
                    </>
                ) : (
                    <Loader />
                )
            ) : (
                <div className="flex items-center justify-center py-10 text-center">
                    <div className="flex flex-col gap-6">
                        <div className="text-4xl font-poppins-bold tracking-tight text-gray-500">
                            Please select company or brand
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ApiKeys;
