import { z, ZodType } from 'zod';
import { IBrands } from './Brads.type';

const commonSchema = z
    .object({
        name: z
            .string({ required_error: 'Brand name is required' })
            .trim()
            .min(1, 'Brand name is required'),
        superAdminCompany: z
            .string({
                required_error: 'Select your Company',
            })
            .optional()
            .nullable(),
        adminUser: z.string({ required_error: 'Company admin is required' }),
        pspType: z.string({ required_error: 'pspType is required' }),
        locals: z
            .string({ required_error: 'Locals is required' })
            .trim()
            .min(1, 'Locals is required'),
        countries: z
            .array(z.string({ required_error: 'Select your countries' }))
            .min(1, { message: 'At least one country must be selected' }), // Minimum length of 1
        currencies: z
            .array(z.string({ required_error: 'Select your currency' }))
            .min(1, { message: 'At least one currency must be selected' }),
        description: z.string().optional().nullable(),
    })
    .required();

export const formSchemaValidation = (
    isSuperAdmin: boolean
): ZodType<IBrands> => {
    if (!isSuperAdmin) {
        return commonSchema.required();
    }

    return commonSchema
        .extend({
            superAdminCompany: z
                .string({ required_error: 'Select your Company' })
                .refine((val) => val !== '', {
                    message: 'Select your Company is required',
                    path: ['superAdminCompany'],
                }),
        })
        .required();
};
