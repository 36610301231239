import { RFSelectField } from 'components/Common';
import React, { useEffect, useState } from 'react';
import { PaymentProviderConstant } from 'data/constants/payment.constants';
import {
    IPaymentProvidersOptions,
    PaymentMethodEnum,
    PaymentProviderOptions,
    TransactionTypeEnum,
    transactionTypeOptions,
} from '../OnBoardCashier.type';
import CardDetails from '../../PaymentMethods/Simulator/Common/CardDetails/CardDetails';

const PaymentDetails = ({
    control,
    handleOnSubmit,
}: {
    control: any;
    handleOnSubmit: () => void;
}) => {
    const [isValidate, setIsValidate] = useState(false);
    const [filteredPaymentProviders, setFilteredPaymentProviders] = useState<
        IPaymentProvidersOptions[]
    >([]);
    const paymentMethodValue = control.watch('pspType');

    useEffect(() => {
        const { transactionType, pspType } = control.watch();
        const isValid =
            transactionType &&
            pspType &&
            transactionType !== TransactionTypeEnum.SELECT_TRANSACTION_TYPE &&
            pspType !== PaymentMethodEnum.SELECT_PAYMENT_METHOD;

        setIsValidate(isValid);
    }, [control.watch(), setIsValidate]);

    useEffect(() => {
        const { transactionType } = control.watch();
        let filteredProviders: IPaymentProvidersOptions[] = [];

        if (transactionType?.includes('CREDIT')) {
            const payMethod = control.watch('pspType');

            if (payMethod === PaymentProviderConstant.CLEO) {
                control.setValue(
                    'pspType',
                    PaymentMethodEnum.SELECT_PAYMENT_METHOD
                );
            }
            filteredProviders = PaymentProviderOptions.filter(
                (provider: { name: string }) =>
                    provider.name !== PaymentProviderConstant.CLEO
            );
        } else if (transactionType?.includes('WEB')) {
            control.setValue('pspType', PaymentProviderConstant.CLEO);
            filteredProviders = PaymentProviderOptions.filter(
                (provider: { name: string }) =>
                    provider.name === PaymentProviderConstant.CLEO
            );
        } else if (transactionType?.includes('BANK')) {
            filteredProviders = PaymentProviderOptions;
        }

        setFilteredPaymentProviders(filteredProviders);
    }, [control.watch('transactionType')]);

    return (
        <form className="w-full" onSubmit={handleOnSubmit}>
            <div className="grid grid-cols-2 items-center gap-x-6 my-5">
                <RFSelectField
                    name="transactionType"
                    control={control?.control}
                    fullWidth
                    label="Transaction Type"
                    bordered
                    options={[
                        {
                            _id: TransactionTypeEnum.SELECT_TRANSACTION_TYPE,
                            name: TransactionTypeEnum.SELECT_TRANSACTION_TYPE,
                        },
                        ...transactionTypeOptions,
                    ]}
                />
                <RFSelectField
                    name="pspType"
                    control={control?.control}
                    fullWidth
                    bordered
                    label="Payment Method"
                    options={[
                        {
                            id: PaymentMethodEnum.SELECT_PAYMENT_METHOD,
                            name: PaymentMethodEnum.SELECT_PAYMENT_METHOD,
                        },
                        ...filteredPaymentProviders,
                    ]}
                />
            </div>
            {isValidate && (
                <div>
                    {paymentMethodValue !== PaymentMethodEnum.CLEO && (
                        <CardDetails control={control?.control} />
                    )}
                </div>
            )}
        </form>
    );
};

export default PaymentDetails;
