import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { RFTextField, Switch } from 'components/Common';

const TransactionVelocity = ({ control }: { control: Control<any> }) => {
    return (
        <div className="mt-5">
            <div className="mt-3 mb-4 text-base font-bold tracking-tight text-black font-poppins-bold">
                Transaction Velocity
            </div>

            <div className="grid grid-cols-2 gap-5 items-center">
                <RFTextField
                    control={control}
                    fullWidth
                    asterisk
                    name="transactionCount"
                    placeholder="Transaction Count"
                    label="Transaction Count"
                    type="number"
                    noArrows
                />
                <div className="flex items-center justify-between">
                    <div className="text-sm font-semibold text-gray-500">
                        Velocity
                    </div>
                    <Controller
                        name="isVelocity"
                        control={control}
                        render={({
                            field: { value, onChange },
                            fieldState,
                        }) => (
                            <>
                                <Switch
                                    onChange={(e) => onChange(e.target.checked)}
                                    isToggled={value}
                                    className="rounded-2xl bg-gray-200"
                                />
                                {fieldState.error?.message ? (
                                    <div className="mt-2 flex self-baseline text-xs font-medium text-red-200">
                                        {fieldState.error?.message}
                                    </div>
                                ) : null}
                            </>
                        )}
                    />
                </div>
            </div>
        </div>
    );
};

export default TransactionVelocity;
