import { useEffect, useMemo, useState } from 'react';
import { SIDE_MENU_LIST } from 'data/constants/common.constants';

interface IUserPermissionProps {
    permissions: any;
    pageName: string;
}

const usePermission = ({ permissions, pageName }: IUserPermissionProps) => {
    const [isWrite, setIsWrite] = useState(false);

    useEffect(() => {
        const permission = permissions.find((p: any) => p.name === pageName);
        if (permission) {
            setIsWrite(permission.isWrite);
        } else {
            setIsWrite(false); // or null if you want to indicate no permission found
        }
    }, [permissions, pageName]);

    return isWrite;
};

export default usePermission;

export const useSideBarItemAsPerRole = ({ auth }: any) => {
    const getSideBarItemAsPerRole = useMemo<any[]>(() => {
        if (!auth.authUser?.role) return [];
        if (!auth?.isSuperAdmin) {
            return SIDE_MENU_LIST.map((menu) => {
                const isMainMenuPermitted = (auth?.permissions || []).some(
                    (perm: { value: string }) => perm.value === menu.key
                );
                const filteredSubMenu = (menu.subMenu || []).filter((subMenu) =>
                    (auth?.permissions || []).some(
                        (perm: { value: string }) => perm.value === subMenu.key
                    )
                );

                if (isMainMenuPermitted) {
                    return {
                        ...menu,
                        subMenu: menu.subMenu, // Return original subMenu if main menu is permitted
                    };
                }

                if (filteredSubMenu.length > 0) {
                    return {
                        ...menu,
                        subMenu: filteredSubMenu, // Return filtered subMenu if main menu is not permitted
                    };
                }

                return null; // Explicitly return null for menus that do not match any conditions
            }).filter(Boolean); // Filter out null values
        }
        return SIDE_MENU_LIST;
    }, [auth?.authUser?.role, auth?.permissions]);

    return {
        getSideBarItemAsPerRole,
    };
};
