import { IOptionProps } from '../common';

export enum PAYOUT_ACTION {
    APPROVED = 'APPROVED',
    DELAY_APPROVAL = 'DELAY_APPROVAL',
    DENY = 'DENY',
    HOLD = 'HOLD',
    UN_HOLD = 'UN_HOLD',
}

export enum PAYOUT_CANCELLED_OPTIONS {
    TECHNICAL_ISSUE = 'Technical Issue',
    CHARGEBACK = 'Chargeback',
    REQUEST_BY_PROVIDER = 'Request by Provider',
    REFUND = 'Refund',
    OTHER = 'Other',
    NULL = '',
}

export const ActionOptions: IOptionProps[] = [
    { key: PAYOUT_ACTION.APPROVED, title: 'Approve' },
    { key: PAYOUT_ACTION.DELAY_APPROVAL, title: 'Delay Approval' },
    { key: PAYOUT_ACTION.DENY, title: 'Deny' },
    { key: PAYOUT_ACTION.HOLD, title: 'Hold' },
    { key: PAYOUT_ACTION.UN_HOLD, title: 'Unhold' },
];

export const ReasonOptions: IOptionProps[] = [
    {
        key: PAYOUT_CANCELLED_OPTIONS.TECHNICAL_ISSUE,
        title: PAYOUT_CANCELLED_OPTIONS.TECHNICAL_ISSUE,
    },
    {
        key: PAYOUT_CANCELLED_OPTIONS.CHARGEBACK,
        title: PAYOUT_CANCELLED_OPTIONS.CHARGEBACK,
    },
    {
        key: PAYOUT_CANCELLED_OPTIONS.REQUEST_BY_PROVIDER,
        title: PAYOUT_CANCELLED_OPTIONS.REQUEST_BY_PROVIDER,
    },
    {
        key: PAYOUT_CANCELLED_OPTIONS.REFUND,
        title: PAYOUT_CANCELLED_OPTIONS.REFUND,
    },
    {
        key: PAYOUT_CANCELLED_OPTIONS.OTHER,
        title: PAYOUT_CANCELLED_OPTIONS.OTHER,
    },
];
