import React, { ChangeEvent, useEffect, useState } from 'react';
import {
    Drawer,
    PrimaryButton,
    PrimaryInput,
    Select,
    SvgIcon,
    TextField,
} from 'components/Common';
import { useReactFlow } from 'reactflow';
import classNames from 'classnames';
import useFetchCountries from 'hooks/useFetchCountries';
import useGetMerchantIds from 'hooks/useGetMerchantIds';
import { showToast } from 'data/utils/toast';
import {
    VelocityTypeOpts,
    VelocityWindowOpts,
} from 'data/constants/common.constants';
import { conditionList } from '../RuleBuilderUtills';
import { IRuleBuilderDrawer } from '../RuleBuilder.type';
import './ruleBuilderDrawer.css';
import { ActionList, PreconditionList } from '../../condition';
import { getInputField } from '../../CardPaymentRuleEngine/Precondition/PreconditionDropdown/PreconditionUtill';
import { ISelectedValueType, operators } from '../../RuleEngine.type';

const initialVelocityData = {
    timeWindow: '',
    windowUnit: '',
    type: '',
    typeThreshold: '',
};

const RuleBuilderDrawer = ({
    isOpen,
    openDrawer,
    onAdd,
    isCondition,
    captureElementNode,
    setIsOpenDrawer,
    setCaptureElementNode,
    setIsConditionOpen,
    setIsInptValueChanged,
}: IRuleBuilderDrawer) => {
    const { getNodes } = useReactFlow();
    const nodes = getNodes();
    const [query, setQuery] = useState<any>('');
    const [dataIndex, setDataIndex] = useState<any>(null);
    const [inptVal, setInptVal] = useState<any>(null);
    const [selectedOperator, setSelectedOperator] = useState('=');
    const [filteredProvidersList, setFilteredProvidersList] = useState<any>([]);
    const [ProvidersKeys, setProvidersKeys] = useState<string[]>([]);
    const [activeTab, setActiveTab] = useState('CONDITION');
    const [velocityObj, setVelocityObj] = useState(initialVelocityData);

    const { countries } = useFetchCountries();
    const Mids = useGetMerchantIds();

    const handleQueryChange = (event: any) => {
        setQuery(event.target.value);
    };

    const keyName = PreconditionList.map((item) => {
        return {
            condition: item?.Condition || item?.name,
            type: item?.Type,
            isDisable: item?.isDisable,
        };
    });

    useEffect(() => {
        conditionList.map((item) => {
            return (item.conditionDataList = keyName);
        });
    }, []);

    useEffect(() => {
        const updatedFilteredProvidersList = conditionList.map((list) => {
            let maxLength = Infinity; // Default value
            if (list.key === 'Recently Used' || list.key === 'Most Popular') {
                maxLength = 8;
            }

            const filterValue = list.conditionDataList
                .filter((v) =>
                    v.condition?.toLowerCase().includes(query?.toLowerCase())
                )
                .slice(0, maxLength); // Limit the length of the filtered conditionDataList
            return { ...list, conditionDataList: filterValue };
        });
        setFilteredProvidersList(updatedFilteredProvidersList);
    }, [query, conditionList]);

    const handleMultiSelect = (selectedLists: any, index: number) => {
        const list = (selectedLists || []).map(
            (value: ISelectedValueType) => value.id
        );
        setDataIndex(index);
        setInptVal(list);
    };

    useEffect(() => {
        if (isCondition) {
            if (!captureElementNode) return;
            const val = captureElementNode?.data?.items;
            setInptVal(val);
            setSelectedOperator(captureElementNode?.data?.operation);
        }
    }, [setIsOpenDrawer]);

    const handleChange = (
        index: number,
        value: string | number | string[] | undefined | any
    ) => {
        // const valueString = value !== undefined ? value?.toString() : ''; // Convert value to string if not undefined
        setDataIndex(index);
        setInptVal(value);
    };

    useEffect(() => {
        if (inptVal !== captureElementNode?.data?.items) {
            setIsInptValueChanged(true);
        } else {
            setIsInptValueChanged(false);
        }
    }, [inptVal]);

    const handleSave = () => {
        if (
            inptVal[0] === '' ||
            inptVal[1] === '' ||
            inptVal === null ||
            inptVal === '' ||
            inptVal.length === 0
        ) {
            showToast('Please select both value', 'error');
            return;
        }

        if (typeof inptVal === 'object' && !Array.isArray(inptVal)) {
            if (
                inptVal?.type === '' ||
                inptVal?.timeWindow === '' ||
                inptVal?.windowUnit === '' ||
                inptVal?.typeThreshold === ''
            ) {
                showToast('Please fill all values', 'error');
                return;
            }
        }

        const newArray = [...nodes];
        const node = newArray[dataIndex || captureElementNode?.id];
        if (node?.data) {
            if (Array.isArray(inptVal)) {
                node.data.items = inptVal;
            } else {
                node.data.items = inptVal;
            }
            node.data.operation = selectedOperator;
            node.data.actionType = activeTab;
        }
        setIsOpenDrawer(false);
        setInptVal(null);
        setDataIndex(null);
        setSelectedOperator('');
        setCaptureElementNode(null);
        setIsConditionOpen(false);
    };

    const getDropdownOptions = (title: string) => {
        if (title.toLowerCase().includes('amount')) {
            return operators.map((operator) => ({
                name: operator,
            }));
        }
        return operators
            .filter((op) => op === '=' || op === '!=')
            .map((operator) => ({
                name: operator,
            }));
    };

    const handleCollapseRuleList = (key: string) => {
        if (ProvidersKeys.includes(key)) {
            setProvidersKeys(ProvidersKeys.filter((item) => item !== key));
        } else {
            setProvidersKeys([...ProvidersKeys, key]);
        }
    };

    useEffect(() => {
        if (isCondition) {
            setActiveTab(captureElementNode?.data?.actionType);
        }
    }, []);

    useEffect(() => {
        if (captureElementNode?.data?.label === 'Transaction Velocity') {
            setVelocityObj(
                captureElementNode?.data?.items || initialVelocityData
            );
        }
    }, []);

    const handleSelectChange =
        (property: keyof typeof velocityObj) =>
        (e: ChangeEvent<HTMLSelectElement>) => {
            const selectedValue =
                e.target.value ===
                `Select ${property === 'timeWindow' ? 'time window' : property}`
                    ? ''
                    : e.target.value;

            const updatedVelocityObj = {
                ...velocityObj,
                [property]: selectedValue,
            };

            setVelocityObj(updatedVelocityObj);
            handleChange(1, updatedVelocityObj);
        };

    const handleInputChange =
        (property: keyof typeof velocityObj) =>
        (e: ChangeEvent<HTMLInputElement>) => {
            setVelocityObj({
                ...velocityObj,
                [property]: e.target.value,
            });
            velocityObj[property] = e.target.value;
            handleChange(1, {
                ...velocityObj,
                [property]: e.target.value,
            });
        };

    return (
        <Drawer
            drawerSize={400}
            isOpen={isOpen}
            toggleDrawer={openDrawer}
            drawerDirection="right"
            className="overflow-y-scroll rounded-r-md bg-pink-100 ruleContainer"
        >
            <div className="flex flex-col gap-3 p-6">
                <div className="flex items-center justify-between">
                    <div className="my-0 text-2xl font-poppins-bold tracking-tight font-extrabold text-green-100">
                        Build Rule
                    </div>
                    <SvgIcon
                        className="cursor-pointer"
                        icon="CLOSE_BUTTON"
                        onClick={openDrawer}
                    />
                </div>
                {!isCondition && (
                    <>
                        <PrimaryInput
                            type="search"
                            iconPosition="left"
                            icon="SEARCH_ICON"
                            className="min-[1480px]:!w-[400px] min-w-1/2 !pl-9 h-[48px]"
                            placeholder="Search for conditions..."
                            value={query}
                            onChange={handleQueryChange}
                        />
                        <div className="grid grid-cols-2  gap-2">
                            <div
                                onClick={() => setActiveTab('CONDITION')}
                                className={`text-xs py-2 font-poppins-bold rounded-xl text-center cursor-pointer ${
                                    activeTab === 'CONDITION'
                                        ? 'bg-[#DEECAA]'
                                        : ''
                                }`}
                            >
                                Conditions
                            </div>
                            <div
                                onClick={() => setActiveTab('ACTION')}
                                className={`text-xs py-2 font-poppins-bold rounded-xl text-center cursor-pointer ${
                                    activeTab === 'ACTION' ? 'bg-[#DEECAA]' : ''
                                }`}
                            >
                                Actions
                            </div>
                        </div>
                    </>
                )}
            </div>

            <div className="p-6 pt-0">
                {isCondition && (
                    <>
                        {!['Action'].includes(
                            captureElementNode?.data?.label
                        ) &&
                            captureElementNode?.data?.label !==
                                'Transaction Velocity' && (
                                <Select
                                    label="Operator"
                                    isCompanyAdmin
                                    isGroup
                                    fullWidth
                                    bordered
                                    options={getDropdownOptions(
                                        captureElementNode?.data?.label
                                    )}
                                    value={selectedOperator}
                                    handleOnSelect={(e) => {
                                        setSelectedOperator(e.target.value);
                                    }}
                                    className="w-full text-black mb-5"
                                />
                            )}

                        <div className="pb-4 text-base leading-5 font-extrabold">
                            {`${
                                captureElementNode?.data?.actionType ===
                                    'ACTION' || activeTab === 'ACTION'
                                    ? 'Action'
                                    : 'Condition'
                            } > ${captureElementNode?.data?.label as string}`}
                        </div>
                        {captureElementNode?.data?.label !==
                        'Transaction Velocity' ? (
                            getInputField(
                                {
                                    title: captureElementNode?.data?.label,
                                    conditionType:
                                        captureElementNode?.data?.type,
                                    items: inptVal,
                                },
                                captureElementNode?.id,
                                handleMultiSelect,
                                handleChange,
                                countries,
                                Mids
                            )
                        ) : (
                            <>
                                <Select
                                    label="Time Window"
                                    bordered
                                    asterisk
                                    isCompanyAdmin
                                    isGroup
                                    fullWidth
                                    options={VelocityWindowOpts}
                                    value={velocityObj.timeWindow}
                                    handleOnSelect={handleSelectChange(
                                        'timeWindow'
                                    )}
                                    className="w-full text-gray-400 !border-2 !border-[#f1f1f1] !rounded-lg hover:!border-[#9ed79f]"
                                />
                                <TextField
                                    fullWidth
                                    asterisk
                                    label="Window Unit"
                                    value={velocityObj.windowUnit}
                                    type="number"
                                    noArrows
                                    placeholder="Enter window unit"
                                    className="text-gray-100 !bg-transparent"
                                    onChange={handleInputChange('windowUnit')}
                                />
                                <Select
                                    bordered
                                    isCompanyAdmin
                                    isGroup
                                    label="Type"
                                    asterisk
                                    fullWidth
                                    options={VelocityTypeOpts}
                                    value={velocityObj.type}
                                    handleOnSelect={handleSelectChange('type')}
                                    className="w-full text-gray-400 !border-2 !border-[#f1f1f1] !rounded-lg hover:!border-[#9ed79f]"
                                />
                                <TextField
                                    fullWidth
                                    asterisk
                                    noArrows
                                    label="Type Threshold"
                                    value={velocityObj.typeThreshold}
                                    type="number"
                                    placeholder="Enter type threshold"
                                    className="text-gray-100 !bg-transparent"
                                    onChange={handleInputChange(
                                        'typeThreshold'
                                    )}
                                />
                            </>
                        )}
                        <PrimaryButton
                            className="!h-[40px] !px-[10px] w-full my-2"
                            color="#2E672F"
                            variant="filled"
                            isDrawerButton
                            name="Save"
                            disabled={!inptVal}
                            onClick={handleSave}
                        />
                    </>
                )}

                {!isCondition &&
                    (activeTab === 'CONDITION' ? (
                        filteredProvidersList.map((v: any, id: string) => {
                            return (
                                <div className="flex flex-col gap-6" key={id}>
                                    <div
                                        onClick={() =>
                                            handleCollapseRuleList(v.key)
                                        }
                                        className="text-base flex items-center gap-3 cursor-pointer font-extrabold font-merri-bold tracking-tighter leading-5"
                                    >
                                        {!ProvidersKeys.includes(v.key) ? (
                                            <SvgIcon
                                                icon="ARROW_UP"
                                                className="stroke-black"
                                            />
                                        ) : (
                                            <SvgIcon
                                                icon="ARROW_DOWN"
                                                className="stroke-black"
                                            />
                                        )}

                                        {v.key}
                                    </div>
                                    {!ProvidersKeys.includes(v.key) && (
                                        <div className="grid grid-cols-3 gap-2 pb-5">
                                            {v.conditionDataList.map(
                                                (li: any, index: number) => {
                                                    const isAllowed =
                                                        li?.isDisable;
                                                    return (
                                                        <div
                                                            className={classNames(
                                                                'truncate cursor-pointer rounded border border-solid border-black px-4 py-2 text-xs font-poppins-medium tracking-tight',
                                                                isAllowed &&
                                                                    '!cursor-not-allowed bg-[#a4a4a469]',
                                                                !isAllowed &&
                                                                    'hover:h-auto hover:border-green-200 focus:border-green-200'
                                                            )}
                                                            key={index}
                                                            title={
                                                                li?.condition
                                                            }
                                                            onClick={() => {
                                                                if (isAllowed)
                                                                    return;
                                                                onAdd(
                                                                    li?.condition,
                                                                    li?.type,
                                                                    'CONDITION'
                                                                );
                                                            }}
                                                        >
                                                            {li?.condition}
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    )}
                                </div>
                            );
                        })
                    ) : (
                        <div className="grid grid-cols-3 gap-2 pb-5">
                            {ActionList.map((li: any, index: number) => {
                                const isAllowed = li?.isDisable;
                                return (
                                    <div
                                        className={classNames(
                                            'truncate cursor-pointer rounded border border-solid border-black px-4 py-2 text-xs font-poppins-medium tracking-tight',
                                            isAllowed &&
                                                '!cursor-not-allowed bg-[#a4a4a469]',
                                            !isAllowed &&
                                                'hover:h-auto hover:border-[#CEEBFF] hover:bg-white transition-all duration-150 focus:border-green-200 border-white rounded bg-[#CEEBFF]'
                                        )}
                                        key={index}
                                        title={li?.Condition}
                                        onClick={() => {
                                            if (isAllowed) return;
                                            onAdd(
                                                li?.Condition,
                                                li?.Type,
                                                'ACTION'
                                            );
                                        }}
                                    >
                                        {li?.Condition}
                                    </div>
                                );
                            })}
                        </div>
                    ))}
            </div>
        </Drawer>
    );
};

export default RuleBuilderDrawer;
