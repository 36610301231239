import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import OtpInput from 'react-otp-input';
import { loginLogo } from 'assets';
import { ResetPassword } from 'components/AuthComponent';
import { IVerifyOTP } from 'data/types/request';
import { createNewPassword, verifyOtp } from 'services/api/api';
import { IPasswordData } from 'data/types/auth';
import { showToast } from 'data/utils/toast';
import { AuthenticationModal } from 'components/Modal';
import { AppActionsEnum } from 'context/Auth/AuthContextValues';
import { useAuthContext } from 'context/Auth/AuthContext';
import { PrimaryButton } from 'components/Common';
import SvgIcon from 'components/Common/Icon/SvgIcon';
import { z, ZodType } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

export const formValidation: ZodType<IPasswordData> = z
    .object({
        password: z
            .string({ required_error: 'Password is required' })
            .min(12, {
                message: 'Password must be at least 12 characters long',
            })
            .regex(/[A-Z]/, {
                message: 'Password must include at least one uppercase letter',
            })
            .regex(/[a-z]/, {
                message: 'Password must include at least one lowercase letter',
            })
            .regex(/[0-9]/, {
                message: 'Password must include at least one number',
            })
            .regex(/[^A-Za-z0-9]/, {
                message:
                    'Password must include at least one special character (e.g., !, @, #, $, %)',
            }),
        confirmPassword: z.string({
            required_error: 'Confirm Password is required',
        }),
    })
    .refine((data) => data.password === data.confirmPassword, {
        message: 'Passwords do not match',
        path: ['confirmPassword'],
    })
    .refine(
        (data) =>
            data.password !== undefined && data.confirmPassword !== undefined,
        {
            message: 'Both Password and Confirm Password are required',
            path: ['password', 'confirmPassword'],
        }
    );

const NewAdminPassword = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState<boolean>(false);
    const [otp, setOtp] = useState<string>('');
    const [token, setToken] = useState<string>('');
    const { dispatch } = useAuthContext();
    const [otpError, setOtpError] = useState<string>('');
    const [qrCode, setQrCode] = useState('');

    const { control, handleSubmit, reset } = useForm<IPasswordData>({
        resolver: zodResolver(formValidation),
        defaultValues: {},
    });

    const verifyUser = JSON.parse(localStorage.getItem('user') as string);

    const digitLeftCount = 6 - otp.length;

    useEffect(() => localStorage.removeItem('token'), []);

    const onSubmit = handleSubmit((formData) => {
        if (!formData) return;
        const payload = {
            password: formData?.password,
            email: verifyUser.email,
        };
        createNewPassword(payload)
            .then((result) => {
                setQrCode(result?.qr);
                localStorage.setItem('user', JSON.stringify(result.user));
                setToken(result.token);
                showToast('Your password created successfully', 'success');
                reset({});
                setOpen(true);
            })
            .catch((err) => {
                showToast(
                    err || 'Your password not created successfully',
                    'success'
                );
            });
    });

    const onVerifyOtp = () => {
        if (!otp) {
            setOtpError('Please Enter your OTP');
            return;
        }
        if (otp.length < 6) {
            setOtpError('Enter 6 digit OTP');
            return;
        }
        const payload: IVerifyOTP = { code: otp };

        verifyOtp(payload, token)
            .then((result) => {
                localStorage.setItem('token', result.token);
                dispatch({
                    type: AppActionsEnum.SET_CURRENT_USER,
                    payload: {
                        permissions: result?.permissions || [],
                        brandPermissions: result?.brandPermissions || [],
                        authUser: result.user,
                        isLoggedIn: true,
                        role: result.user?.role,
                    },
                });
                setOtpError('');
                showToast('successfully verified', 'success');
                setOpen(false);
                navigate('/operations');
            })
            .catch((err) => {
                showToast(err.message, 'error');
            });
    };

    return (
        <div className="flex w-[100vw] h-[100vh]">
            <div className="flex w-1/2 bg-amber-100">
                <img
                    src={loginLogo}
                    className="loginLogo"
                    alt="logo"
                    width="100%"
                    height="100%"
                />
            </div>
            {qrCode ? (
                <div className="mx-auto flex flex-col items-start justify-center w-[500px] max-[900px]:w-[300px]">
                    <div className="flex flex-col items-center gap-5">
                        <div>
                            <SvgIcon
                                className="mb-11 flex flex-col items-center"
                                icon="GOOGLE_LOGO"
                            />
                            <div className="mt-1 flex justify-center text-xl font-extrabold text-green-700">
                                Two-Factor Authentication - A Breeze!
                            </div>
                            <div>
                                Download the free{' '}
                                <span className="font-extrabold text-green-200">
                                    Google Authenticator{' '}
                                </span>{' '}
                                App, add a new account, then scan the Barcode to
                                set up your account.
                            </div>
                        </div>
                    </div>
                    <img
                        src={qrCode}
                        alt="code"
                        height="30%"
                        width="50%"
                        className="mx-auto"
                    />
                    <div className="mx-auto">
                        <OtpInput
                            value={otp}
                            onChange={(value: string) => setOtp(value)}
                            numInputs={6}
                            renderSeparator={
                                <span style={{ marginLeft: '13px' }} />
                            }
                            renderInput={(props: any) => (
                                <input
                                    id="inputOtp"
                                    {...props}
                                    style={{
                                        width: '53px',
                                        height: '55px',
                                        color: 'text-green-600',
                                        background: '#F7F7F7',
                                        border:
                                            otpError && otp.length < 6
                                                ? '1px solid red'
                                                : otp
                                                ? props.value
                                                    ? '2px solid #9ED79F'
                                                    : 'none'
                                                : 'none',
                                        borderRadius: 15,
                                        fontSize: '30px',
                                        fontWeight: 500,
                                        textAlign: 'center',
                                        outline: props.value
                                            ? '3px solid #9ED79F'
                                            : '',
                                    }}
                                />
                            )}
                        />
                    </div>
                    <PrimaryButton
                        onClick={onVerifyOtp}
                        type="submit"
                        name={
                            digitLeftCount === 0
                                ? `Let's go`
                                : `${digitLeftCount} Digits Left`
                        }
                        color="#2E672F"
                        variant="filled"
                        isDrawerButton
                        className={classNames(
                            'ml-5 mt-5 flex justify-center items-center w-[88%] h-10 rounded-xl bg-zinc-300 text-base font-bold text-gray-400',
                            {
                                'bg-green-700 text-white': digitLeftCount === 0,
                            }
                        )}
                    />
                </div>
            ) : (
                <div className="mx-14 flex w-1/2">
                    <ResetPassword
                        isNewAdmin
                        handleSubmit={onSubmit}
                        control={control}
                    />
                    {open && (
                        <AuthenticationModal
                            setIsOpen={setOpen}
                            handleVerifyOTP={onVerifyOtp}
                            setOtp={setOtp}
                            otp={otp}
                            otpError={otpError}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default NewAdminPassword;
