import React, { useState } from 'react';
import classNames from 'classnames';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { PrimaryButton } from 'components/Common';
import { cleoPaymentPayout } from 'services/api/api';
import {
    generateRandomData,
    PaymentProviderConstant,
} from 'data/constants/payment.constants';
import { showToast } from 'data/utils/toast';
import useFetchCountries from 'hooks/useFetchCountries';
import { useAuthContext } from 'context/Auth/AuthContext';
import useFilteredBrandData from 'hooks/useFilteredBrandData';
import { ICleoType } from '../PayIn/Merchant.type';
import UserInformation from '../Common/UserInformation/UserInformation';
import BillingAddress from '../Common/BillingAddress/BillingAddress';
import OtherInformation from '../Common/OtherInformation/OtherInformation';
import { cleoFormValidation } from '../PayIn/Merchant.schema';
import TransactionVelocity from '../Common/TransactionVelocity/TransactionVelocity';

const Cleo = () => {
    const [loading, setLoading] = useState<boolean>(false);

    const { control, handleSubmit, reset, setValue } = useForm<ICleoType>({
        resolver: zodResolver(cleoFormValidation),
        defaultValues: {
            blocked: false,
            kycVerified: false,
            isVelocity: false,
        },
    });
    const { countries } = useFetchCountries();
    const { auth } = useAuthContext();
    const brands = useFilteredBrandData(auth, PaymentProviderConstant.CLEO);

    const handleOnSubmit = handleSubmit((formData) => {
        if (!formData) return;
        setLoading(true);
        cleoPaymentPayout({
            ...formData,
            baseamount: parseInt(formData?.baseamount || ''),
            country: formData.address.country,
        })
            .then((res) => {
                showToast(res?.message, 'success');
                if (res?.response?.url) {
                    window.open(res?.response?.url, '_blank');
                }
                reset({});
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                if (err?.message === 'AMOUNT_UNDER_LIMIT') {
                    showToast('Amount should be higher.', 'error');
                } else if (err?.message === 'EXTERNAL_KEY_NOT_UNIQUE') {
                    showToast('External key should be unique.', 'error');
                } else {
                    showToast(
                        err?.errors?.[0]?.message ||
                            err?.message ||
                            'something went wrong',
                        'error'
                    );
                }
            });
    });

    return (
        <>
            <div className="flex w-full items-center justify-end">
                <PrimaryButton
                    color="#8FB131"
                    variant="filled"
                    name="Generate Data"
                    onClick={() =>
                        generateRandomData(
                            PaymentProviderConstant.CLEO,
                            reset,
                            countries,
                            brands
                        )
                    }
                    className="!h-[45px] w-fit px-4"
                />
            </div>
            <form className="w-full" onSubmit={handleOnSubmit}>
                <UserInformation
                    control={control}
                    pspType={PaymentProviderConstant.CLEO}
                    setValue={setValue}
                />
                <BillingAddress control={control} setValue={setValue} />
                <OtherInformation
                    control={control}
                    setValue={setValue}
                    pspType={PaymentProviderConstant.CLEO}
                />
                <TransactionVelocity control={control} />
                <div className="mt-10">
                    <PrimaryButton
                        type="submit"
                        isDrawerButton
                        className={classNames('w-full max-w-[224px]')}
                        color="#2E672F"
                        variant="filled"
                        name={loading ? '' : 'Submit'}
                        loading={loading}
                        disabled={loading}
                    />
                </div>
            </form>
        </>
    );
};
export default Cleo;
