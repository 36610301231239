import { useEffect, useMemo, useState } from 'react';
import { getCompanyBrands } from '../services/api/api';

interface ICompany {
    mid: string;
    brandData: { mid: string }[];
}

const useGetMerchantIds = (): string[] => {
    const [companies, setCompanies] = useState<ICompany[]>([]);
    const getCompanies = async () => {
        try {
            const response = await getCompanyBrands();
            setCompanies(response?.companies ?? []);
        } catch (error) {
            console.log('Error fetching companies:', error);
        }
    };

    useEffect(() => {
        getCompanies();
    }, []);

    return useMemo(() => {
        const midsSet = new Set<string>();
        companies.forEach((company) => {
            if (company.mid) {
                midsSet.add(company.mid);
            }
            company.brandData?.forEach((brand) => {
                if (brand.mid) {
                    midsSet.add(brand.mid);
                }
            });
        });
        return Array.from(midsSet);
    }, [companies]);
};

export default useGetMerchantIds;
