import './table.css';
import { ITableHeaderProps } from '../common';

export function TableHeader<T>({
    columns,
    isTransactionDetailsTable,
    isSidebarTable,
    isAuditHistoryTable,
    onSort,
}: ITableHeaderProps<T>): JSX.Element {
    /* const getSortDirection = (columnKey: string) => {
        if (!sortConfig || sortConfig.key !== columnKey) return '▼';
        return sortConfig?.direction === 'ascending' ? '▲' : '▼';
    }; */

    return (
        <tr>
            {columns.map((column, columnIndex) => {
                return (
                    <th
                        key={`table-head-cell-${columnIndex}`}
                        style={{
                            textAlign: isSidebarTable ? 'justify' : 'center',
                            cursor: 'pointer',
                            width: column.width ? column.width : '100%',
                        }}
                        className={` ${
                            isSidebarTable
                                ? ''
                                : isAuditHistoryTable
                                ? '!text-center border border-solid border-t-0 border-[#E3E3E3] last:border-r-0 thHeader bg-[#DEECAA]'
                                : isTransactionDetailsTable
                                ? '!text-center border border-solid border-[#E3E3E3]  bg-[#ffffff] thHeader'
                                : '!text-center border border-solid border-l-0 border-t-0 border-[#E3E3E3] last:border-r-0 thHeader'
                        } `}
                        onClick={() => onSort(column.key)}
                    >
                        <div
                        /* style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }} */
                        >
                            <div>{column.title}</div>
                            {/*  {isTransactionDetailsTable && (
                                <div style={{ fontSize: '10px' }}>
                                    {getSortDirection(column.key)}
                                </div>
                            )} */}
                        </div>
                    </th>
                );
            })}
        </tr>
    );
}
