import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PrimaryButton, RFTextField } from 'components/Common';
import { createPaymentProvider } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import {
    ICleoConfigurationType,
    IPspConfigurationProps,
} from '../configuration.type';
import { cleoFormSchemaValidation } from '../configurationUtills';

const CleoPaymentForm = ({
    title,
    selectedPspData,
    fetchConfigurationList,
    selectedCompanyId,
}: IPspConfigurationProps) => {
    const [loading, setLoading] = useState<boolean>(false);

    const { control, handleSubmit, reset } = useForm<ICleoConfigurationType>({
        resolver: zodResolver(cleoFormSchemaValidation),
        mode: 'onChange',
        defaultValues: {},
    });

    useEffect(() => {
        const resetData = {
            apiKey: selectedPspData?.apiKey,
            comment: selectedPspData?.comment,
            customerExternalKeyForCleo:
                selectedPspData?.customerExternalKeyForCleo,
        };
        reset(resetData);
    }, [selectedPspData]);

    const onSubmit = handleSubmit((formData) => {
        setLoading(true);
        const payload = {
            type: 'CLEO',
            companyId: selectedCompanyId,
            ...formData,
        };

        createPaymentProvider(payload)
            .then(() => {
                setLoading(false);
                fetchConfigurationList();
                showToast('Successfully', 'success');
            })
            .catch((err) => {
                setLoading(false);
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    });

    return (
        <form className="w-full" onSubmit={onSubmit}>
            <div className="flex flex-col gap-6 p-8">
                <div className="text-2xl tracking-tighter font-merri-bold">
                    {title}
                </div>
                <div>
                    <RFTextField
                        control={control}
                        name="apiKey"
                        label="API Key"
                        type="password"
                        fullWidth
                        asterisk
                        iconPosition="right"
                    />
                    <RFTextField
                        control={control}
                        name="customerExternalKeyForCleo"
                        label="Customer external key"
                        type="password"
                        fullWidth
                        asterisk
                        iconPosition="right"
                    />
                    <RFTextField
                        control={control}
                        name="comment"
                        label="Comment/Text"
                        type="text"
                        fullWidth
                        iconPosition="right"
                    />
                    <PrimaryButton
                        isDrawerButton
                        className={classNames(
                            'w-full max-w-[224px] !font-extrabold'
                        )}
                        color="#2E672F"
                        variant="filled"
                        type="submit"
                        name={loading ? '' : 'Save and Update'}
                        loading={loading}
                        disabled={loading}
                    />
                </div>
            </div>
        </form>
    );
};
export default CleoPaymentForm;
