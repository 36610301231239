import React, { useEffect, useMemo, useState } from 'react';
import { getTransaction } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { ITransactions } from 'data/types/response';
import { useAuthContext } from 'context/Auth/AuthContext';
import { TransactionHeader } from './TransactionHeader';
import { TransactionTable } from './TransactionTable';
import TransactionTableFilterModal from './TransactionTableFilterModal';
import { headerList } from './TransactionTable/TransactionTableUtill';
import {
    IFetchTransactionPayload,
    IFilteredDataType,
} from './Transaction.type';

const PaymentTransactions = () => {
    const { auth } = useAuthContext();
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setIsLoading] = useState<boolean>(false);
    const [transactionData, setTransactionData] = useState<ITransactions[]>([]);
    const [filterData, setFilterData] = useState<IFilteredDataType>({
        status: [],
        search: '',
        page: 1,
        limit: 10,
    });
    const [isTableFilter, setIsTableFilter] = useState<boolean>(false);
    const [totalPage, setTotalPage] = useState<number>(10);

    const updatedHeaderList = useMemo(() => {
        if (auth.authUser?.filteredColumn !== undefined) {
            return auth.authUser?.filteredColumn?.length !== 0
                ? headerList.map((item) => ({
                      ...item,
                      isChecked: (auth.authUser?.filteredColumn || []).includes(
                          item.key
                      ),
                  }))
                : headerList;
        }
        return headerList;
    }, [auth.authUser?.filteredColumn]);

    const fetchTransactionData = () => {
        setIsLoading(true);
        const payload: IFetchTransactionPayload = { ...filterData };
        if (auth.selectedBrand?._id) {
            payload.brandIds = [auth?.selectedBrand?._id];
        } else if (auth.selectedCompany?._id) {
            const selectedCompany = (auth?.companiesAndBrands || []).find(
                (company: { _id: string }) =>
                    company._id === auth.selectedCompany?._id
            );
            payload.brandIds =
                (selectedCompany?.brandData || []).map(
                    ({ _id }: { _id: string }) => _id
                ) || [];
        } else {
            payload.brandIds = [];
        }

        if (!payload?.brandIds) {
            setIsLoading(false);
            return;
        }

        getTransaction(payload)
            .then((res) => {
                setTotalPage(res?.total);
                setTransactionData(
                    (res?.transactions || []).sort(
                        (a, b) =>
                            new Date(b?.createdAt).getTime() -
                            new Date(a?.createdAt).getTime()
                    ) || []
                );
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    useEffect(() => {
        fetchTransactionData();
    }, [
        filterData,
        auth?.selectedBrand?._id,
        auth?.selectedCompany?._id,
        auth?.companiesAndBrands,
    ]);

    return (
        <div className="p-5">
            <TransactionHeader
                setFilterData={setFilterData}
                filterData={filterData}
                setIsTableFilter={setIsTableFilter}
            />

            {isTableFilter && (
                <TransactionTableFilterModal
                    className="actionModal"
                    setIsOpen={setIsTableFilter}
                    tableFilterColumn={updatedHeaderList}
                />
            )}

            <TransactionTable
                tableFilterColumn={updatedHeaderList}
                loading={loading}
                currentPage={currentPage}
                transactionData={transactionData}
                filterData={filterData}
                setCurrentPage={setCurrentPage}
                setFilterData={setFilterData}
                totalPage={totalPage}
            />
        </div>
    );
};
export default PaymentTransactions;
