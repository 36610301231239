import React, { ChangeEvent, useEffect, useState } from 'react';
import { Select } from 'components/Common';
import ConfirmationModal from 'components/Modal/ConfirmationModal/ConfirmationModal';
import {
    IConditionDataProps,
    IPreConditionData,
    IPreconditionPros,
} from '../../RuleEngine.type';
import { PreconditionDropdown } from './PreconditionDropdown';
import { PreconditionList } from '../../condition';

const Preconditions = ({
    showPreconditions,
    getPreConditionDataList,
    setGetPreConditionDataList,
}: IPreconditionPros) => {
    const [data, setData] = useState<IConditionDataProps[]>([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [title, setTitle] = useState('');

    const updateCondition = PreconditionList.map((obj) => {
        if (obj.Condition) {
            obj.name = obj.Condition;
            delete obj.Condition;
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return obj;
    });

    useEffect(() => {
        if (getPreConditionDataList.length > 0) {
            const tempList: IConditionDataProps[] = getPreConditionDataList.map(
                (obj: IPreConditionData) => ({
                    title: obj?.key,
                    items: obj?.values,
                    conditionType: obj?.conditionType,
                })
            );
            tempList.map((tempObj) => {
                const matchingPrecondition = PreconditionList.find(
                    (obj) => obj.Condition || obj.name === tempObj.title
                );

                if (matchingPrecondition) {
                    tempObj.conditionType = matchingPrecondition.Type;
                }
                return tempObj;
            });
            setData(tempList);
        }
    }, [getPreConditionDataList]);

    const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        if (data?.find((item) => item?.title === value)) {
            return null;
        }
        const getConditionList = updateCondition.find((v) => v.name === value);
        const listType = getConditionList?.Type;
        setGetPreConditionDataList([
            ...getPreConditionDataList,
            { key: value, conditionType: listType, values: [] },
        ]);

        setData([
            ...data,
            { title: value, conditionType: listType, items: [] },
        ]);
    };

    const handleDelete = (e: string) => {
        const filteredItem = data.find((v) => v?.title === e);
        const updateData = data.filter((v) => v?.title !== e);
        let shouldDelete = false;
        if (
            typeof filteredItem?.items === 'string' &&
            filteredItem?.items === ''
        ) {
            shouldDelete = true;
        } else if (
            Array.isArray(filteredItem?.items) &&
            filteredItem?.items.length === 0
        ) {
            shouldDelete = true;
        }
        if (shouldDelete || isDeleteModalOpen) {
            const updateList = getPreConditionDataList.filter(
                (ele) => ele.key !== e
            );
            setData(updateData);
            setGetPreConditionDataList(updateList);
            setIsDeleteModalOpen(false);
        } else {
            setIsDeleteModalOpen(true);
        }
    };

    return (
        <div className="bg-[#BDD864] m-4 rounded-2xl p-4">
            {isDeleteModalOpen && (
                <ConfirmationModal
                    onConfirmation={() => handleDelete(title)}
                    message={`Are you sure you want to delete ${title} ?`}
                    setIsModalOpen={setIsDeleteModalOpen}
                    isLoading={false}
                    onCancellation={() => {
                        setIsDeleteModalOpen(false);
                    }}
                />
            )}
            <span className="text-sm font-poppins-bold tracking-tight leading-[18px] p-0.5">
                Preconditions
            </span>
            {showPreconditions && (
                <PreconditionDropdown
                    data={data}
                    setTitle={setTitle}
                    setData={setData}
                    setGetPreConditionDataList={setGetPreConditionDataList}
                    handleDelete={handleDelete}
                    getPreConditionDataList={getPreConditionDataList}
                />
            )}
            <Select
                asterisk
                isCompanyAdmin
                bordered
                isGroup
                options={[
                    { name: 'Select precondition...' },
                    ...updateCondition,
                ].filter(
                    (i) =>
                        !data
                            .map((item: any) => item.title as string)
                            .includes(i.name)
                )}
                value="Start typing..."
                handleOnSelect={(e) => handleSelect(e)}
                className="col-span-4 text-gray-400"
            />
        </div>
    );
};

export default Preconditions;
