import React, { useEffect, useRef, useState } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import classNames from 'classnames';
import './MutliSelectDropdown.css';
import SvgIcon from '../Icon/SvgIcon';
import { NewMultiSelectDropdownProps } from '../common';

const MultiSelectDropdown: React.FC<NewMultiSelectDropdownProps> = ({
    options,
    selectedValues,
    displayValue,
    placeholder,
    showArrow,
    customArrow,
    customCloseIcon,
    onSelect,
    onRemove,
    className,
    hidePlaceholder,
    errorMessage,
    label,
    asterisk,
    isError = false,
    value,
    style,
    backgroundColor,
}) => {
    const inputRef = useRef(null);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [isHovered, setIsHovered] = useState<boolean>(false);

    const handleFocusChange = () => {
        setIsFocused(document.activeElement === inputRef.current);
    };

    useEffect(() => {
        document.addEventListener('focus', handleFocusChange, true);
        document.addEventListener('blur', handleFocusChange, true);
        document.addEventListener('hover', handleFocusChange, true);

        return () => {
            document.removeEventListener('focus', handleFocusChange, true);
            document.removeEventListener('blur', handleFocusChange, true);
            document.removeEventListener('hover', handleFocusChange, true);
        };
    }, []);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <>
            {label && (
                <label
                    className={classNames(
                        'font-semibold text-sm mb-2 text-start',
                        isError
                            ? 'text-red-200'
                            : isHovered
                            ? 'text-black'
                            : value
                            ? 'text-black'
                            : !isFocused
                            ? 'text-gray-500'
                            : 'text-black'
                    )}
                    htmlFor={label}
                >
                    {label}
                    {asterisk && (
                        <span className="!text-red-200 text-lg pl-[2px]">
                            *
                        </span>
                    )}
                </label>
            )}
            <div
                className="w-full"
                ref={inputRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <Multiselect
                    options={options}
                    selectedValues={selectedValues}
                    displayValue={displayValue}
                    placeholder={placeholder}
                    showArrow={showArrow}
                    customArrow={
                        customArrow ?? <SvgIcon icon="MULTISELECT_DOWN_ARROW" />
                    }
                    customCloseIcon={
                        customCloseIcon ?? <SvgIcon icon="SELECT_CLOSE" />
                    }
                    onSelect={onSelect}
                    onRemove={onRemove}
                    className={classNames(className, '')}
                    hidePlaceholder={hidePlaceholder}
                    style={{
                        multiselectContainer: {
                            borderRadius: '8px',
                            background: selectedValues?.length
                                ? '#ffffff'
                                : isError
                                ? '#fadcdc'
                                : '#F5F5F5',
                        },
                        searchBox: {
                            border: 'none',
                            paddingBlock: '10px',
                            background: selectedValues?.length
                                ? backgroundColor ?? '#F5F5F5'
                                : isError
                                ? '#fadcdc'
                                : backgroundColor ?? '#F5F5F5',
                        },
                        ...style,
                    }}
                />
            </div>
            {errorMessage && (
                <div className="flex mt-2 self-baseline text-xs font-medium text-red-200">
                    {errorMessage}
                </div>
            )}
        </>
    );
};

export default MultiSelectDropdown;
