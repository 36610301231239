import { Dispatch, SetStateAction } from 'react';
import { ICompanyData, IUser } from 'data/common';
import { Switch, TextField } from 'components/Common';
import { getFirstDateFormat } from 'data/utils/common';
import SvgIcon from 'components/Common/Icon/SvgIcon';
import { updateCompanyStatus } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { IColumnType } from 'components/Common/common';

export const CompaniesTableColumn = (
    isAllChecked: boolean,
    setCheckedCompanies: Dispatch<SetStateAction<string[]>>,
    checkedCompanies: Array<string>,
    updatedCompanyStatus: (id: string, status: boolean) => void,
    allCompanies: Array<ICompanyData>,
    setCompany: Dispatch<SetStateAction<ICompanyData | null>>,
    setIsEditCompany: Dispatch<SetStateAction<boolean>>,
    setIsOpen: Dispatch<SetStateAction<boolean>>
): IColumnType<ICompanyData>[] => [
    {
        key: 'checked',
        width: 20,
        title: (
            <div className="flex justify-center">
                <TextField
                    checked={isAllChecked}
                    isCheckbox
                    onChange={() => {
                        if (isAllChecked) {
                            setCheckedCompanies([]);
                        } else {
                            const filteredCompanies =
                                allCompanies?.filter(
                                    (company) => !company?.isDeleted
                                ) ?? [];
                            setCheckedCompanies(
                                filteredCompanies?.map((item) => item?._id)
                            );
                        }
                    }}
                    type="checkbox"
                />
            </div>
        ),
        render: (_, i) => (
            <div className="flex justify-center">
                <TextField
                    isCheckbox
                    checked={
                        checkedCompanies?.find((item) => item === i._id) ===
                        i._id
                    }
                    disabled={i?.isDeleted}
                    onChange={() => {
                        if (checkedCompanies?.includes(i._id) || isAllChecked) {
                            setCheckedCompanies(
                                checkedCompanies?.filter(
                                    (item) => item !== i?._id
                                )
                            );
                        } else {
                            setCheckedCompanies([...checkedCompanies, i?._id]);
                        }
                    }}
                    type="checkbox"
                />
            </div>
        ),
    },
    {
        key: 'mid',
        title: (
            <div className="flex justify-center">
                <div className="font-poppins-medium tracking-tight text-xs">
                    MID
                </div>
            </div>
        ),
        width: 50,
    },
    {
        key: 'name',
        title: (
            <div className="flex justify-center">
                <div className="font-poppins-medium tracking-tight text-xs">
                    Company Name
                </div>
            </div>
        ),
        width: 200,
    },
    {
        key: 'adminName',
        title: (
            <div className="flex justify-center">
                <div className="font-poppins-medium tracking-tight text-xs">
                    Admin
                </div>
            </div>
        ),
        render: (_, i) => (
            <div>
                {i?.userData?.length > 0
                    ? (i?.userData || [])
                          .map((user: IUser) => user?.name.toString())
                          ?.join(', ')
                    : '-'}
            </div>
        ),
        width: 200,
    },
    {
        key: 'createdAt',
        title: (
            <div className="flex justify-center">
                <div className="font-poppins-medium tracking-tight text-xs">
                    Created
                </div>
            </div>
        ),
        render: (_, i) => <div>{getFirstDateFormat(i.createdAt)}</div>,
        width: 200,
    },
    {
        key: 'edit',
        title: (
            <div className="flex justify-center">
                <div className="font-poppins-medium tracking-tight text-xs">
                    Edit
                </div>
            </div>
        ),
        width: 200,
        render: (_, i) => (
            <SvgIcon
                className="cursor-pointer"
                onClick={() => {
                    setCompany?.(i);
                    setIsEditCompany(true);
                    setIsOpen(true);
                }}
                icon="EDIT_ICON"
            />
        ),
    },
    {
        key: 'disable',
        title: (
            <div className="flex justify-center">
                <div className="font-poppins-medium tracking-tight text-xs">
                    Disable
                </div>
            </div>
        ),
        width: 200,
        render: (_, i) => (
            <Switch
                handleCheck={() => {
                    updateCompanyStatus(i._id, { isActive: !i.isDeleted })
                        .then((res) => {
                            if (res.isSuccess) {
                                updatedCompanyStatus(i._id, !i.isDeleted);
                                showToast(
                                    res.message || 'Successfully updated'
                                );
                            }
                        })
                        .catch((err) => {
                            showToast(
                                err?.errors?.[0]?.message ||
                                    'something went wrong',
                                'error'
                            );
                        });
                }}
                isToggled={i.isDeleted}
                className="rounded-2xl bg-gray-200"
            />
        ),
    },
];
