import { useState, useEffect } from 'react';

function useFetchCountries() {
    const [countries, setCountries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await fetch(
                    `https://restcountries.com/v3.1/all`
                );
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setCountries(data);
            } catch (err: any) {
                setError(err?.message);
            } finally {
                setLoading(false);
            }
        };

        fetchCountries();
    }, []); // Empty dependency array means this useEffect runs once on component mount

    return { countries, loading, error };
}

export default useFetchCountries;
