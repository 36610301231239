import { IMenuItem } from '../common';

export const SIDE_MENU_LIST: IMenuItem[] = [
    // {
    //     id: 1,
    //     icon: 'DASHBOARD_ICON',
    //     path: '/dashboard',
    //     key: 'DASHBOARD',
    //     title: 'Dashboard',
    //     subMenu: [],
    // },
    {
        id: 2,
        icon: 'AUDIT_ICON',
        path: '/operations',
        key: 'OPERATIONS',
        title: 'Operations',
        subMenu: [
            {
                id: 1,
                title: 'Transactions',
                key: 'TRANSACTIONS',
                path: '/operations/transactions',
            },
            {
                id: 2,
                title: 'Approvals',
                key: 'APPROVALS',
                path: '/operations/approvals',
            },
        ],
    },
    {
        id: 3,
        icon: 'AUDIT_ICON',
        title: 'Orchestration',
        key: 'WORKFLOWS',
        path: '/workflows/orchestration',
        subMenu: [],
    },
    {
        id: 4,
        icon: 'PAYMENT_ICON',
        title: 'Payment Methods',
        key: 'PAYMENT_METHODS',
        path: '/payment-methods',
        subMenu: [
            {
                id: 1,
                title: 'Configuration',
                key: 'CONFIGURATION',
                path: '/payment-methods/configuration',
            },
            {
                id: 2,
                title: 'Simulator',
                key: 'SIMULATOR',
                path: '/payment-methods/simulator',
            },
        ],
    },
    // {
    //     id: 5,
    //     icon: 'CASHIER_ICON',
    //     path: '/cashier',
    //     key: 'CASHIER',
    //     title: 'Cashier',
    //     subMenu: [
    //         {
    //             id: 1,
    //             title: 'Merchant PayIn',
    //             key: 'MERCHANT_PAYIN',
    //             path: '/cashier/merchant-payin',
    //         },
    //         {
    //             id: 2,
    //             title: 'Merchant Payout',
    //             key: 'MERCHANT_PAYOUT',
    //             path: '/cashier/merchant-payout',
    //         },
    //     ],
    // },
    {
        id: 6,
        icon: 'ADMIN_ICON',
        path: '/admin',
        key: 'ADMIN',
        title: 'Admin Center',
        subMenu: [
            {
                id: 1,
                title: 'User Management',
                key: 'USER_MANAGEMENT',
                path: '/admin/existing-users',
            },
            {
                id: 2,
                title: 'Companies',
                key: 'COMPANIES',
                path: '/admin/companies',
            },
            {
                id: 4,
                key: 'ACTIVITY_LOG',
                path: '/admin/activity-log',
                title: 'Activity Log',
            },
            {
                id: 3,
                title: 'Groups',
                key: 'GROUPS',
                path: '/admin/groups',
            },
            {
                id: 4,
                title: 'Sub Brands',
                key: 'SUB_BRANDS',
                path: '/admin/brands',
            },
            {
                id: 5,
                title: 'API Credentials',
                key: 'API_KEYS',
                path: '/admin/api-keys',
            },
        ],
    },
];

export enum GenderConstant {
    SELECT_GENDER = '-Select-',
    MALE = 'Male',
    FEMALE = 'Female',
    OTHER = 'Other',
}

export const GenderList = [
    {
        _id: '',
        name: GenderConstant.SELECT_GENDER,
    },
    { _id: GenderConstant.MALE, name: GenderConstant.MALE },
    { _id: GenderConstant.FEMALE, name: GenderConstant.FEMALE },
    {
        _id: GenderConstant.OTHER,
        name: GenderConstant.OTHER,
    },
];

export enum CurrenciesConstant {
    SELECT_CURRENCY = '-Select-',
    CLP = 'CLP',
    USD = 'USD',
    EURO = 'EURO',
}

export enum UserRoles {
    SUPER_ADMIN = 'SUPER_ADMIN',
    ADMIN = 'ADMIN',
    USER = 'USER',
}

export enum BulkActionTypes {
    DELETE = 'Delete',
    DISABLE = 'Disable',
    BULK_ACTION = 'Bulk Changes',
}

export enum ModalConstants {
    PAYMENT = 'Payment',
    USER_INFORMATION = 'User Information',
    TRANSACTION_INFORMATION = 'Transaction Information',
    KYC_STATUS = 'KYC Status',
    OTHER = 'Other',
    FINANCIAL = 'Financial',
    RAW_RESPONSE = 'Raw Response',
}

export const VelocityWindowOpts = [
    {
        id: 'Select time window',
        name: `Select time window`,
    },
    {
        _id: 'HOURS',
        name: 'Hours',
    },
    {
        _id: 'DAYS',
        name: 'Days',
    },
    {
        _id: 'MONTHS',
        name: 'Months',
    },
];

export const VelocityTypeOpts = [
    {
        id: 'Select type',
        name: `Select type`,
    },
    {
        _id: 'MAX_COUNT',
        name: 'Max Count',
    },
    {
        _id: 'MAX_AMOUNT',
        name: 'Max Amount',
    },
];
