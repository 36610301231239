import React, { FC } from 'react';
import '../Loader/loader.css';
import SvgIcon from '../Icon/SvgIcon';
import { IButtonProps } from '../common';

const PrimaryButton: FC<IButtonProps> = (props) => {
    const {
        name,
        variant,
        color,
        type = 'button',
        className,
        loading = false,
        disabled = false,
        isDrawerButton,
        icon,
        iconPosition,
        iconStroke,
        ...rest
    } = props;

    let backgroundColor: string;
    let fontColor: string;
    if (disabled) {
        fontColor = 'white';
        backgroundColor = '#8fb13180';
    } else if (variant === 'filled') {
        fontColor = 'white';
        backgroundColor = color;
    } else {
        backgroundColor = 'white';
        fontColor = color;
    }

    return type === 'submit' ? (
        <button
            style={{
                height: isDrawerButton ? '48px' : '32px',
                fontSize: isDrawerButton ? '16px' : '14px',
                fontWeight: 500,
                border:
                    variant === 'outline' ? `1px solid ${fontColor}` : 'none',
                background: backgroundColor,
                color: fontColor,
                opacity: 1,
                borderRadius: isDrawerButton ? '12px' : '6px',
                cursor: 'pointer',
                ...rest,
            }}
            type="submit"
            disabled={disabled}
            className={className}
            {...rest}
        >
            <div className="flex gap-1 justify-center">
                {loading && (
                    <div className="spinner-container">
                        <div className="main-loader !h-5 !w-5" />
                    </div>
                )}
                {name !== '' && (
                    <div className="w-full flex text-nowrap font-merri-regular items-center justify-center">
                        {name}
                    </div>
                )}
            </div>
        </button>
    ) : (
        <div className="relative flex flex-wrap">
            {iconPosition === 'left' && (
                <SvgIcon
                    icon={icon}
                    style={{
                        position: 'absolute',
                        top: '44%',
                        padding: '0 0 0 10px',
                        width: '14px',
                        height: '15px',
                        transform: 'translateY(-50%)',
                        stroke: iconStroke,
                    }}
                />
            )}
            <button
                style={{
                    height: isDrawerButton ? '48px' : '32px',
                    fontSize: isDrawerButton ? '16px' : '14px',
                    fontWeight: 500,
                    border:
                        variant === 'outline'
                            ? `1px solid ${fontColor}`
                            : 'none',
                    background: backgroundColor,
                    color: fontColor,
                    opacity: 1,
                    borderRadius: isDrawerButton ? '12px' : '6px',
                    cursor: 'pointer',
                    textAlign: 'center',
                    ...rest,
                }}
                type="button"
                disabled={disabled}
                className={className}
                {...rest}
            >
                <div className="flex gap-1 justify-center">
                    {loading && (
                        <div className="spinner-container">
                            <div className="main-loader !h-5 !w-5" />
                        </div>
                    )}
                    {name !== '' && (
                        <div className="w-full flex text-nowrap font-merri-regular items-center justify-center">
                            {name}
                        </div>
                    )}
                </div>
            </button>
        </div>
    );
};

export default PrimaryButton;
