import './index.css';
import { StatusBadgeProps } from '../common';

export const getStatusColor = (status: string) => {
    switch (status) {
        case 'SUCCESS':
        case 'PAID':
            return '#B6FFB6';
        case 'PENDING':
            return '#FFF0BB';
        case 'SUSPENDED':
            return '#EDE7FB';
        case 'FAILED':
        case 'ERROR':
            return '#FCA2C2';
        case 'EXPIRED':
            return '#EDE7FB';
        case 'NOT_VERIFIED':
            return '#FCA2C2';
        case 'VERIFIED':
            return '#B6FFB6';
        case 'APPROVE':
            return '#B6FFB6';
        default:
            return '#EDE7FB';
    }
};

export const getStatusLabel = (status: string) => {
    switch (status) {
        case 'SUCCESS':
            return 'Success';
        case 'PAID':
            return 'Paid';
        case 'PENDING':
            return 'Pending';
        case 'SUSPENDED':
            return 'Suspended';
        case 'FAILED':
            return 'Failed';
        case 'ERROR':
            return 'Error';
        case 'EXPIRED':
            return 'Expired';
        case 'NOT_VERIFIED':
            return 'Not Verified';
        case 'VERIFIED':
            return 'Verified';
        case 'APPROVE':
            return 'Approved';
        default:
            return status;
    }
};

const StatusBadge = ({ status, fullWidth = true }: StatusBadgeProps) => {
    if (!status) return <p>-</p>;
    const formattedStatus = (status ?? '').charAt(0) + (status ?? '').slice(1);

    return (
        <div
            className={`status-badge ${
                fullWidth ? 'w-[100px]' : ''
            } justify-center`}
            style={{
                backgroundColor: getStatusColor(status),
            }}
        >
            <div className="font-poppins-bold tracking-tight text-xs text-center capitalize">
                {formattedStatus.toLowerCase()}
            </div>
        </div>
    );
};

export default StatusBadge;
