import './index.css';
import { TooltipProps } from '../common';

const Tooltip = ({ text = '', children, className = '' }: TooltipProps) => {
    return (
        <div className={`tooltip-container ${className}`}>
            {children}
            <div className="tooltip-text font-poppins-medium tracking-tight">
                {text}
            </div>
        </div>
    );
};

export default Tooltip;
