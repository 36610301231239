import React, { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { PrimaryButton } from 'components/Common';
import { processPaymodumPayment } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import {
    generateRandomData,
    PaymentProviderConstant,
} from 'data/constants/payment.constants';
import useFetchCountries from 'hooks/useFetchCountries';
import { useAuthContext } from 'context/Auth/AuthContext';
import useFilteredBrandData from 'hooks/useFilteredBrandData';
import { payModumFormValidation } from './Merchant.schema';
import { IPayModumeType } from './Merchant.type';
import UserInformation from '../Common/UserInformation/UserInformation';
import BillingAddress from '../Common/BillingAddress/BillingAddress';
import OtherInformation from '../Common/OtherInformation/OtherInformation';
import CardDetails from '../Common/CardDetails/CardDetails';
import TransactionVelocity from '../Common/TransactionVelocity/TransactionVelocity';

const Paymodum = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const { control, handleSubmit, reset, setValue } = useForm<IPayModumeType>({
        resolver: zodResolver(payModumFormValidation),
        mode: 'onChange',
        defaultValues: {
            blocked: false,
            kycVerified: false,
            isVelocity: false,
        },
    });
    const { countries } = useFetchCountries();
    const { auth } = useAuthContext();
    const brands = useFilteredBrandData(auth, PaymentProviderConstant.PAYMODUM);

    const handleOnSubmit = handleSubmit((formData) => {
        if (!formData) return;
        setLoading(true);

        const payload = {
            ...formData,
            tags: formData?.tags || [],
            bonusCode: formData?.bonusCode || '',
            card: {
                ...formData.card,
                expiry: {
                    month: formData.card.expiry.split('/')[0],
                    year: formData.card.expiry.split('/')[1],
                },
                billing_address: {
                    ...formData.address,
                    postal_code: formData.address.postalCode,
                },
            },
            baseamount: parseFloat(formData.baseamount),
            country: formData.address.country,
        };

        processPaymodumPayment(payload)
            .then((res) => {
                if (res.isSuccess) {
                    reset({});
                    showToast(res?.message, 'success');
                    setLoading(false);
                }
            })
            .catch((err) => {
                let errorMessage: JSX.Element | string = 'something went wrong';
                if (typeof err.message === 'string') {
                    errorMessage = err.message;
                }
                if (err?.errors?.length && Array.isArray(err?.errors)) {
                    errorMessage = (
                        <div>
                            {err?.errors.map(
                                (message: string, index: number) => (
                                    <div key={index}>
                                        {index + 1}. {message}
                                    </div>
                                )
                            )}
                        </div>
                    );
                }
                showToast(errorMessage, 'error');
                setLoading(false);
            });
    });

    return (
        <>
            <div className="flex w-full items-center justify-end">
                <PrimaryButton
                    color="#8FB131"
                    variant="filled"
                    name="Generate Data"
                    onClick={() =>
                        generateRandomData(
                            PaymentProviderConstant.PAYMODUM,
                            reset,
                            countries,
                            brands
                        )
                    }
                    className="!h-[45px] w-fit px-4"
                />
            </div>

            <form className="w-full" onSubmit={handleOnSubmit}>
                <div>
                    <UserInformation
                        control={control}
                        pspType={PaymentProviderConstant.PAYMODUM}
                        setValue={setValue}
                    />
                    <CardDetails control={control} />
                    <BillingAddress control={control} setValue={setValue} />
                    <OtherInformation
                        control={control}
                        setValue={setValue}
                        pspType={PaymentProviderConstant.PAYMODUM}
                    />
                    <TransactionVelocity control={control} />
                    <div className="mt-10">
                        <PrimaryButton
                            type="submit"
                            isDrawerButton
                            className={classNames('w-full max-w-[224px]')}
                            color="#2E672F"
                            variant="filled"
                            name={loading ? '' : 'Submit'}
                            loading={loading}
                            disabled={loading}
                        />
                    </div>
                </div>
            </form>
        </>
    );
};

export default Paymodum;
