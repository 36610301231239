import { ChangeEvent } from 'react';
import { PrimaryButton, Select, Tooltip } from 'components/Common';
import { bulkActionForBrand } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { BrandsHeaderProps, Options } from '../Brads.type';

export const BrandsHeader = ({
    getAllBrandsData,
    setCheckedBrands,
    checkedBrands,
    openDrawer,
    isAllowedUser,
}: BrandsHeaderProps) => {
    const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value !== '1') {
            bulkActionForBrand({
                brandIds: checkedBrands,
                type: e.target.value?.toUpperCase(),
            })
                .then((res) => {
                    if (res.isSuccess) {
                        getAllBrandsData();
                        showToast(res.message);
                        setCheckedBrands([]);
                    }
                })
                .catch((err) => {
                    showToast(
                        err?.errors?.[0]?.message || 'something went wrong',
                        'error'
                    );
                });
        }
    };

    return (
        <div className="mb-5 flex w-full items-center justify-between max-[970px]:flex-col">
            <div className="font-merri-bold tracking-tighter text-2xl text-[#131119]">
                Sub Brands
            </div>
            <div className="flex items-center gap-4">
                <Tooltip
                    text={
                        checkedBrands?.length === 0
                            ? 'Please select first the Mids from the table that you want to edit'
                            : ''
                    }
                    className="w-[150px]"
                >
                    <Select
                        isDisabled={!checkedBrands?.length}
                        isGroup
                        isMarginBottom={false}
                        options={Options}
                        handleOnSelect={handleSelect}
                        className="w-full max-w-[150px] !pr-2 !border-2 !border-[#818181] !rounded-[12px] px-4 !text-xs !h-11"
                    />
                </Tooltip>
                <div className="w-[120px]">
                    <PrimaryButton
                        disabled={!isAllowedUser}
                        onClick={openDrawer}
                        type="button"
                        name="Add Sub Brand"
                        color="#8FB131"
                        variant="filled"
                        className="!rounded-xl px-4 !w-[120px] !text-xs !h-11"
                    />
                </div>
            </div>
        </div>
    );
};
