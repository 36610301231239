import { z, ZodType } from 'zod';
import { IBrandsPermissions } from 'data/common';
import { IGroupType } from './Groups.type';

const brandPermissionSchema: ZodType<IBrandsPermissions> = z.object({
    brandId: z.string({ required_error: 'Brand ID is required' }),
    isRead: z.boolean({ required_error: 'isRead is required' }),
    isWrite: z.boolean({ required_error: 'isWrite is required' }),
    _id: z.string().optional(),
});

const commonSchema = z.object({
    groupName: z
        .string({ required_error: 'Group name is required' })
        .trim()
        .min(1, 'Group name is required'),
    companyId: z
        .string({ required_error: 'Select your Company' })
        .optional()
        .nullable(),
    brandsPermissions: z
        .array(brandPermissionSchema)
        .refine((data) => data.length >= 1, {
            message: 'At least one brand permission is required',
        }),
    pageAreasPermissions: z
        .array(
            z.object({
                pageAccessId: z.string({
                    required_error: 'Page access ID is required',
                }),
                isRead: z.boolean({
                    required_error: 'Read permission is required',
                }),
                isWrite: z.boolean({
                    required_error: 'Write permission is required',
                }),
            })
        )
        .refine((data) => data.length >= 1, {
            message: 'At least one page permission is required',
        }),
});

export const formSchemaValidation = (
    isSuperAdmin: boolean
): ZodType<IGroupType> => {
    if (!isSuperAdmin) {
        return commonSchema.required();
    }

    return commonSchema
        .extend({
            companyId: z
                .string({ required_error: 'Select your Company' })
                .refine((val) => val !== '', {
                    message: 'Select your Company is required',
                    path: ['companyId'],
                }),
        })
        .required();
};
