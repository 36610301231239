import { useEffect, useState } from 'react';
import {
    IBrandsPermissions,
    ICreateUserData,
    IPagePermissions,
} from 'data/common';
import { getUserByCompany } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import LoadingSpinner from 'components/Common/Loader/Loader';
import usePermission from 'hooks/commonHook';
import { useAuthContext } from 'context/Auth/AuthContext';
import { UserHeader } from './UserHeader';
import { UserTable } from './UserTable';
import { IGetUserByCompany, IUserData } from './User.type';
import { AddUserDrawer } from './UserDrawer/AddUserDrawer';
import { EditUserDrawer } from './UserDrawer/EditUserDrawer';

export const GetStatus = (status: boolean | undefined) => {
    if (status) {
        return (
            <div className="p-1 px-3 w-20 font-poppins-bold text-xs bg-[#FCA2C2] rounded-[12px] py-2">
                Inactive
            </div>
        );
    }
    return (
        <div className="p-1 px-3 w-20 font-poppins-bold text-xs bg-[#B6FFB6] rounded-[12px] py-2">
            Active
        </div>
    );
};

const User = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [pageAreaData, setPageAreaData] = useState<IPagePermissions[]>([]);
    const [accessAreaData, setAccessAreaData] = useState<IBrandsPermissions[]>(
        []
    );
    const [userData, setUserData] = useState<ICreateUserData | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [companyUsers, setCompanyUsers] = useState<Array<IUserData>>([]);
    const [checkedUsers, setCheckedUsers] = useState<string[]>([]);
    const [isEditUser, setIsEditUser] = useState(false);

    const { auth } = useAuthContext();
    const isWrite = usePermission({
        permissions: auth?.permissions,
        pageName: 'Users',
    });
    const isAllowedUser = auth?.isSuperAdmin ? true : isWrite;

    const getUsers = () => {
        setLoading(true);
        let payload: IGetUserByCompany = {
            sort: { fieldName: 'createdAt', order: -1 },
        };
        if (auth.selectedBrand?._id) {
            payload = {
                ...payload,
                brandId: auth.selectedBrand?._id,
            };
        }
        if (auth.selectedCompany?._id) {
            payload = {
                ...payload,
                companyId: auth.selectedCompany?._id,
            };
        }
        getUserByCompany(payload)
            .then((res) => {
                setLoading(false);
                setCompanyUsers(res?.users || []);
            })
            .catch((err) => {
                setLoading(false);
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    useEffect(() => {
        getUsers();
    }, [auth.isSuperAdmin, auth.selectedCompany, auth.selectedBrand]);

    const openDrawer = () => {
        setUserData(null);
        setAccessAreaData([]);
        setPageAreaData([]);
        setIsEditUser(false);
        setIsOpen(!isOpen);
    };

    return (
        <div className="p-5">
            <UserHeader
                getUsers={getUsers}
                checkedUsers={checkedUsers}
                setCheckedUsers={setCheckedUsers}
                openDrawer={openDrawer}
                isAllowedUser={isAllowedUser}
            />{' '}
            {loading ? (
                <div className="mt-5">
                    <LoadingSpinner />
                </div>
            ) : (
                <UserTable
                    isAllowedUser={isAllowedUser}
                    setCheckedUsers={setCheckedUsers}
                    setUserData={setUserData}
                    setIsEditUser={setIsEditUser}
                    companyUsers={companyUsers}
                    checkedUsers={checkedUsers}
                    setCompanyUsers={setCompanyUsers}
                    setIsOpen={setIsOpen}
                />
            )}
            {isEditUser ? (
                <EditUserDrawer
                    openDrawer={openDrawer}
                    isOpen={isOpen}
                    isEditUser={isEditUser}
                    userData={userData}
                    pageAreaData={pageAreaData}
                    setAccessAreaData={setAccessAreaData}
                    setPageAreaData={setPageAreaData}
                    accessAreaData={accessAreaData}
                    getUsers={getUsers}
                    setIsOpen={setIsOpen}
                />
            ) : (
                <AddUserDrawer
                    openDrawer={openDrawer}
                    isOpen={isOpen}
                    userData={userData}
                    pageAreaData={pageAreaData}
                    setAccessAreaData={setAccessAreaData}
                    setPageAreaData={setPageAreaData}
                    accessAreaData={accessAreaData}
                    getUsers={getUsers}
                    setIsOpen={setIsOpen}
                />
            )}
        </div>
    );
};

export default User;
