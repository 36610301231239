import { getFirstDateFormat } from 'data/utils/common';
import { IColumnType } from 'components/Common/common';
import { IAuditData } from '../Audit.type';

export const AuditTableColumn = (): IColumnType<IAuditData>[] => [
    {
        key: '_id',
        title: (
            <div className="flex items-center justify-center">
                <div className="font-poppins-medium tracking-tight text-xs">
                    ID
                </div>
            </div>
        ),
        width: 70,
    },
    {
        key: 'level',
        title: (
            <div className="flex justify-center">
                <div className="font-poppins-medium tracking-tight text-xs">
                    Level
                </div>
            </div>
        ),
        width: 70,
    },
    {
        key: 'source',
        title: (
            <div className="flex justify-center">
                <div className="font-poppins-medium tracking-tight text-xs">
                    Source
                </div>
            </div>
        ),
        width: 70,
    },
    {
        key: 'createdAt',
        title: (
            <div className="flex items-center justify-center">
                <div className="font-poppins-medium tracking-tight text-xs">
                    Created
                </div>
            </div>
        ),
        width: 80,
        render: (_, i) => <div>{getFirstDateFormat(i?.createdAt)}</div>,
    },
    {
        key: 'userName',
        title: (
            <div className="flex items-center justify-center">
                <div className="font-poppins-medium tracking-tight text-xs">
                    User
                </div>
            </div>
        ),
        width: 100,
    },
    {
        key: 'merchantId',
        title: (
            <div className="flex items-center justify-center">
                <div className="font-poppins-medium tracking-tight text-xs">
                    MID
                </div>
            </div>
        ),
        width: 80,
    },
    {
        key: 'updatedBy',
        title: (
            <div className="flex items-center justify-center">
                <div className="font-poppins-medium tracking-tight text-xs">
                    Page Modified
                </div>
            </div>
        ),
        width: 80,
    },
    {
        key: 'page',
        title: (
            <div className="flex justify-center">
                <div className="font-poppins-medium tracking-tight text-xs">
                    Page Name
                </div>
            </div>
        ),
        width: 70,
    },
    {
        key: 'ipAddress',
        title: (
            <div className="flex justify-center">
                <div className="font-poppins-medium tracking-tight text-xs">
                    IP Address
                </div>
            </div>
        ),
        width: 70,
    },
    {
        key: 'httpMethod',
        title: (
            <div className="flex justify-center">
                <div className="font-poppins-medium tracking-tight text-xs">
                    HTTP Method
                </div>
            </div>
        ),
        width: 70,
    },
    {
        key: 'responseCode',
        title: (
            <div className="flex justify-center">
                <div className="font-poppins-medium tracking-tight text-xs">
                    ResponseCode
                </div>
            </div>
        ),
        width: 70,
    },
    {
        key: 'url',
        title: (
            <div className="flex justify-center">
                <div className="font-poppins-medium tracking-tight text-xs">
                    URL
                </div>
            </div>
        ),
        width: 70,
    },
    {
        key: 'action',
        title: (
            <div className="flex">
                <div className="font-poppins-medium tracking-tight text-xs">
                    Comment
                </div>
            </div>
        ),
        width: 300,
    },
];
