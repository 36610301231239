import { useState } from 'react';
import { Modal, Table } from 'components/Common';
import { IColumnType } from 'components/Common/common';
import AuditDrawer from 'components/AuditDrawer/AuditDrawer';
import { ActionModalProps } from '../Modal.type';

const HistoryData = [
    {
        id: '098756780',
        date: '09/10/2023',
        person: 'George Daly',
        time: '098756780',
        comment: 'Lorem ipsum dolor sit amet consectetur.',
        action: 'George Daly',
    },
    {
        id: '098756780',
        date: '12/12/2023',
        person: 'George Daly',
        time: '098756780',
        comment: 'Lorem ipsum dolor sit amet consectetur.',
        action: 'George Daly',
    },
    {
        id: '098756780',
        date: '08/12/2023',
        person: 'George Daly',
        time: '098756780',
        comment: 'Lorem ipsum dolor sit amet consectetur.',
        action: 'George Daly',
    },
    {
        id: '098756780',
        date: '05/12/2023',
        person: 'George Daly',
        time: '098756780',
        comment: 'Lorem ipsum dolor sit amet consectetur.',
        action: 'George Daly',
    },
    {
        id: '098756780',
        date: '05/05/2023',
        person: 'George Daly',
        time: '098756780',
        comment: 'Lorem ipsum dolor sit amet consectetur.',
        action: 'George Daly',
    },
    {
        id: '098756780',
        date: '01/02/2023',
        person: 'George Daly',
        time: '098756780',
        comment: 'Lorem ipsum dolor sit amet consectetur.',
        action: 'George Daly',
    },
    {
        id: '098756780',
        date: '09/02/2023',
        person: 'George Daly',
        time: '098756780',
        comment: 'Lorem ipsum dolor sit amet consectetur.',
        action: 'George Daly',
    },
    {
        id: '098756780',
        date: '05/05/2020',
        person: 'George Daly',
        time: '098756780',
        comment: 'Lorem ipsum dolor sit amet consectetur.',
        action: 'George Daly',
    },
    {
        id: '098756780',
        date: '01/10/12023',
        person: 'George Daly',
        time: '098756780',
        comment: 'Lorem ipsum dolor sit amet consectetur.',
        action: 'George Daly',
    },
    {
        id: '098756780',
        date: '/02/12/2023',
        person: 'George Daly',
        time: '098756780',
        comment: 'Lorem ipsum dolor sit amet consectetur.',
        action: 'George Daly',
    },
    {
        id: '098756780',
        date: '10/02/2023',
        person: 'George Daly',
        time: '098756780',
        comment: 'Lorem ipsum dolor sit amet consectetur.',
        action: 'George Daly',
    },
];
const AuditHistoryModal = ({
    setIsOpen,
    pageName = 'Page Name',
}: ActionModalProps) => {
    const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);

    const columns: IColumnType<any>[] = [
        {
            key: 'date',
            title: (
                <div className="flex justify-center items-center">
                    <div className="ml-2">Date</div>
                </div>
            ),
            width: 100,
        },
        {
            key: 'time',
            title: (
                <div className="flex justify-center items-center">
                    <div className="ml-2">Time</div>
                </div>
            ),
            width: 100,
        },
        {
            key: 'person',
            title: (
                <div className="flex justify-center items-center">
                    <div className="ml-2">Person</div>
                </div>
            ),
            width: 100,
        },
        {
            key: 'comment',
            title: (
                <div className="flex justify-center text items-center">
                    <div className="ml-2">Comment</div>
                </div>
            ),
            width: 300,
        },
        {
            key: 'action',
            title: (
                <div className="flex justify-center items-center">
                    <div className="ml-2">Action</div>
                </div>
            ),
            render: () => (
                <span
                    onClick={() => setIsOpenDrawer(true)}
                    className="!font-black text-green-200 cursor-pointer"
                >
                    Restore
                </span>
            ),
            width: 100,
        },
    ];

    const openDrawer = () => {
        setIsOpenDrawer(!isOpenDrawer);
    };

    return (
        <Modal title={`${pageName} Audit History`} setIsOpen={setIsOpen} icon>
            <div className="flex flex-col gap-6	p-2 mt-6 pt-0  ">
                <div className="flex items-start !text-start text-sm mt-[-20px] ">
                    The ultimate goal of the audit is to provide an independent
                    assessment and opinion of the accuracy and adequacy of
                    financial records and other documented information.
                </div>
                <div className="max-h-[320px] !overflow-y-scroll !overflow-x-scroll dataTable rounded-xl w-max	">
                    <div className="flex w-full max-w-full items-center">
                        <Table
                            colSpan={5}
                            isAuditHistoryTable
                            data={HistoryData}
                            columns={columns}
                        />
                    </div>
                </div>
                <AuditDrawer isOpen={isOpenDrawer} openDrawer={openDrawer} />
            </div>
        </Modal>
    );
};
export default AuditHistoryModal;
