import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { PrimaryButton, RFTextField } from 'components/Common';
import { createPaymentProvider } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import {
    ConfigurationType,
    IPspConfigurationProps,
} from '../configuration.type';
import { formSchemaValidation } from '../configurationUtills';

const PayModumForm = ({
    title,
    selectedPspData,
    fetchConfigurationList,
    selectedCompanyId,
}: IPspConfigurationProps) => {
    const [loading, setLoading] = useState<boolean>(false);

    const { control, handleSubmit, reset } = useForm<ConfigurationType>({
        resolver: zodResolver(formSchemaValidation),
        mode: 'onChange',
        defaultValues: {},
    });

    useEffect(() => {
        const resetData = {
            comment: selectedPspData?.comment,
            username: selectedPspData?.username,
            password: selectedPspData?.password,
        };
        reset(resetData);
    }, [selectedPspData]);

    const onSubmit = handleSubmit((formData) => {
        setLoading(true);
        const payload = {
            type: 'PAYMODUM',
            companyId: selectedCompanyId,
            ...formData,
        };

        createPaymentProvider(payload)
            .then(() => {
                setLoading(false);
                fetchConfigurationList();
                showToast('Successfully', 'success');
            })
            .catch((err) => {
                setLoading(false);
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    });

    return (
        <form className="w-full" onSubmit={onSubmit}>
            <div className="flex flex-col gap-6 p-8">
                <div className="text-2xl font-merri-bold tracking-tighter">
                    {title}
                </div>
                <div>
                    <RFTextField
                        control={control}
                        name="username"
                        fullWidth
                        label="User Name"
                        type="password"
                        asterisk
                        iconPosition="right"
                    />
                    <RFTextField
                        control={control}
                        name="password"
                        label="Password"
                        fullWidth
                        type="password"
                        asterisk
                        iconPosition="right"
                    />
                    <RFTextField
                        control={control}
                        name="comment"
                        label="Comment/Text"
                        type="text"
                        fullWidth
                        iconPosition="right"
                    />
                    <PrimaryButton
                        isDrawerButton
                        className={classNames(
                            'w-full max-w-[224px] !font-extrabold'
                        )}
                        color="#2E672F"
                        variant="filled"
                        type="submit"
                        name={loading ? '' : 'Save and Update'}
                        loading={loading}
                        disabled={loading}
                    />
                </div>
            </div>
        </form>
    );
};
export default PayModumForm;
