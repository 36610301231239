import { useCallback } from 'react';
import {
    useStore,
    Position,
    internalsSymbol,
    Node,
    XYPosition,
    EdgeProps,
} from 'reactflow';
import './SimpleFloatingEdges.css';
import { EdgeParams, Handle } from '../RuleBuilder.type';

function getParams(nodeA: Node, type: string): [number, number, Position] {
    let position: Position;

    if (nodeA.type === 'input') {
        position = Position.Right;
    } else if (type === 'source') {
        position = Position.Right;
    } else if (type === 'target') {
        position = Position.Left;
    } else {
        position = Position.Right;
    }

    const [x, y] = getHandleCoordsByPosition(nodeA, position);
    return [x, y, position];
}

function getHandleCoordsByPosition(
    node: Node,
    handlePosition: Position
): [number, number] {
    const handle = (node as any)[internalsSymbol].handleBounds.source.find(
        (h: Handle) => h.position === handlePosition
    );
    if (!handle) {
        return [0, 0];
    }

    let offsetX = handle.width ? handle.width / 2 : 0;
    let offsetY = handle.height ? handle.height / 2 : 0;

    switch (handlePosition) {
        case Position.Left:
            offsetX = 0;
            break;
        case Position.Right:
            offsetX = handle.width;
            break;
        case Position.Top:
            offsetY = 0;
            break;
        case Position.Bottom:
            offsetY = handle.height;
            break;
        default:
            break;
    }

    const x =
        (node.positionAbsolute as XYPosition).x + Number(handle.x) + offsetX;
    const y =
        (node.positionAbsolute as XYPosition).y + Number(handle.y) + offsetY;

    return [x, y];
}

export function getEdgeParams(source: Node, target: Node): EdgeParams {
    const [sx, sy, sourcePos] = getParams(source, 'source');
    const [tx, ty, targetPos] = getParams(target, 'target');
    return {
        sx,
        sy,
        tx,
        ty,
        sourcePos,
        targetPos,
    };
}

interface SimpleFloatingEdgeProps extends EdgeProps {
    style?: React.CSSProperties;
}

function SimpleFloatingEdge({
    id,
    source,
    target,
    style,
}: SimpleFloatingEdgeProps) {
    const sourceNode = useStore(
        useCallback((store) => store.nodeInternals.get(source), [source])
    );
    const targetNode = useStore(
        useCallback((store) => store.nodeInternals.get(target), [target])
    );

    if (!sourceNode || !targetNode) {
        return null;
    }

    const { sx, sy, tx, ty } = getEdgeParams(sourceNode, targetNode);

    return (
        <line
            key={id}
            id={id}
            className="react-flow__edge-path"
            x1={sx}
            y1={sy}
            x2={tx}
            y2={ty}
            strokeWidth={5}
            style={style}
        />
    );
}

export default SimpleFloatingEdge;
