import './Dropdown.css';
import { DropdownProps } from '../common';
import { SvgIcon } from '../index';

const Dropdown = ({
    submenus,
    dropdown,
    handleClick,
    isExportModal = false,
    className,
}: DropdownProps) => (
    <div
        className={` ${
            dropdown
                ? `flex flex-col absolute rounded border Dropdown ${
                      className as string
                  }`
                : 'none'
        }`}
    >
        {submenus.map((submenu: any, index: number) => (
            <div
                key={index}
                className="menu-items flex items-center"
                onClick={() => handleClick(submenu.title)}
            >
                {submenu.icon && (
                    <SvgIcon className="h-[19px]" icon={submenu.icon} />
                )}
                <div
                    className={`sub-menu cursor-pointer ${
                        isExportModal ? 'font-poppins-medium text-[10px]' : ''
                    }`}
                >
                    {submenu.title}
                </div>
            </div>
        ))}
    </div>
);

export default Dropdown;
