import { getMerchant } from 'services/api/api';
import React, { useEffect, useState } from 'react';
import { showToast } from 'data/utils/toast';
import LoadingSpinner from 'components/Common/Loader/Loader';
import { getDecryptValue, getListName } from 'data/utils/common';
import { useAuthContext } from 'context/Auth/AuthContext';
import {
    IMerchant,
    IProfileDataType,
    paymentDetailsKeys,
    playerProfileKeys,
} from '../OnBoardCashier.type';
import { generateTitle } from '../onBoardCashierUtill';

const Profile = () => {
    const [merchantData, setMerchantData] = useState<IProfileDataType>({
        PlayerProfile: {},
        PaymentDetails: {},
    });
    const [isLoading, setIsLoading] = useState(true);
    const { PlayerProfile, PaymentDetails } = merchantData;
    const { auth } = useAuthContext();
    const merchantMid = auth?.selectedBrand?.mid || auth?.selectedCompany?.mid;

    const combinedPaymentData = {
        ...PaymentDetails?.card,
        ...PaymentDetails?.card?.billingAddress,
    };
    const formattedPlayerProfile = {
        ...PlayerProfile,
        ...PlayerProfile?.address,
    };

    const formattedPaymentDetails = {
        ...combinedPaymentData,
        pan: getDecryptValue(combinedPaymentData?.pan),
        ...(combinedPaymentData?.expiry?.month && {
            expiry: `${combinedPaymentData.expiry.month as string}/${
                combinedPaymentData.expiry.year as string
            }`,
        }),
    };

    delete formattedPaymentDetails?.billingAddress;
    delete formattedPlayerProfile?.address;

    const getMerchantData = () => {
        getMerchant({
            userId: auth?.authUser?._id,
            merchantId: merchantMid,
        })
            .then((res: { details: IMerchant }) => {
                const playerData: Record<string, any> = {};
                const paymentInfo: Record<string, any> = {};
                if (!res?.details) {
                    setIsLoading(false);
                    return;
                }
                Object.entries(res?.details).forEach((entry) => {
                    const [key, value] = entry;
                    if (playerProfileKeys?.includes(key)) {
                        playerData[key] = value;
                    } else if (paymentDetailsKeys?.includes(key)) {
                        paymentInfo[key] = value;
                    }
                });
                setMerchantData({
                    PlayerProfile: playerData,
                    PaymentDetails: paymentInfo,
                });
                setIsLoading(false);
            })
            .catch((err: any) => {
                setIsLoading(false);
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    useEffect(() => {
        getMerchantData();
    }, [auth?.authUser?._id, auth?.selectedCompany]);

    return (
        <div>
            {!isLoading ? (
                <>
                    <p className="font-extrabold text-2xl text-[#131119]">
                        Player Profile
                    </p>
                    <div className="grid grid-cols-3">
                        {PlayerProfile &&
                            Object.keys(formattedPlayerProfile).map((key) => (
                                <div key={key}>
                                    <span>{generateTitle(key)}</span> :{' '}
                                    <span>
                                        {getListName(
                                            String(formattedPlayerProfile[key])
                                        )}
                                    </span>
                                </div>
                            ))}
                    </div>
                    {Object.keys(PaymentDetails)?.length > 0 && (
                        <>
                            <p className="font-extrabold text-2xl text-[#131119]">
                                Payment Details
                            </p>
                            <div className="grid grid-cols-3">
                                {PaymentDetails &&
                                    Object.keys(formattedPaymentDetails).map(
                                        (key) => (
                                            <div key={key}>
                                                <span>
                                                    {generateTitle(key)}
                                                </span>{' '}
                                                :{' '}
                                                <span>
                                                    {
                                                        formattedPaymentDetails[
                                                            key
                                                        ]
                                                    }
                                                </span>
                                            </div>
                                        )
                                    )}
                            </div>
                        </>
                    )}
                </>
            ) : (
                <div className="mt-5">
                    <LoadingSpinner />
                </div>
            )}
        </div>
    );
};

export default Profile;
