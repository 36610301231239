import React from 'react';
import { useController } from 'react-hook-form';
import classNames from 'classnames';
import TextField from '../TextField/input';
import { RFTextFieldProps } from '../common';

const RFTextField: React.FunctionComponent<RFTextFieldProps> = ({
    control,
    name,
    onChange,
    placeholder,
    isLabelMarginBottom = true,
    ...rest
}) => {
    const {
        field: { onChange: onChangeRF, onBlur, value },
        fieldState,
    } = useController({
        name,
        control,
    });

    return (
        <TextField
            name={name}
            value={value ?? ''}
            onChange={(event) => {
                onChangeRF?.(event);
                onChange?.(event);
            }}
            placeholder={placeholder ?? ''}
            isLabelMarginBottom={isLabelMarginBottom}
            onBlur={onBlur}
            className={classNames(
                'text-gray-400',
                !fieldState.error?.message
                    ? 'text-gray-400 bg-gray-200'
                    : 'bg-red-300 border-0 '
            )}
            isError={!!fieldState.error?.message}
            errorMessage={fieldState.error?.message ?? ''}
            {...rest}
        />
    );
};

export default RFTextField;
