import React from 'react';
import cc from 'currency-codes';
import { Control, Controller } from 'react-hook-form';
import classNames from 'classnames';
import useFilteredBrandData from 'hooks/useFilteredBrandData';
import { useAuthContext } from 'context/Auth/AuthContext';
import { PaymentProviderConstant } from 'data/constants/payment.constants';
import { RFSelectField, RFTextField, Select, Switch } from '../../../../Common';
import { IProviderIdListData } from '../../Payout/MerchantPayout.type';

interface IOtherInfoProps {
    control: Control<any>;
    setValue: any;
    pspType: string;
    providerIdList?: IProviderIdListData[];
}

const OtherInformation = ({
    control,
    setValue,
    pspType,
    providerIdList,
}: IOtherInfoProps) => {
    const handleCurrencyChange = (value: string, key: string) => {
        if (key === 'currency') {
            setValue('currencyCode', cc.code(value)?.number as string);
        } else if (key === 'currencyCode') {
            setValue('currency', cc.number(value)?.code as string);
        }
    };
    const { auth } = useAuthContext();
    const brands = useFilteredBrandData(auth, pspType);
    const brandList = [{ _id: '', name: '-Select-' }, ...brands];

    const handleBrandSelect = (
        e: React.ChangeEvent<HTMLSelectElement>,
        onChange: (event: any) => void
    ) => {
        const selectedOption = brandList?.find(
            (option) => option.name === e.target.value
        );
        onChange(selectedOption?._id === '' ? undefined : selectedOption?._id);
    };

    return (
        <div>
            <div className="mt-3 mb-4 text-base font-bold tracking-tight text-black font-poppins-bold">
                Other Information
            </div>
            <div className="grid grid-cols-2 items-center gap-x-6">
                <RFSelectField
                    name="currency"
                    control={control}
                    fullWidth
                    asterisk
                    bordered
                    onChange={(e) =>
                        handleCurrencyChange(e.target.value, 'currency')
                    }
                    label="Currency"
                    options={[
                        {
                            id: '',
                            name: 'Select Currency',
                        },
                        ...cc.data.map((data) => ({
                            id: data.code,
                            name: data.code,
                        })),
                    ]}
                />

                <RFSelectField
                    name="currencyCode"
                    control={control}
                    fullWidth
                    asterisk
                    bordered
                    onChange={(e) =>
                        handleCurrencyChange(e.target.value, 'currencyCode')
                    }
                    label="Currency Code"
                    options={[
                        {
                            id: '',
                            name: 'Select Currency Code',
                        },
                        ...cc.data.map((data) => ({
                            id: data.number,
                            name: data.number,
                        })),
                    ]}
                />
                {pspType !== PaymentProviderConstant.ONBOARD_CASHIER && (
                    <div className="mb-4">
                        <Controller
                            name="brandId"
                            control={control}
                            render={({
                                field: { onChange, value, ...field },
                                fieldState,
                            }) => {
                                const brandName = brandList.find(
                                    (v) => v._id === value
                                );
                                return (
                                    <Select
                                        bordered
                                        label="Brand"
                                        asterisk
                                        isMarginBottom={false}
                                        isGroup
                                        value={brandName?.name ?? ''}
                                        fullWidth
                                        options={brandList?.map((v) => ({
                                            name: v.name,
                                            id: v._id,
                                        }))}
                                        handleOnSelect={(e) =>
                                            handleBrandSelect(e, onChange)
                                        }
                                        className={classNames(
                                            'text-gray-400',
                                            fieldState.error?.message
                                                ? 'bg-red-300 border-0'
                                                : 'text-gray-400 bg-gray-200'
                                        )}
                                        isError={!!fieldState.error?.message}
                                        errorMessage={
                                            fieldState.error?.message ?? ''
                                        }
                                        {...field}
                                    />
                                );
                            }}
                        />
                    </div>
                )}

                <RFTextField
                    control={control}
                    name="comment"
                    fullWidth
                    placeholder="Comment"
                    label="Comment"
                    type="text"
                />
                {pspType === PaymentProviderConstant.PAYMODUM && (
                    <RFSelectField
                        name="providerTransactionId"
                        bordered
                        control={control}
                        fullWidth
                        asterisk
                        label="Provider TransactionId"
                        options={providerIdList || []}
                    />
                )}

                <div className="flex items-center justify-between">
                    <div className="text-sm font-semibold text-gray-500">
                        Blocked
                    </div>
                    <Controller
                        name="blocked"
                        control={control}
                        render={({
                            field: { value, onChange },
                            fieldState,
                        }) => (
                            <>
                                <Switch
                                    onChange={(e) => onChange(e.target.checked)}
                                    isToggled={value}
                                    className="rounded-2xl bg-gray-200"
                                />
                                {fieldState.error?.message ? (
                                    <div className="mt-2 flex self-baseline text-xs font-medium text-red-200">
                                        {fieldState.error?.message}
                                    </div>
                                ) : null}
                            </>
                        )}
                    />
                </div>
                <div className="flex items-center justify-between">
                    <div className="text-sm font-semibold text-gray-500">
                        Kyc Status
                    </div>
                    <Controller
                        name="kycVerified"
                        control={control}
                        render={({
                            field: { value, onChange },
                            fieldState,
                        }) => (
                            <>
                                <Switch
                                    onChange={(e) => onChange(e.target.checked)}
                                    isToggled={value}
                                    className="rounded-2xl bg-gray-200"
                                />
                                {fieldState.error?.message ? (
                                    <div className="mt-2 flex self-baseline text-xs font-medium text-red-200">
                                        {fieldState.error?.message}
                                    </div>
                                ) : null}
                            </>
                        )}
                    />
                </div>
            </div>
        </div>
    );
};

export default OtherInformation;
