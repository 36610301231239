import { ChangeEvent, useMemo, useState } from 'react';
import { Pagination, Table } from 'components/Common';
import { IUserTableProps } from '../User.type';
import { UserTableColumn } from './UserTableUtill';

export const UserTable = ({
    companyUsers,
    checkedUsers,
    setCompanyUsers,
    setIsOpen,
    setCheckedUsers,
    setUserData,
    setIsEditUser,
    isAllowedUser,
}: IUserTableProps) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageLimit, setPageLimit] = useState<number>(10);

    const isAllChecked =
        companyUsers?.length > 0 &&
        companyUsers?.length === checkedUsers?.length;

    const updatedUserStatus = (id: string, status: boolean) => {
        const filter = companyUsers.map((v) => {
            if (v._id === id) {
                return {
                    ...v,
                    isDeleted: status,
                };
            }
            return v;
        });
        setCompanyUsers([...filter]);
    };

    const currentTableData = useMemo(
        () =>
            (companyUsers || []).slice(
                currentPage * pageLimit - pageLimit,
                currentPage * pageLimit
            ) || [],
        // eslint-disable-next-line
        [companyUsers, currentPage, pageLimit]
    );

    const handleOnSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        if (currentTableData?.length < parseInt(e.target.value)) {
            setCurrentPage(1);
        }
        setPageLimit(parseInt(e.target.value));
    };

    return (
        <div>
            <div className="max-h-[calc(100vh-250px)] !overflow-y-scroll !overflow-x-scroll dataTable rounded-xl">
                <div className="flex w-full max-w-full items-center">
                    <Table
                        isAdminUserModal
                        data={currentTableData}
                        columns={UserTableColumn(
                            isAllChecked,
                            setCheckedUsers,
                            checkedUsers,
                            updatedUserStatus,
                            companyUsers,
                            setUserData,
                            setIsEditUser,
                            setIsOpen,
                            isAllowedUser
                        )}
                        className="w-full"
                        colSpan={10}
                    />
                </div>
            </div>
            <div className="mt-2">
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={companyUsers?.length || 0}
                    pageSize={pageLimit}
                    onPageChange={(page: number) => setCurrentPage(page)}
                    siblingCount={1}
                    handleOnSelect={handleOnSelect}
                    text="Show Users"
                />
            </div>
        </div>
    );
};
