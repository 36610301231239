// import Chatra from '@chatra/chatra';
import { Menubar, SvgIcon } from 'components/Common';
import { useAuthContext } from 'context/Auth/AuthContext';
import { useSideBarItemAsPerRole } from 'hooks/commonHook';
import './sidebar.css';
// import { PrimaryButton } from '../Common';

const SideMenu = () => {
    const { auth } = useAuthContext();
    const { getSideBarItemAsPerRole } = useSideBarItemAsPerRole({ auth });
    // const [isChecked, setIsChecked] = useState(false);

    return (
        <div className="w-64 background-gradient z-[101]">
            <div className="flex flex-col justify-between overflow-auto h-[100vh]">
                <div className="p-4">
                    <SvgIcon icon="MOZARTO_ICON" />
                    <div className="mt-4">
                        <Menubar menuItem={getSideBarItemAsPerRole} />
                    </div>

                    {/* <div className="mt-3 flex items-center justify-between h-[44px]">
                        <span className="ml-4 text-xs font-merri-bold tracking-tighter text-green-100">
                            Sandbox Mode
                        </span>
                        <Switch
                            isSideMenu
                            className={`rounded-2xl ${
                                isChecked ? 'bg-[#deecaa]' : 'bg-white'
                            } `}
                            isToggled={isChecked}
                            handleCheck={() => setIsChecked(!isChecked)}
                        />
                    </div> */}
                </div>
                {/* <div className="p-6">
                    <div className="flex flex-col items-center rounded-[28px] bg-green-600 p-4 text-white">
                        <div className="flex flex-col items-center gap-3">
                            <span className="text-base font-extrabold tracking-tighter font-merri-bold">
                                Need more help?
                            </span>
                            <div className="flex flex-col text-center">
                                <span className="text-xs tracking-tight font-poppins-regular">
                                    24/7 support is available,
                                </span>
                                <span className="text-xs tracking-tight font-poppins-regular">
                                    start a chat now
                                </span>
                            </div>
                        </div>
                        <div className="w-full">
                            <PrimaryButton
                                onClick={() => {
                                    Chatra('openChat', true);
                                }}
                                isDrawerButton
                                type="button"
                                name="Chat Now"
                                color="#8FB131"
                                variant="filled"
                                className="bg-[#8FB131] h-[40px] font-extrabold hover:bg-[#2E672F] hover:border-green-600 focus:border-green-100 rounded-xl w-full mt-5"
                            />
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default SideMenu;
