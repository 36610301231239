import React, { ChangeEvent } from 'react';
import { useController } from 'react-hook-form';
import classNames from 'classnames';
import { Select } from '../index';
import { RFSelectFieldProps } from '../common';

const RFSelectField: React.FunctionComponent<RFSelectFieldProps> = ({
    control,
    name,
    onChange,
    placeholder,
    label,
    options,
    isDisabled,
    ...rest
}) => {
    const {
        field: { onChange: onChangeRF, onBlur, value },
        fieldState,
    } = useController({
        name,
        control,
    });

    const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        onChangeRF?.(
            !e.target.value || e.target.value === 'Select' ? undefined : e
        );
        onChange?.(
            !e.target.value || e.target.value === 'Select' ? undefined : e
        );
    };

    return (
        <Select
            isGroup
            fullWidth
            value={value ?? ''}
            asterisk
            options={options}
            handleOnSelect={handleSelect}
            isDisabled={isDisabled}
            onBlur={onBlur}
            label={label}
            className={classNames(
                'text-gray-400 h-[46px]',
                fieldState.error?.message
                    ? 'bg-red-300 border-0 '
                    : 'text-gray-400 bg-gray-200'
            )}
            isError={!!fieldState.error?.message}
            errorMessage={fieldState.error?.message ?? ''}
            {...rest}
        />
    );
};

export default RFSelectField;
