import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { Control } from 'react-hook-form';
import {
    IBrandsPermissions,
    ICompanyData,
    ICreateUserData,
    IGroup,
    IPagePermissions,
} from 'data/common';
import { BulkActionTypes } from 'data/constants/common.constants';

export const Options = [
    { id: '', name: BulkActionTypes.BULK_ACTION },
    { id: 'disable', name: BulkActionTypes.DISABLE },
];

export const UserRolesOption = [
    { _id: '', name: 'Select Role' },
    { _id: 'USER', name: 'User' },
    { _id: 'ADMIN', name: 'Admin' },
];

export interface IUserHeaderProps {
    openDrawer: () => void;
    getUsers: () => void;
    checkedUsers: Array<string>;
    setCheckedUsers: Dispatch<SetStateAction<Array<string>>>;
    isAllowedUser: boolean;
}

export interface IUserData {
    _id: string;
    name: string;
    email: string;
    phone: string;
    isCustomGroup: boolean;
    role: string;
    isDeleted?: boolean;
    company: string;
    createdAt: string;
    updatedAt: string;
    secret?: string;
    lastLogin?: string;
    job?: string;
    group?: string;
    permissionId?: string;
    updatedBy?: string;
    createdBy?: string;
}

export interface IUserTableProps {
    companyUsers: Array<IUserData>;
    checkedUsers: Array<string>;
    setCompanyUsers: Dispatch<SetStateAction<Array<IUserData>>>;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    setCheckedUsers: Dispatch<SetStateAction<Array<string>>>;
    setUserData: Dispatch<SetStateAction<ICreateUserData | null>>;
    setIsEditUser: Dispatch<SetStateAction<boolean>>;
    isAllowedUser: boolean;
}

export interface IUserDrawerProps {
    isOpen: boolean;
    openDrawer: () => void;
    isEditUser?: boolean;
    userData: ICreateUserData | null;
    setPageAreaData: Dispatch<SetStateAction<IPagePermissions[]>>;
    pageAreaData: IPagePermissions[];
    accessAreaData: IBrandsPermissions[];
    setAccessAreaData: Dispatch<SetStateAction<IBrandsPermissions[]>>;
    getUsers: () => void;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export interface IUserDrawerFormProps {
    isEditUser?: boolean;
    openDrawer: () => void;
    userData: ICreateUserData | null;
    handleOnSelect: (e: ChangeEvent<HTMLSelectElement>) => void;
    groups: IGroup[];
    companyOptionList: (ICompanyData | { _id: string; name: string })[];
    control: Control<IUserType>;
    watch: any;
}

export interface IUserType {
    email: string;
    role?: string | null;
    job: string;
    phone: string;
    name: string;
    company?: string | null;
    group: string;
    pageAreasPermissions?: Array<IPagePermissions> | null;
    brandsPermissions?: Array<IBrandsPermissions> | null;
}

export interface IGetUserByCompany {
    sort: { fieldName: string; order: number };
    companyId?: string;
    brandId?: string;
}
