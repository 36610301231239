import React from 'react';
import './VisibleLoader.css';

const VisibleLoader = () => (
    <div className="loader-overlay">
        <div className="loader" />
    </div>
);

export default VisibleLoader;
