import React from 'react';
import { ICountry } from 'components/OnBoardCashier/OnBoardCashier.type';
import useFetchCountries from 'hooks/useFetchCountries';
import { Control } from 'react-hook-form';
import { RFSelectField, RFTextField } from '../../../../Common';

interface IBillingAddressProps {
    control: Control<any>;
    setValue: any;
}

const BillingAddress = ({ control, setValue }: IBillingAddressProps) => {
    const { countries } = useFetchCountries();

    const handleCountryChange = (value: string, key: string) => {
        if (key === 'countryCode') {
            const filteredCountry: any = countries.find(
                (country: ICountry) => country?.cca2 === value
            );
            setValue('address.country', filteredCountry?.name?.common);
        } else if (key === 'country') {
            const filteredCountry: any = countries.find(
                (country: ICountry) => country?.name?.common === value
            );
            setValue('countryCode', filteredCountry?.cca2);
        }
    };

    return (
        <div>
            <div className="mt-3 mb-4 text-base font-bold tracking-tight text-black font-poppins-bold">
                Billing Address
            </div>
            <div className="grid grid-cols-2 items-center gap-x-6">
                <RFTextField
                    control={control}
                    name="address.line1"
                    fullWidth
                    label="Address Line 1"
                    type="text"
                    asterisk
                />
                <RFTextField
                    control={control}
                    name="address.line2"
                    fullWidth
                    label="Address Line 2"
                    type="text"
                />
                <RFTextField
                    control={control}
                    name="address.postalCode"
                    fullWidth
                    label="Post Code / Zip Code"
                    type="text"
                    asterisk
                />
                <RFTextField
                    control={control}
                    name="address.city"
                    fullWidth
                    label="City"
                    type="text"
                    asterisk
                />
                <RFTextField
                    control={control}
                    name="address.state"
                    fullWidth
                    label="State"
                    type="text"
                    asterisk
                />
                <RFSelectField
                    name="address.country"
                    bordered
                    control={control}
                    fullWidth
                    label="Country"
                    asterisk
                    onChange={(e) =>
                        handleCountryChange(e.target.value, 'country')
                    }
                    options={[
                        {
                            id: '',
                            name: 'Select Country',
                        },
                        ...countries.map((data: any, index) => ({
                            id: index,
                            name: data?.name?.common,
                        })),
                    ]}
                />
                <RFSelectField
                    name="countryCode"
                    control={control}
                    fullWidth
                    asterisk
                    bordered
                    label="Country Code"
                    onChange={(e) =>
                        handleCountryChange(e.target.value, 'countryCode')
                    }
                    options={[
                        {
                            id: '',
                            name: 'Select Country Code',
                        },
                        ...countries.map((data: any, index) => ({
                            id: index,
                            name: data?.cca2,
                        })),
                    ]}
                />
            </div>
        </div>
    );
};

export default BillingAddress;
