import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { PrimaryButton } from 'components/Common';
import { britePaymentPayIn } from 'services/api/api';
import { zodResolver } from '@hookform/resolvers/zod';
import { showToast } from 'data/utils/toast';
import {
    generateRandomData,
    PaymentProviderConstant,
} from 'data/constants/payment.constants';
import useFetchCountries from 'hooks/useFetchCountries';
import { useAuthContext } from 'context/Auth/AuthContext';
import useFilteredBrandData from 'hooks/useFilteredBrandData';
import { ICleoType } from './Merchant.type';
import { cleoFormValidation } from './Merchant.schema';
import UserInformation from '../Common/UserInformation/UserInformation';
import BillingAddress from '../Common/BillingAddress/BillingAddress';
import OtherInformation from '../Common/OtherInformation/OtherInformation';
import TransactionVelocity from '../Common/TransactionVelocity/TransactionVelocity';

const Brite = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const { control, handleSubmit, reset, setValue } = useForm<ICleoType>({
        resolver: zodResolver(cleoFormValidation),
        defaultValues: {
            blocked: false,
            kycVerified: false,
            isVelocity: false,
        },
    });
    const { countries } = useFetchCountries();
    const { auth } = useAuthContext();
    const brands = useFilteredBrandData(auth, PaymentProviderConstant.BRITE);

    const handleOnSubmit = handleSubmit((formData) => {
        if (!formData) return;
        setLoading(true);
        const payload = {
            ...formData,
            baseamount: parseInt(formData?.baseamount || ''),
            country: formData?.address?.country,
        };
        britePaymentPayIn(payload)
            .then((res) => {
                if (res.data) {
                    reset({});
                    window.open(res?.data?.url, '_blank');
                    showToast(res?.message, 'success');
                }
                setLoading(false);
            })
            .catch((err) => {
                showToast(
                    typeof err.message === 'string'
                        ? err.message
                        : 'something went wrong',
                    'error'
                );
                setLoading(false);
            });
    });

    return (
        <>
            <div className="flex w-full items-center justify-end">
                <PrimaryButton
                    color="#8FB131"
                    variant="filled"
                    name="Generate Data"
                    onClick={() =>
                        generateRandomData(
                            PaymentProviderConstant.BRITE,
                            reset,
                            countries,
                            brands
                        )
                    }
                    className="!h-[45px] w-fit px-4"
                />
            </div>
            <form className="w-full" onSubmit={handleOnSubmit}>
                <UserInformation
                    control={control}
                    pspType={PaymentProviderConstant.BRITE}
                    setValue={setValue}
                />
                <BillingAddress control={control} setValue={setValue} />
                <OtherInformation
                    control={control}
                    setValue={setValue}
                    pspType={PaymentProviderConstant.BRITE}
                />
                <TransactionVelocity control={control} />
                <div className="mt-10">
                    <PrimaryButton
                        type="submit"
                        isDrawerButton
                        className={classNames('w-full max-w-[224px]')}
                        color="#2E672F"
                        variant="filled"
                        name={loading ? '' : 'Submit'}
                        loading={loading}
                        disabled={loading}
                    />
                </div>
            </form>
        </>
    );
};

export default Brite;
