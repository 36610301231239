import React, { ChangeEvent, useState } from 'react';
import { Select } from 'components/Common';
import {
    PaymentProviderConstant,
    PaymentProvider,
} from 'data/constants/payment.constants';
import Trust from './Trust';
import Cleo from './Cleo';
import Paymodum from './Paymodum';
import Brite from './Brite';

const renderPaymentComponent = (method: string): JSX.Element | null => {
    switch (method) {
        case PaymentProviderConstant.TRUST:
            return <Trust />;
        case PaymentProviderConstant.CLEO:
            return <Cleo />;
        case PaymentProviderConstant.PAYMODUM:
            return <Paymodum />;
        case PaymentProviderConstant.BRITE:
            return <Brite />;
        default:
            return null;
    }
};

const Merchant = () => {
    const [transactionMethod, setTransactionMethod] = useState<string>(
        PaymentProviderConstant.TRUST
    );

    return (
        <div className="py-10 px-8 flex justify-center">
            <div className="w-full">
                <div className="flex justify-between">
                    <div className="font-merri-bold tracking-tighter text-center text-2xl text-[#131119] self-center">
                        Merchants
                    </div>
                    <div className="flex items-center gap-1">
                        {' '}
                        <div className="font-poppins-bold text-sm pr-3">
                            Select Payment Method :
                        </div>
                        <div className="w-[328px]">
                            <Select
                                isMarginBottom={false}
                                isGroup
                                isCompanyAdmin
                                value={transactionMethod}
                                className="!border-2 !border-[#818181] !rounded-[12px] hover:!border-[#9ed79f]"
                                options={PaymentProvider}
                                handleOnSelect={(
                                    e: ChangeEvent<HTMLSelectElement>
                                ) => {
                                    setTransactionMethod(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="pt-8">
                    {renderPaymentComponent(transactionMethod)}
                </div>
            </div>
        </div>
    );
};
export default Merchant;
