import { z, ZodType } from 'zod';
import { IPayModumeType } from '../PayIn/Merchant.type';

export const payModumFormValidation: ZodType<IPayModumeType> = z
    .object({
        userBalance: z
            .number({
                required_error: 'Please enter your total balance',
            })
            .min(1, 'Please enter your total balance'),
        providerTransactionId: z
            .string({
                required_error: 'Please select your provider Id',
            })
            .trim()
            .min(1, 'Please select your provider Id'),
        date_of_birth: z
            .string({
                required_error: 'Please enter your date of birth',
            })
            .trim()
            .min(1, 'Please enter your date of birth'),

        kycVerified: z.boolean(),
        blocked: z.boolean(),
        isVelocity: z.boolean(),
        transactionCount: z
            .string({
                required_error: 'Please enter your transaction count',
            })
            .trim()
            .min(1, 'Please enter your transaction count'),
        brandId: z
            .string({
                required_error: 'Please enter Brand',
            })
            .trim()
            .min(1, 'Please enter Brand')
            .min(1, 'Please enter Brand'),
        countryCode: z
            .string({
                required_error: 'Please enter your country code',
            })
            .trim()
            .min(1, 'Please enter your country code'),
        tags: z.array(z.string()).optional().nullable(),
        bonusCode: z.string().optional().nullable(),
        baseamount: z
            .string({ required_error: 'Please enter your amount' })
            .trim()
            .min(1, 'Please enter your amount'),
        currency: z
            .string({ required_error: 'Please select your currency' })
            .trim()
            .min(1, 'Please select your currency'),
        currencyCode: z
            .string({ required_error: 'Please select your currency code' })
            .trim()
            .min(1, 'Please select your currency code'),
        firstName: z
            .string({ required_error: 'Please enter your first name' })
            .trim()
            .min(1, 'Please enter your first name'),
        lastName: z
            .string({ required_error: 'Please enter your last name' })
            .trim()
            .min(1, 'Please enter your last name'),
        phone: z
            .string({ required_error: 'Please enter your phone number' })
            .min(6, 'phone number allows the min 6 digit'),
        gender: z
            .string({ required_error: 'Please select your gender' })
            .trim()
            .min(1, 'Please select your gender'),
        email: z
            .string({ required_error: 'Please enter your email' })
            .trim()
            .min(1, 'Please enter your email')
            .email({ message: 'Email is invalid type' }),
        emailVerification: z
            .string({ required_error: 'Please enter your email' })
            .trim()
            .min(1, 'Please enter your email')
            .email({ message: 'Email is invalid type' }),
        verificationDate: z
            .string({
                required_error: 'Please enter your verification date',
            })
            .trim()
            .min(1, 'Please enter your verification date'),
        partnerName: z
            .string({
                required_error: 'Please enter your partner name',
            })
            .trim()
            .min(1, 'Please enter your partner name'),
        address: z.object({
            line1: z
                .string({ required_error: 'Please enter your address' })
                .trim()
                .min(1, 'Please enter your address'),
            line2: z.string().optional(),
            postalCode: z
                .string({
                    required_error: 'Please enter your postal code',
                })
                .trim()
                .min(1, 'Please enter your postal code'),
            state: z
                .string({ required_error: 'Please select your state' })
                .trim()
                .min(1, 'Please select your state'),
            country: z
                .string({ required_error: 'Please select your country' })
                .trim()
                .min(1, 'Please select your country'),
            city: z
                .string({ required_error: 'Please select your city' })
                .trim()
                .min(1, 'Please select your city'),
        }),
        card: z.object({
            expiry: z
                .string({ required_error: 'Please select expiry date' })
                .trim()
                .min(1, 'Please select expiry date'),
            pan: z
                .string({
                    required_error: 'Please enter your pan',
                })
                .trim()
                .min(1, 'Please enter your pan')
                .max(16, 'Card number must be a maximum of 16 '),
            cvv: z
                .string({ required_error: 'Please enter your cvv number' })
                .trim()
                .min(1, 'Please enter your cvv number')
                .max(3, 'CVV number must be a maximum of 3'),
            paymentMethod: z
                .string({
                    required_error: 'Please select your card type',
                })
                .trim()
                .min(1, 'Please select your card type'),
            name_on_card: z
                .string({
                    required_error: 'Please enter your card holder name',
                })
                .trim()
                .min(1, 'Please enter your card holder name'),
        }),
    })
    .required();
