import { IBrandsPermissions, IPagePermissions } from 'data/common';
import { TextField } from 'components/Common';
import { IGetAllBrandData, IGetAllPageAreasData } from 'data/types/response';
import { IColumnType } from 'components/Common/common';

export const GroupDrawerPageAreaTableColumns = (
    isDisabled: boolean,
    onChange: (...event: any[]) => void,
    value: IPagePermissions[]
): IColumnType<IGetAllPageAreasData>[] => [
    {
        key: 'name',
        width: '200px',
        title: (
            <span className="font-merri-bold tracking-tight">
                Page Areas <span className="text-red-200">*</span>
            </span>
        ),
    },
    {
        key: 'read',
        width: 10,
        title: <span className="font-poppins-bold text-xs">Read</span>,
        render: (_, i) => (
            <TextField
                disabled={isDisabled}
                checked={
                    value?.find(
                        (items: IPagePermissions) =>
                            items?.pageAccessId === i?._id
                    )?.isRead ||
                    value?.find(
                        (item: IPagePermissions) =>
                            item?.pageAccessId === i?._id
                    )?.isWrite
                }
                onChange={() => {
                    const record =
                        value &&
                        value?.find((item) => item?.pageAccessId === i?._id);
                    const updated =
                        value &&
                        value?.map((item: IPagePermissions) => {
                            if (
                                record &&
                                record?.isRead &&
                                item?.pageAccessId === i?._id &&
                                !record?.isWrite
                            ) {
                                return {
                                    ...item,
                                    pageAccessId: '',
                                    isRead: false,
                                    isWrite: false,
                                } as IPagePermissions;
                            }

                            if (
                                record &&
                                !record?.isRead &&
                                item?.pageAccessId === i?._id &&
                                record?.isWrite
                            ) {
                                return {
                                    ...item,
                                    pageAccessId: i?._id,
                                    isRead: true,
                                    isWrite: true,
                                } as IPagePermissions;
                            }
                            return item;
                        });

                    let newArrayWithAddRead = updated || [];

                    if (!record) {
                        const addRead = {
                            pageAccessId: i?._id,
                            isRead: true,
                            isWrite: false,
                        } as IPagePermissions;
                        newArrayWithAddRead = [...newArrayWithAddRead, addRead];
                    }
                    const filterDataList = newArrayWithAddRead.filter(
                        (v) => v.pageAccessId !== ''
                    );
                    onChange(filterDataList);
                }}
                value={i?.name}
                name="read"
                isCheckbox
                type="checkbox"
            />
        ),
    },
    {
        key: 'write',
        width: 10,
        title: <span className="font-poppins-bold text-xs">Write</span>,
        render: (_, i) => (
            <TextField
                disabled={isDisabled}
                checked={
                    value?.find((item) => item?.pageAccessId === i?._id)
                        ?.isWrite === undefined
                        ? false
                        : value?.find((item) => item?.pageAccessId === i?._id)
                              ?.isWrite
                }
                onChange={() => {
                    const record =
                        value &&
                        value?.find((item) => item?.pageAccessId === i?._id);
                    const updated =
                        value &&
                        value?.map((ite) => {
                            if (
                                record &&
                                record?.isWrite &&
                                ite?.pageAccessId === record?.pageAccessId &&
                                !record.isRead
                            ) {
                                return {
                                    ...record,
                                    pageAccessId: '',
                                    isRead: false,
                                    isWrite: false,
                                } as IPagePermissions;
                            }
                            if (
                                record &&
                                record?.isWrite &&
                                ite?.pageAccessId === record?.pageAccessId &&
                                record.isRead
                            ) {
                                return {
                                    ...record,
                                    pageAccessId: i?._id,
                                    isWrite: false,
                                    isRead: true,
                                } as IPagePermissions;
                            }
                            if (
                                record &&
                                !record.isWrite &&
                                ite?.pageAccessId === record?.pageAccessId &&
                                record.isRead
                            ) {
                                return {
                                    ...ite,
                                    pageAccessId: i?._id,
                                    isWrite: true,
                                    isRead: true,
                                } as IPagePermissions;
                            }

                            return ite;
                        });

                    let newArrayWithAddRead = updated || [];

                    if (!record) {
                        const addRead = {
                            pageAccessId: i?._id,
                            isRead: true,
                            isWrite: true,
                        } as IPagePermissions;
                        newArrayWithAddRead = [...newArrayWithAddRead, addRead];
                    }

                    onChange(newArrayWithAddRead);
                }}
                value={i?.name}
                name="write"
                isCheckbox
                type="checkbox"
            />
        ),
    },
];

export const GroupDrawerAccessTableColumns = (
    isDisabled: boolean,
    onChange: (...event: any[]) => void,
    value: IBrandsPermissions[]
): IColumnType<IGetAllBrandData>[] => [
    {
        key: 'name',
        width: '200px',
        title: (
            <span className="font-merri-bold tracking-tight">
                Access <span className="text-red-200">*</span>
            </span>
        ),
    },
    {
        key: 'read',
        width: 10,
        title: <span className="font-poppins-bold text-xs">Read</span>,
        render: (_, i) => (
            <TextField
                disabled={isDisabled}
                checked={
                    value?.find((item) => item?.brandId === i?._id)?.isRead ||
                    value?.find((item) => item?.brandId === i?._id)?.isWrite
                }
                onChange={() => {
                    const record =
                        value &&
                        value?.find((item) => item?.brandId === i?._id);

                    const updated =
                        value &&
                        value?.map((item) => {
                            if (
                                record &&
                                record?.isRead &&
                                item?.brandId === i?._id &&
                                !record?.isWrite
                            ) {
                                return {
                                    ...item,
                                    brandId: '',
                                    isRead: false,
                                    isWrite: false,
                                } as IBrandsPermissions;
                            }

                            if (
                                record &&
                                !record?.isRead &&
                                item?.brandId === i?._id &&
                                record?.isWrite
                            ) {
                                return {
                                    ...item,
                                    brandId: i?._id,
                                    isRead: true,
                                    isWrite: true,
                                } as IBrandsPermissions;
                            }
                            return item;
                        });

                    let newArrayWithAddRead = updated || [];

                    if (!record) {
                        const addRead = {
                            brandId: i?._id,
                            isRead: true,
                            isWrite: false,
                        } as IBrandsPermissions;
                        newArrayWithAddRead = [...newArrayWithAddRead, addRead];
                    }
                    const filterDataList = newArrayWithAddRead.filter(
                        (v) => v.brandId !== ''
                    );

                    onChange(filterDataList);
                }}
                value={i?.name}
                name="read"
                isCheckbox
                type="checkbox"
            />
        ),
    },
    {
        key: 'write',
        width: 10,
        title: <span className="font-poppins-bold text-xs">Write</span>,
        render: (_, i) => (
            <TextField
                disabled={isDisabled}
                checked={
                    value?.find((item) => item?.brandId === i?._id)?.isWrite ===
                    undefined
                        ? false
                        : value?.find((item) => item?.brandId === i?._id)
                              ?.isWrite
                }
                onChange={() => {
                    const record =
                        value && value?.find((item) => item?.brandId === i._id);
                    const updated =
                        value &&
                        value.map((item) => {
                            if (
                                record &&
                                record.isWrite &&
                                item?.brandId === record?.brandId &&
                                !record.isRead
                            ) {
                                return {
                                    ...record,
                                    brandId: '',
                                    isRead: false,
                                    isWrite: false,
                                } as IBrandsPermissions;
                            }
                            if (
                                record &&
                                record.isWrite &&
                                item?.brandId === record?.brandId &&
                                record.isRead
                            ) {
                                return {
                                    ...record,
                                    brandId: i?._id,
                                    isWrite: false,
                                    isRead: true,
                                } as IBrandsPermissions;
                            }
                            if (
                                record &&
                                !record.isWrite &&
                                item?.brandId === record?.brandId &&
                                record.isRead
                            ) {
                                return {
                                    ...item,
                                    brandId: i?._id,
                                    isWrite: true,
                                    isRead: true,
                                } as IBrandsPermissions;
                            }

                            return item;
                        });

                    let newArrayWithAddRead = updated || [];

                    if (!record) {
                        const addRead = {
                            brandId: i?._id,
                            isRead: true,
                            isWrite: true,
                        } as IBrandsPermissions;
                        newArrayWithAddRead = [...newArrayWithAddRead, addRead];
                    }
                    onChange(newArrayWithAddRead);
                }}
                value={i?.name}
                name="write"
                isCheckbox
                type="checkbox"
            />
        ),
    },
];
