import React from 'react';
import ReactDrawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { IDrawer } from '../common';
import './Drawer.css';

const Drawer = ({
    drawerDirection,
    toggleDrawer,
    isOpen,
    children,
    drawerSize,
    className,
}: IDrawer) => (
    <ReactDrawer
        size={drawerSize}
        open={isOpen}
        onClose={toggleDrawer}
        direction={drawerDirection}
        className={className}
    >
        <div>{children}</div>
    </ReactDrawer>
);

export default Drawer;
