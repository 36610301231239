import { useState, useEffect } from 'react';

export interface IBrandsData {
    pspType: string;
    name: string;
    _id: string;
    // other properties of brand data
}

interface Company {
    _id: string;
    brandData: IBrandsData[];
    // other properties of company
}

interface AuthContext {
    selectedCompany?: { _id: string };
    companiesAndBrands?: Company[];
}

const useFilteredBrandData = (auth: AuthContext, pspType: string) => {
    const [brands, setBrands] = useState<IBrandsData[]>([]);

    useEffect(() => {
        const getFilteredBrandData = () => {
            const selectedCompanyId = auth?.selectedCompany?._id;
            const companies = auth?.companiesAndBrands || [];

            const selectedCompany = companies.find(
                (company: Company) => company?._id === selectedCompanyId
            );

            if (selectedCompany && selectedCompany?.brandData) {
                const filteredBrands = selectedCompany.brandData.filter(
                    (brand: IBrandsData) => brand.pspType === pspType
                );
                setBrands(filteredBrands);
            } else {
                const allBrands = companies.reduce(
                    (accumulator: IBrandsData[], company: Company) => {
                        if (company?.brandData) {
                            const filteredBrands = company.brandData.filter(
                                (brand: IBrandsData) =>
                                    brand.pspType === pspType
                            );
                            accumulator.push(...filteredBrands);
                        }
                        return accumulator;
                    },
                    []
                );
                setBrands(allBrands);
            }
        };

        getFilteredBrandData();
    }, [auth, pspType]);

    return brands;
};

export default useFilteredBrandData;
