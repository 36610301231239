import React from 'react';
import { JsonView, darkStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

interface RawResponseProps {
    transaction?: any;
}
const RawResponse = ({ transaction }: RawResponseProps) => {
    return <JsonView data={transaction} style={darkStyles} />;
};
export default RawResponse;
