export const conditionList = [
    {
        key: 'Recently Used',
        conditionDataList: [
            {
                condition: '3DS Enrollment',
                type: 'SELECT',
                isDisable: true,
            },
        ],
    },
    {
        key: 'Most Popular',
        conditionDataList: [
            {
                condition: '3DS Enrollment',
                type: 'SELECT',
                isDisable: true,
            },
        ],
    },
    {
        key: 'All Conditions',
        conditionDataList: [
            {
                condition: '3DS Enrollment',
                type: 'SELECT',
                isDisable: true,
            },
        ],
    },
];
