import React from 'react';
import 'flatpickr/dist/plugins/monthSelect/style.css'; // Plugin styles (if applicable)
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import { Control, Controller } from 'react-hook-form';
import { RFTextField } from '../../../../Common';
import DatePicker from '../../../../Common/DatePicker/DatePicker';

const CardDetails = ({ control }: { control: Control<any> }) => {
    const today = new Date();
    const firstDayOfCurrentMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        1
    );

    return (
        <>
            <div className="mt-3 mb-4 text-base font-bold tracking-tight text-black font-poppins-bold">
                Card Details
            </div>
            <RFTextField
                control={control}
                name="card.pan"
                fullWidth
                label="Card Number"
                type="number"
                asterisk
                noArrows
            />
            <RFTextField
                control={control}
                name="card.name_on_card"
                fullWidth
                label="Name"
                type="text"
                asterisk
            />
            <div className="grid grid-cols-5 gap-6">
                <div className="col-span-3">
                    <Controller
                        name="card.expiry"
                        control={control}
                        render={({
                            field: { onChange, ...field },
                            fieldState,
                        }) => (
                            <DatePicker
                                label="Expiry Date"
                                options={{
                                    allowInput: true,
                                    dateFormat: 'm/Y',
                                    minDate: firstDayOfCurrentMonth,
                                    plugins: [
                                        monthSelectPlugin({
                                            shorthand: true, // Display shorthand month (Jan, Feb, etc.)
                                            dateFormat: 'm/Y', // Format as MM/YYYY
                                            altFormat: 'F Y', // Display as "January 2024"
                                        }),
                                    ],
                                }}
                                placeholder="Expiry (MM/YY)"
                                onChange={(
                                    _selectedDates: Date[],
                                    dateStr: string
                                ) => {
                                    onChange(dateStr);
                                }}
                                asterisk
                                isError={!!fieldState.error?.message}
                                errorMessage={fieldState.error?.message ?? ''}
                                {...field}
                            />
                        )}
                    />
                </div>
                <div className="col-span-2">
                    <RFTextField
                        control={control}
                        name="card.cvv"
                        fullWidth
                        label="CVV"
                        type="number"
                        noArrows
                        asterisk
                        maxLength={3}
                    />
                </div>
            </div>
        </>
    );
};

export default CardDetails;
