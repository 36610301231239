import { useEffect } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Drawer } from 'components/Common';
import { useAuthContext } from 'context/Auth/AuthContext';
import { updateGroup } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { IGroupsDrawerProps, IGroupType } from '../Groups.type';
import { formSchemaValidation } from '../Groups.schema';
import { GroupForm } from './GroupForm/GroupForm';

export const EditGroupsDrawer = ({
    isOpen,
    openDrawer,
    loading,
    userCompany,
    getGroups,
    setIsOpen,
    isEditGroupDrawer,
    editGroup,
}: IGroupsDrawerProps) => {
    const { auth } = useAuthContext();
    const { control, handleSubmit, reset, watch } = useForm<IGroupType>({
        resolver: zodResolver(formSchemaValidation(auth.isSuperAdmin)),
        mode: 'onChange',
    });

    useEffect(() => {
        if (!editGroup) return;
        const updateDate = {
            groupName: editGroup?.name,
            companyId: editGroup?.companyId,
            brandsPermissions: editGroup?.permission?.brandsPermissions,
            pageAreasPermissions: editGroup?.permission?.pageAreasPermissions,
        };

        reset(updateDate);
    }, [reset, editGroup]);

    const onSubmit = handleSubmit((formData) => {
        if (!formData) return;
        if (!editGroup) return;
        const payload = {
            name: formData?.groupName,
            companyId: auth.isSuperAdmin
                ? formData?.companyId
                : auth?.authUser?.company,
            brandsPermissions: formData?.brandsPermissions,
            pageAreasPermissions: formData?.pageAreasPermissions,
            permissionId: editGroup?.permissionId,
        };
        updateGroup(editGroup?._id, payload)
            .then((result) => {
                getGroups();
                reset({});
                setIsOpen(false);
                showToast(result?.message, 'success');
            })
            .catch((err) => {
                showToast(err?.errors?.[0]?.msg || err.message, 'error');
            });
    });

    return (
        <Drawer
            drawerSize={400}
            isOpen={isOpen}
            toggleDrawer={() => openDrawer()}
            drawerDirection="right"
            className={classNames(
                'bg-pink-100  max-h-[100vh] relative',
                loading ? '' : 'overflow-y-scroll'
            )}
        >
            <GroupForm
                onSubmit={onSubmit}
                loading={loading}
                control={control}
                openDrawer={() => openDrawer()}
                isSuperAdmin={auth.isSuperAdmin}
                userCompany={userCompany}
                isOpen={isOpen}
                isEditGroupDrawer={isEditGroupDrawer}
                watch={watch}
            />
        </Drawer>
    );
};
