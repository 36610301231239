import { Outlet } from 'react-router-dom';
import { Header, SideMenu } from '../index';
import './layout.css';

const Layout = () => (
    <div className="flex w-full">
        <SideMenu />
        <div>
            <Header />
            <div className="bg-gray-700 content overflow-y-auto">
                <Outlet />
            </div>
        </div>
    </div>
);

export default Layout;
