import { ChangeEvent } from 'react';
import { PrimaryButton, Select, Tooltip } from 'components/Common';
import { bulkActionForCompany } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { useAuthContext } from 'context/Auth/AuthContext';
import { ICompaniesHeaderProps, Options } from '../Companies.type';

export const CompaniesHeader = ({
    openDrawer,
    getAllCompanies,
    checkedCompanies,
    setCheckedCompanies,
}: ICompaniesHeaderProps) => {
    const { auth } = useAuthContext();

    const handleOnSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value === '1') return;
        bulkActionForCompany({
            companyIds: checkedCompanies,
            type: e.target.value?.toUpperCase(),
        })
            .then((res) => {
                if (res.isSuccess) {
                    getAllCompanies();
                    showToast(res.message);
                    setCheckedCompanies([]);
                }
            })
            .catch((err) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    return (
        <div className="mb-5 flex w-full items-center justify-between max-[970px]:flex-col">
            <div className="font-merri-bold tracking-tighter text-2xl text-[#131119]">
                {auth?.isSuperAdmin
                    ? 'Companies'
                    : auth.isAdmin && 'My Company'}
            </div>
            <div className="flex items-center gap-4">
                <Tooltip
                    text={
                        checkedCompanies?.length === 0
                            ? 'Please select first the Mids from the table that you want to edit'
                            : ''
                    }
                    className="w-[150px]"
                >
                    <Select
                        isDisabled={!checkedCompanies?.length}
                        isGroup
                        isMarginBottom={false}
                        options={Options}
                        handleOnSelect={handleOnSelect}
                        className="w-full max-w-[150px] !pr-2 !border-2 !border-[#818181] !rounded-[12px] px-4 !text-xs !h-11"
                    />
                </Tooltip>
                {auth.isSuperAdmin && (
                    <div className="w-[150px]">
                        <PrimaryButton
                            onClick={openDrawer}
                            type="button"
                            name="Add New Company"
                            color="#8FB131"
                            variant="filled"
                            className="!rounded-xl w-[150px] px-4 !text-xs !h-11"
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
