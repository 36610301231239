import './VerticalTable.css';
import { getStatusColor, getStatusLabel } from '../Common/StatusBadge';

interface IVerticalTableProps {
    data: any;
}

const VerticalTable = ({
    data = [{ PlayerID: '04/12/2023', username: 'test' }],
}: IVerticalTableProps) => (
    <div className="flex w-full">
        <table
            style={{ borderCollapse: 'collapse' }}
            className="w-[100%] text-justify h-fit"
        >
            <tbody>
                {data?.map((items: any) =>
                    Object.keys(items)
                        ?.slice(
                            0,
                            // eslint-disable-next-line no-unsafe-optional-chaining
                            Math.ceil(Object.keys(items)?.length / 2)
                        )
                        ?.map((value, index) => (
                            <tr className="tableCell">
                                <th className="tableRow font-poppins-medium border-solid border-zinc-100  border-[1px] !capitalize">
                                    {value.split('_').join(' ')}
                                </th>
                                <td
                                    className="tableRow border-solid border-zinc-100 border-[1px]"
                                    key={`table-row-cell-${index}`}
                                >
                                    <span
                                        style={{
                                            backgroundColor:
                                                Object.keys(items)[index] ===
                                                    'status²' ||
                                                Object.keys(items)[index] ===
                                                    'status¹'
                                                    ? getStatusColor(
                                                          Object?.values(items)[
                                                              index
                                                          ] as string
                                                      )
                                                    : 'transparent',
                                        }}
                                        className={`${
                                            Object.keys(items)[index] ===
                                                'status²' ||
                                            Object.keys(items)[index] ===
                                                'status¹'
                                                ? 'text-xs px-3 py-1.5 capitalize font-poppins-bold rounded-[6px]'
                                                : ''
                                        } `}
                                    >
                                        {Object.keys(items)[index] ===
                                            'status²' ||
                                        Object.keys(items)[index] === 'status¹'
                                            ? getStatusLabel(
                                                  Object.values(items)[
                                                      index
                                                  ] as string
                                              )
                                            : (Object?.values(items)[
                                                  index
                                              ] as string)}
                                    </span>
                                </td>
                            </tr>
                        ))
                )}
            </tbody>
        </table>
        <table
            style={{ borderCollapse: 'collapse' }}
            className="w-[100%] text-justify h-fit"
        >
            <tbody>
                {data?.map((items: any) =>
                    Object.keys(items)
                        ?.slice(
                            // eslint-disable-next-line no-unsafe-optional-chaining
                            Math.ceil(Object.keys(items)?.length / 2) - 1,
                            Object?.keys(items)?.length
                        )
                        ?.map((value, index) => {
                            if (index !== 0) {
                                return (
                                    <tr className="tableCell">
                                        <th className="tableRow font-poppins-medium border-solid border-zinc-100  border-[1px] !capitalize">
                                            {value.split('_').join(' ')}
                                        </th>
                                        <td
                                            className="tableRow border-solid border-zinc-100 border-[1px]"
                                            key={`table-row-cell-${index}`}
                                        >
                                            <span
                                                style={{
                                                    backgroundColor:
                                                        Object.keys(items)[
                                                            index +
                                                                Math.ceil(
                                                                    // eslint-disable-next-line no-unsafe-optional-chaining
                                                                    Object.keys(
                                                                        items
                                                                    )?.length /
                                                                        2
                                                                ) -
                                                                1
                                                        ] === 'status¹'
                                                            ? getStatusColor(
                                                                  Object?.values(
                                                                      items
                                                                  )[
                                                                      index
                                                                  ] as string
                                                              )
                                                            : 'transparent',
                                                }}
                                                className={`${
                                                    Object.keys(items)[
                                                        index +
                                                            Math.ceil(
                                                                // eslint-disable-next-line no-unsafe-optional-chaining
                                                                Object.keys(
                                                                    items
                                                                )?.length / 2
                                                            ) -
                                                            1
                                                    ] === 'status¹'
                                                        ? 'text-xs px-3 py-1.5 capitalize font-poppins-bold rounded-[6px]'
                                                        : ''
                                                } `}
                                            >
                                                {Object.keys(items)[
                                                    index +
                                                        Math.ceil(
                                                            // eslint-disable-next-line no-unsafe-optional-chaining
                                                            Object.keys(items)
                                                                ?.length / 2
                                                        ) -
                                                        1
                                                ] === 'status¹'
                                                    ? getStatusLabel(
                                                          Object?.values(items)[
                                                              index +
                                                                  Math.ceil(
                                                                      // eslint-disable-next-line no-unsafe-optional-chaining
                                                                      Object.keys(
                                                                          items
                                                                      )
                                                                          ?.length /
                                                                          2
                                                                  ) -
                                                                  1
                                                          ] as string
                                                      )
                                                    : (Object?.values(items)[
                                                          index +
                                                              Math.ceil(
                                                                  // eslint-disable-next-line no-unsafe-optional-chaining
                                                                  Object.keys(
                                                                      items
                                                                  )?.length / 2
                                                              ) -
                                                              1
                                                      ] as string)}
                                            </span>
                                        </td>
                                    </tr>
                                );
                            }
                            return null;
                        })
                )}
            </tbody>
        </table>
    </div>
);

export default VerticalTable;
