import { Dispatch, FormEventHandler, SetStateAction } from 'react';
import {
    IAdminUserData,
    IBrandData,
    IBrandUserData,
    ICompanyData,
} from 'data/common';
import { Control } from 'react-hook-form';
import { BulkActionTypes } from 'data/constants/common.constants';

export interface IBrandDrawerProps {
    openDrawer: () => void;
    isOpen: boolean;
    loading: boolean;
    isEditBrand?: boolean;
    isCreateBrandModal: boolean;
    editSubBrandData: any;
    getUsers: (Id: string) => void;
    adminUsers: IAdminUserData[];
    isEditBrandsDrawer?: boolean;
    companyOptions: ICompanyData[];
    setBrandLoading: Dispatch<SetStateAction<boolean>>;
    getAllBrandsData: () => void;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    brand: IBrandUserData | null;
    setBrand?: Dispatch<SetStateAction<IBrandUserData | null>>;
    brandData?: IBrands;
}

export interface BrandTableProps {
    brands: IBrandData[];
    checkedBrands: Array<string>;
    setCheckedBrands: Dispatch<SetStateAction<Array<string>>>;
    setBrands: Dispatch<SetStateAction<Array<IBrandData>>>;
    setBrand: Dispatch<SetStateAction<IBrandUserData | null>>;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    getUsers: (Id: string) => void;
    setIsEditBrandsDrawer: Dispatch<SetStateAction<boolean>>;
    isAllowedUser: boolean;
}

export interface BrandsHeaderProps {
    getAllBrandsData: () => void;
    openDrawer: () => void;
    setCheckedBrands: Dispatch<SetStateAction<Array<string>>>;
    checkedBrands: Array<string>;
    isAllowedUser: boolean;
}

export interface IBrands {
    name: string | undefined;
    superAdminCompany: string | undefined | null;
    adminUser: string | undefined;
    pspType: string | undefined;
    locals: string | undefined;
    countries: Array<string> | undefined;
    currencies: Array<string> | undefined;
    description?: string | null;
}

export interface IBrandFormProps {
    onSubmit: FormEventHandler<HTMLFormElement>;
    control: Control<IBrands>;
    isEditBrandsDrawer?: boolean;
    openDrawer: (reset: any) => void;
    companyOptionList: (ICompanyData | { _id: string; name: string })[];
    isSuperAdmin: boolean;
    brand: IBrandUserData | null;
    loading: boolean;
    editSubBrandData?: any;
    getUsers: (Id: string) => void;
    adminUsers: IAdminUserData[];
    setIsAdminUser: Dispatch<SetStateAction<boolean>>;
}

export const Options = [
    { id: '', name: BulkActionTypes.BULK_ACTION },
    { id: 'disable', name: BulkActionTypes.DISABLE },
];

export interface IGetAllBrandsData {
    brandId?: string;
    companyId?: string;
}
