export interface IPlayerType {
    comment?: string;
    externalKey?: string;
    date_of_birth: string;
    address: {
        line1: string;
        line2?: string;
        city: string;
        state: string;
        postalCode: string;
        country: string;
    };
    countryCode: string;
    emailVerification: string;
    verificationDate: string;
    partnerName: string;
    userBalance: number;
    kycVerified: boolean;
    blocked: boolean;
    tags?: any[] | null;
    bonusCode?: string | null;
    currency: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    gender: string;
    currencyCode: string;
}

export interface ICreditCardDetails {
    card: {
        expiry: string | any;
        pan: string;
        cvv: string;
        name_on_card: string;
        billing_address?: {
            line1?: string;
            line2?: string;
            city?: string;
            state?: string;
            postalCode?: string;
            country?: string;
        };
    };
}

export interface IMerchant extends IPlayerType, ICreditCardDetails {
    _id: string;
}

export interface ICleoFormDetails {
    transactionType?: string;
    pspType?: string;
}

export interface IProfileDataType {
    PlayerProfile: Record<string, any>;
    PaymentDetails: Record<string, any>;
}
export interface IPaymentProvidersOptions {
    _id?: PaymentMethodEnum;
    name?: PaymentMethodEnum;
}

export const playerProfileKeys = [
    'userBalance',
    'country',
    'countryCode',
    'bonusCode',
    'blocked',
    'kycVerified',
    'currency',
    'firstName',
    'lastName',
    'phone',
    'gender',
    'address',
    'zipcode',
    'email',
    'emailVerification',
    'verificationDate',
    'date_of_birth',
    'partnerName',
    'state',
    'transactionType',
    'pspType',
    'city',
];

export const paymentDetailsKeys = ['card'];

export enum TransactionTypeEnum {
    SELECT_TRANSACTION_TYPE = 'Select Transaction Type',
    CREDIT_CARD_DEPOSIT = 'CreditCard deposit',
    CREDIT_CARD_WITHDRAWALS = 'CreditCard Withdrawal',
    WEB_REDIRECT_DEPOSIT = 'WebRedirect deposit',
    WEB_REDIRECT_WITHDRAWALS = 'WebRedirect Withdrawal',
    LOCAL_BANK_WITHDRAWALS = 'Local Bank Withdrawals',
    BANK_WITHDRAWAL = 'Bank Withdrawal',
}

export enum TransactionTypeUppercaseEnum {
    CREDIT_CARD_DEPOSIT = 'CREDIT_CARD_DEPOSITE',
    CREDIT_CARD_WITHDRAWALS = 'CREDIT_CARD_WITHDRAWALS',
    WEB_REDIRECT_DEPOSIT = 'WEB_REDIRECT_DEPOSITE',
    WEB_REDIRECT_WITHDRAWALS = 'WEB_REDIRECT_WITHDRAWALS',
    LOCAL_BANK_WITHDRAWALS = 'LOCAL_BANK_WITHDRAWALS',
    BANK_WITHDRAWAL = 'BANK_WITHDRAWAL',
}

export enum PaymentMethodEnum {
    SELECT_PAYMENT_METHOD = 'Select Payment Method',
    TRUST = 'TRUST',
    CLEO = 'CLEO',
    PAYMODUM = 'PAYMODUM',
}

export const PaymentProviderOptions = [
    {
        _id: PaymentMethodEnum.TRUST,
        name: PaymentMethodEnum.TRUST,
    },
    { _id: PaymentMethodEnum.CLEO, name: PaymentMethodEnum.CLEO },
    {
        _id: PaymentMethodEnum.PAYMODUM,
        name: PaymentMethodEnum.PAYMODUM,
    },
];

export const transactionTypeOptions = [
    {
        _id: TransactionTypeUppercaseEnum.CREDIT_CARD_DEPOSIT,
        name: TransactionTypeEnum.CREDIT_CARD_DEPOSIT,
    },
    {
        _id: TransactionTypeUppercaseEnum.CREDIT_CARD_WITHDRAWALS,
        name: TransactionTypeEnum.CREDIT_CARD_WITHDRAWALS,
    },
    {
        _id: TransactionTypeUppercaseEnum.WEB_REDIRECT_DEPOSIT,
        name: TransactionTypeEnum.WEB_REDIRECT_DEPOSIT,
    },
    {
        _id: TransactionTypeUppercaseEnum.WEB_REDIRECT_WITHDRAWALS,
        name: TransactionTypeEnum.WEB_REDIRECT_WITHDRAWALS,
    },
    {
        _id: TransactionTypeUppercaseEnum.LOCAL_BANK_WITHDRAWALS,
        name: TransactionTypeEnum.LOCAL_BANK_WITHDRAWALS,
    },
    {
        _id: TransactionTypeUppercaseEnum.BANK_WITHDRAWAL,
        name: TransactionTypeEnum.BANK_WITHDRAWAL,
    },
];

export interface ICountry {
    cca2: string;
    name: {
        common: string;
    };
}
