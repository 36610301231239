import React, { useState } from 'react';
import { Handle, Position } from 'reactflow';
import { SvgIcon } from 'components/Common';
import { formatCamelCase } from 'data/utils/common';
import { CustomNodeProps, getTargetPos } from '../../RuleEngine.type';

export const CustomNode = ({ data }: CustomNodeProps) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const isAction = data.actionType === 'ACTION';

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const isTarget = (pos: string) => {
        return getTargetPos(data.srcPos) === pos;
    };

    const handlePos = `${getTargetPos(data.srcPos) as string}-${data.id}`;

    function isConnected(position: string) {
        return handlePos === position;
    }

    const handleRuleClose = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        e.stopPropagation();
        if (data.setCloseRuleModal && data.setCloseRuleNode) {
            data.setCloseRuleModal(true);
            data.setCloseRuleNode(data);
        }
    };
    return (
        <div
            key={data.id}
            className={`${
                isAction
                    ? 'min-h-10 border-solid border-[2px] border-[#3EACFA] text-[#3EACFA] rounded'
                    : 'border-solid border-[2px] border-black min-h-10 rounded'
            }`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {data.label} {data?.operation || '='}{' '}
            {typeof data?.items === 'object' &&
            !Array.isArray(data?.items) &&
            data?.items !== null
                ? Object.entries(data.items)
                      .map(
                          ([key, val]) =>
                              `${formatCamelCase(key)}: ${val as string}`
                      )
                      .join(', ')
                : Array.isArray(data?.items)
                ? data.items.join(', ')
                : data?.items}
            {isHovered && isAction && (
                <div
                    id={data.id}
                    className="!cursor-pointer"
                    onClick={handleRuleClose}
                >
                    <SvgIcon
                        icon="CROSS_DELETE_ICON"
                        className="absolute right-3 top-3"
                    />
                </div>
            )}
            {!isAction && (
                <>
                    <Handle
                        type={isTarget(Position.Right) ? 'target' : 'source'}
                        position={Position.Right}
                        id={`right-${data.id}`}
                        isConnectable
                    >
                        <div>
                            <SvgIcon
                                icon="ADD_ICON"
                                className="cursor-pointer pointer-events-none relative"
                            />
                            {isHovered && (
                                <div
                                    id={data.id}
                                    className="!cursor-pointer"
                                    onClick={handleRuleClose}
                                >
                                    <SvgIcon
                                        icon="CROSS_DELETE_ICON"
                                        className="absolute right-[50px]"
                                    />
                                </div>
                            )}
                        </div>
                    </Handle>
                    <Handle
                        type={isTarget(Position.Top) ? 'target' : 'source'}
                        position={Position.Top}
                        id={`top-${data.id}`}
                        isConnectable
                        className={
                            isConnected(`top-${data.id}`)
                                ? '!absolute !-top-1 !left-[-2px] !w-0 !min-w-0 !border-none'
                                : ''
                        }
                    >
                        <div>
                            <SvgIcon
                                icon="ADD_ICON"
                                className={`cursor-pointer ${
                                    isConnected(`top-${data.id}`)
                                        ? '!opacity-0'
                                        : ''
                                } pointer-events-none relative`}
                            />
                        </div>
                    </Handle>
                    <Handle
                        type={isTarget(Position.Bottom) ? 'target' : 'source'}
                        position={Position.Bottom}
                        id={`bottom-${data.id}`}
                        isConnectable
                        className={
                            isConnected(`bottom-${data.id}`)
                                ? '!absolute !top-[60%] !left-[-2px] !w-0 !min-w-0 !border-none'
                                : ''
                        }
                    >
                        <div>
                            <SvgIcon
                                icon="ADD_ICON"
                                className={`cursor-pointer ${
                                    isConnected(`bottom-${data.id}`)
                                        ? '!opacity-0'
                                        : ''
                                } pointer-events-none relative`}
                            />
                        </div>
                    </Handle>
                </>
            )}
            <Handle
                type="target"
                position={Position.Left}
                id={`left-${data.id}`}
                isConnectable
                className="!absolute !top-[50%] !left-[-2px] !w-0 !min-w-0 !border-none"
            >
                <div>
                    <SvgIcon
                        icon="ADD_ICON"
                        className="cursor-pointer !opacity-0 pointer-events-none relative"
                    />
                    <div id="draggable" className="!cursor-pointer">
                        <SvgIcon
                            icon="BIG_DRAG_ICON"
                            className="stroke-[#383838] absolute left-1"
                        />
                    </div>
                </div>
            </Handle>
        </div>
    );
};
