import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { showToast } from 'data/utils/toast';
import classNames from 'classnames';
import { IClipboardPropsTypes } from '../common';
import SvgIcon from '../Icon/SvgIcon';
import './clipboard.css';

const Clipboard = ({
    text,
    handleOnCopy,
    title,
    className,
    hidden,
    isPassword,
}: IClipboardPropsTypes) => {
    const [passwordType, setPasswordType] = useState('password');
    const togglePassword = () => {
        if (passwordType === 'password') {
            setPasswordType('text');
            return;
        }
        setPasswordType('password');
    };
    return (
        <>
            <p className="text-gray-500">{title}</p>
            <CopyToClipboard
                text={text}
                onCopy={
                    handleOnCopy ||
                    ((_text: string | number, result: boolean) => {
                        if (result) {
                            showToast('Copied', 'info');
                        }
                    })
                }
            >
                <div
                    className={`
                        flex w-72 items-center relative text-sm text-[#A4A4A4]  p-3 rounded-lg bg-[#F5F5F5] cursor-pointer ${
                            className as string
                        }`}
                >
                    {passwordType === 'password' && isPassword ? (
                        <input
                            readOnly
                            className="bg-tranparent clipboard-input h-[18px]"
                            type="password"
                            value="This text looks like dots"
                        />
                    ) : (
                        <span
                            className={classNames(
                                'whitespace-nowrap overflow-hidden text-ellipsis',
                                hidden ? 'pr-5' : ''
                            )}
                        >
                            {text}
                        </span>
                    )}
                    {hidden && (
                        <SvgIcon
                            icon={
                                passwordType === 'password'
                                    ? 'HIDDEN_PASSWORD'
                                    : 'SHOW_PASSWORD'
                            }
                            onClick={(e) => {
                                e.stopPropagation();
                                togglePassword();
                            }}
                            style={{
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                left: 'auto',
                                right: '10px',
                            }}
                        />
                    )}
                </div>
            </CopyToClipboard>
        </>
    );
};

export default Clipboard;
