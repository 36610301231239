import React, { useEffect, useState } from 'react';
import { Select, SvgIcon, TextField } from 'components/Common';
import useFetchCountries from 'hooks/useFetchCountries';
import useGetMerchantIds from 'hooks/useGetMerchantIds';
import { getInputField } from './PreconditionUtill';
import {
    IConditionDataProps,
    IPreconditionDropDown,
    ISelectedValueType,
    operators,
} from '../../../RuleEngine.type';

export const PreconditionDropdown = ({
    handleDelete,
    data,
    setData,
    getPreConditionDataList,
    setGetPreConditionDataList,
    setTitle,
}: IPreconditionDropDown) => {
    const [selectedOperators, setSelectedOperators] = useState<string[]>(
        Array(data.length).fill('')
    );
    const { countries } = useFetchCountries();
    const Mids = useGetMerchantIds();

    const handleMultiSelect = (selectedLists: any, index: number) => {
        const list = (selectedLists || []).map(
            (value: ISelectedValueType) => value.id
        );
        const newArray = [...data];
        newArray[index].items = list;
        setData(newArray);
        const tempList = [...getPreConditionDataList];
        tempList[index].values = list;
        setGetPreConditionDataList(tempList);
    };

    const handleChange = (
        index: number,
        value: string | number | string[] | undefined
    ) => {
        const newArray = [...data];
        const tempList = [...getPreConditionDataList];
        if (operators.includes(value as string)) {
            newArray[index].operator = value as string;
            setData(newArray);
            tempList[index].operator = value as string;
            setGetPreConditionDataList(tempList);
            setSelectedOperators((prevState) => {
                const newState = [...prevState];
                newState[index] = value as string;
                return newState;
            });
            return;
        }
        newArray[index].items = value;
        setData(newArray);
        tempList[index].values = value;
        setGetPreConditionDataList(tempList);
    };
    useEffect(() => {
        getPreConditionDataList.map((v, index) => {
            if (v.operator) {
                setSelectedOperators((prevState) => {
                    const newState = [...prevState];
                    newState[index] = v.operator as string;
                    return newState;
                });
            }
            return null;
        });
    }, [getPreConditionDataList]);

    const getDropdownOptions = (title: string) => {
        if (title.toLowerCase().includes('amount')) {
            return operators.map((operator) => ({
                name: operator,
            }));
        }
        return operators
            .filter((op) => op === '=' || op === '!=')
            .map((operator) => ({
                name: operator,
            }));
    };

    return (
        <div className="col-span-4 pt-2.5">
            {data.length > 0 &&
                data?.map((v: IConditionDataProps, index) => {
                    return (
                        <div className="flex w-full items-start" key={index}>
                            <div className="flex max-w-[230px] w-full">
                                <TextField
                                    key={v.title}
                                    fullWidth
                                    type="text"
                                    className="text-gray-400 min-w-[80px] truncate w-full !bg-white !rounded-lg"
                                    value={v.title ?? ''}
                                    disabled
                                />
                            </div>
                            <div className="flex items-baseline w-full">
                                <div>
                                    <Select
                                        isCompanyAdmin
                                        isMarginBottom={false}
                                        isGroup
                                        fullWidth
                                        bordered
                                        options={getDropdownOptions(v.title)}
                                        value={selectedOperators[index]}
                                        handleOnSelect={(e) => {
                                            handleChange(index, e.target.value);
                                        }}
                                        className="!border-2 !w-[70px] !mx-5 hover:border-[#9ed79f] border-gray-200 text-black h-[50px] !rounded-lg"
                                    />
                                </div>
                                <div className="flex w-full">
                                    {getInputField(
                                        v,
                                        index,
                                        handleMultiSelect,
                                        handleChange,
                                        countries,
                                        Mids
                                    )}
                                </div>
                                <span>
                                    <SvgIcon
                                        icon="DELETE_ICON_2"
                                        className="stroke-2 stroke-[#FFFFFF] mx-2.5 cursor-pointer"
                                        onClick={() => {
                                            handleDelete(v.title);
                                            setTitle(v.title);
                                        }}
                                    />
                                </span>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};
