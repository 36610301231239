import { ChangeEvent } from 'react';
import { PrimaryButton, Select, Tooltip } from 'components/Common';
import { bulkActionForGroups } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { BulkOptions, IGroupsHeaderProps } from '../Groups.type';

export const GroupsHeader = ({
    getGroups,
    checkedGroups,
    setCheckedGroups,
    openDrawer,
    isAllowedUser,
}: IGroupsHeaderProps) => {
    const handledSubmit = (e: ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value === '1') return;
        bulkActionForGroups({
            groupIds: checkedGroups,
            type: e.target.value.toLocaleUpperCase(),
        })
            .then((res) => {
                if (res.isSuccess) {
                    getGroups();
                    showToast(res.message);
                    setCheckedGroups([]);
                }
            })
            .catch((err) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    return (
        <div className="mb-5 flex w-full items-center justify-between max-[970px]:flex-col">
            <div className="font-merri-bold tracking-tighter text-2xl text-[#131119]">
                Groups
            </div>
            <div className="flex items-center gap-4">
                <Tooltip
                    text={
                        checkedGroups?.length === 0
                            ? 'Please select first the Mids from the table that you want to edit'
                            : ''
                    }
                    className="w-[150px]"
                >
                    <Select
                        isDisabled={!checkedGroups?.length}
                        isGroup
                        isMarginBottom={false}
                        options={BulkOptions}
                        handleOnSelect={handledSubmit}
                        className="w-full max-w-[150px] !pr-2 !border-2 !border-[#818181] !rounded-[12px] px-4 !text-xs !h-11"
                    />
                </Tooltip>
                <div className="w-[120px]">
                    <PrimaryButton
                        disabled={!isAllowedUser}
                        onClick={openDrawer}
                        type="button"
                        name="Add New Group"
                        color="#8FB131"
                        variant="filled"
                        className="!rounded-xl px-4 !w-[120px] !text-xs !h-11"
                    />
                </div>
            </div>
        </div>
    );
};
