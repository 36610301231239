import { ChangeEvent, useMemo, useState } from 'react';
import { Pagination, Table } from 'components/Common';
import { BrandsTableColumn } from './BrandsTableUtill';
import { BrandTableProps } from '../Brads.type';

export const BrandsTable = ({
    brands,
    checkedBrands,
    setCheckedBrands,
    setBrands,
    setBrand,
    setIsOpen,
    setIsEditBrandsDrawer,
    getUsers,
    isAllowedUser,
}: BrandTableProps) => {
    const [pageLimit, setPageLimit] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState(1);

    const isAllChecked =
        brands?.length !== 0 && brands?.length === checkedBrands?.length;

    const currentTableData = useMemo(
        () =>
            (brands || [])?.slice(
                currentPage * pageLimit - pageLimit,
                currentPage * pageLimit
            ) || [],
        [brands, currentPage, pageLimit]
    );

    const updatedBrandStatus = (id: string, status: boolean) => {
        const filter = brands.map((v) => {
            if (v._id === id) {
                return {
                    ...v,
                    isDeleted: status,
                };
            }
            return v;
        });
        setBrands([...filter]);
    };

    const handleOnSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        if (currentTableData?.length < parseInt(e.target.value)) {
            setCurrentPage(1);
        }
        setPageLimit(parseInt(e.target.value));
    };

    return (
        <div>
            <div className="max-h-[calc(100vh-250px)] !overflow-y-scroll !overflow-x-scroll dataTable rounded-xl">
                <div className="flex w-full max-w-full items-center">
                    <Table
                        data={currentTableData}
                        columns={BrandsTableColumn(
                            isAllChecked,
                            setCheckedBrands,
                            checkedBrands,
                            updatedBrandStatus,
                            setBrand,
                            setIsOpen,
                            getUsers,
                            setIsEditBrandsDrawer,
                            brands,
                            isAllowedUser
                        )}
                        className="w-full"
                        colSpan={11}
                    />
                </div>
            </div>
            <div className="mt-2">
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={brands?.length}
                    pageSize={pageLimit}
                    onPageChange={(page: number) => setCurrentPage(page)}
                    siblingCount={1}
                    handleOnSelect={handleOnSelect}
                    text="Show Brands"
                />
            </div>
        </div>
    );
};
