import React from 'react';
import OtherInformation from '../../PaymentMethods/Simulator/Common/OtherInformation/OtherInformation';
import BillingAddress from '../../PaymentMethods/Simulator/Common/BillingAddress/BillingAddress';
import UserInformation from '../../PaymentMethods/Simulator/Common/UserInformation/UserInformation';

const PlayerProfile = ({
    control,
    handleOnSubmit,
}: {
    control: any;
    handleOnSubmit: () => void;
}) => (
    <form className="w-full" onSubmit={handleOnSubmit}>
        <UserInformation
            control={control?.control}
            pspType="ONBOARD_CASHIER"
            setValue={control?.setValue}
        />
        <BillingAddress
            control={control?.control}
            setValue={control?.setValue}
        />
        <OtherInformation
            control={control?.control}
            setValue={control?.setValue}
            pspType="ONBOARD_CASHIER"
        />
    </form>
);

export default PlayerProfile;
