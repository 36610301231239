import classnames from 'classnames';
import { IOptionProps } from 'data/common';
import { usePagination, DOTS } from './usePagination';
import './pagination.css';
import { Select } from '../index';
import { IPaginationProps } from '../common';

const options: IOptionProps[] = [
    { key: '10', title: '10' },
    { key: '20', title: '20' },
    { key: '30', title: '30' },
];

const Pagination = ({
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
    handleOnSelect,
    text = 'Show Data',
}: IPaginationProps) => {
    const paginationRange: (string | number)[] | any = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize,
    });

    if (currentPage === 0) {
        return null;
    }
    const onPrevious = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    };

    const lastPage = paginationRange[paginationRange.length - 1];

    const onNext = () => {
        if (currentPage === lastPage) return;
        if (currentPage !== lastPage) {
            onPageChange(currentPage + 1);
        }
    };

    return (
        <div className="flex justify-between">
            <div className="flex items-center">
                <div
                    className={classnames('pagination-container', {
                        [className]: className,
                    })}
                >
                    <div
                        className={classnames('pagination-item', {
                            disabled: currentPage === 1,
                        })}
                        onClick={onPrevious}
                    >
                        <div
                            className="arrow"
                            style={{
                                transform: 'rotate(-135deg)',
                            }}
                        />
                    </div>
                    {paginationRange.map((pageNumber: any) => {
                        if (pageNumber === DOTS) {
                            return (
                                <div className="pagination-item dots">
                                    &#8230;
                                </div>
                            );
                        }

                        return (
                            <div
                                key={pageNumber}
                                className={classnames('pagination-item', {
                                    'bg-green-200 !text-white font-bold':
                                        pageNumber === currentPage,
                                })}
                                onClick={(e) => {
                                    const eventTarget = e.target as HTMLElement;
                                    onPageChange(
                                        parseInt(eventTarget.innerText)
                                    );
                                }}
                            >
                                {pageNumber}
                            </div>
                        );
                    })}
                    <div
                        className={classnames('pagination-item', {
                            'hover:bg-none': currentPage === lastPage,
                        })}
                        onClick={onNext}
                    >
                        <div
                            className="arrow"
                            style={{ transform: 'rotate(45deg)' }}
                        />
                    </div>
                </div>
            </div>
            <div className="flex items-center gap-4">
                <div className="text-sm font-poppins-regular tracking-tight">
                    {text}
                </div>
                <Select
                    isMarginBottom={false}
                    className="!bg-white text-xs !py-0 !w-[65px] !rounded-[10px] text-start !border-2 !border-gray-500 hover:border-gray-500 hover:border-2 focus:border-gray-500 focus:border-2 h-8"
                    options={options}
                    value={String(pageSize)}
                    handleOnSelect={(e) => handleOnSelect(e)}
                />
            </div>
        </div>
    );
};

export default Pagination;
