import { TableHeader } from './TableHeader';
import TableRow from './TableRow';
import './table.css';
import { ITableProps } from '../common';

export const get = (
    obj: any,
    path: string,
    defaultValue: string | number | undefined = undefined
): string | number | undefined => {
    const keys = path.split('.');
    let result = obj;

    for (const list of keys) {
        if (result === null || result === undefined) {
            return defaultValue;
        }
        result = result[list];
    }

    return result === undefined ? defaultValue : (result as string | number);
};

export default function Table<T>({
    data,
    columns,
    className,
    isAdminUserModal,
    isSidebarTable,
    isAuditHistoryTable,
    isTransactionDetailsTable,
    handleRowClick,
    colSpan,
    setFilterData,
    filterData,
}: ITableProps<T>): JSX.Element {
    const requestSort = (key: string) => {
        if (key === 'checked') return;
        if (setFilterData && filterData) {
            setFilterData({
                ...filterData,
                sortKey: key === 'city' ? 'address.city' : key,
            });
        }
    };

    return (
        <table
            className={`${isSidebarTable ? '' : 'tableSection'} ${
                className as string
            }`}
        >
            <thead className="sticky top-0 z-10">
                <TableHeader
                    isAuditHistoryTable={isAuditHistoryTable}
                    isSidebarTable={isSidebarTable}
                    columns={columns}
                    onSort={requestSort}
                />
            </thead>
            <tbody className="relative">
                <TableRow
                    isAdminUserModal={isAdminUserModal}
                    handleRowClick={(e) => {
                        if (handleRowClick) {
                            handleRowClick(e, true);
                        }
                    }}
                    isTransactionDetailsTable={isTransactionDetailsTable}
                    isAuditHistoryTable={isAuditHistoryTable}
                    isSidebarTable={isSidebarTable}
                    data={data}
                    columns={columns}
                    colSpan={colSpan}
                />
            </tbody>
        </table>
    );
}
