import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PrimaryButton } from 'components/Common';
import {
    getPaymodumeTransaction,
    processPaymodumPaymentPayout,
} from 'services/api/api';
import { showToast } from 'data/utils/toast';
import {
    generateRandomData,
    PaymentProviderConstant,
} from 'data/constants/payment.constants';
import useFetchCountries from 'hooks/useFetchCountries';
import { useAuthContext } from 'context/Auth/AuthContext';
import useFilteredBrandData from 'hooks/useFilteredBrandData';
import { IProviderIdListData } from './MerchantPayout.type';
import { payModumFormValidation } from './MerchantPayout.shcema';
import { IPayModumeType } from '../PayIn/Merchant.type';
import UserInformation from '../Common/UserInformation/UserInformation';
import BillingAddress from '../Common/BillingAddress/BillingAddress';
import OtherInformation from '../Common/OtherInformation/OtherInformation';
import CardDetails from '../Common/CardDetails/CardDetails';
import TransactionVelocity from '../Common/TransactionVelocity/TransactionVelocity';

interface IProviderTransactionType {
    id: string;
    name: string;
}

const Paymodum = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [providerIdList, setProviderIdList] = useState<IProviderIdListData[]>(
        []
    );

    const { control, handleSubmit, reset, setValue } = useForm<IPayModumeType>({
        resolver: zodResolver(payModumFormValidation),
        defaultValues: {
            blocked: false,
            kycVerified: false,
            isVelocity: false,
        },
    });
    const { countries } = useFetchCountries();
    const { auth } = useAuthContext();
    const brands = useFilteredBrandData(auth, PaymentProviderConstant.PAYMODUM);

    const getPaymodumTransactionData = () => {
        getPaymodumeTransaction({
            status: 'Captured',
        })
            .then((res) => {
                const resList: IProviderTransactionType[] =
                    res.transactions.map((item: { _id: string }) => {
                        return {
                            id: item?._id,
                            name: item?._id,
                        } as IProviderTransactionType; // Change "example name" to the desired name value
                    });
                setProviderIdList([
                    { id: '', name: 'Select Transaction provider Id' },
                    ...resList,
                ]);
            })
            .catch((err) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    useEffect(() => {
        getPaymodumTransactionData();
    }, []);

    const handleOnSubmit = handleSubmit((formData) => {
        if (!formData) return;
        setLoading(true);
        const payload = {
            ...formData,
            tags: formData?.tags || [],
            bonusCode: formData?.bonusCode || '',
            card: {
                ...formData.card,
                expiry: {
                    month: formData.card.expiry.split('/')[0],
                    year: formData.card.expiry.split('/')[1],
                },
                billing_address: {
                    ...formData.address,
                    postal_code: formData.address.postalCode,
                },
            },
            baseamount: parseFloat(formData.baseamount),
            country: formData.address.country,
        };

        processPaymodumPaymentPayout(payload)
            .then((res) => {
                if (res.isSuccess) {
                    reset({});
                    showToast(res?.message, 'success');
                    setLoading(false);
                }
            })
            .catch((err: any) => {
                setLoading(false);
                showToast(err.message || 'something went wrong', 'error');
            });
    });

    return (
        <>
            <div className="flex w-full items-center justify-end">
                <PrimaryButton
                    color="#8FB131"
                    variant="filled"
                    name="Generate Data"
                    onClick={() =>
                        generateRandomData(
                            PaymentProviderConstant.PAYMODUM,
                            reset,
                            countries,
                            brands
                        )
                    }
                    className="!h-[45px] w-fit px-4"
                />
            </div>
            <form className="w-full" onSubmit={handleOnSubmit}>
                <div>
                    <UserInformation
                        control={control}
                        pspType={PaymentProviderConstant.PAYMODUM}
                        setValue={setValue}
                    />
                    <CardDetails control={control} />
                    <BillingAddress control={control} setValue={setValue} />
                    <OtherInformation
                        control={control}
                        setValue={setValue}
                        pspType={PaymentProviderConstant.PAYMODUM}
                        providerIdList={providerIdList}
                    />
                    <TransactionVelocity control={control} />
                    <div className="mt-10">
                        <PrimaryButton
                            type="submit"
                            isDrawerButton
                            className={classNames('w-full max-w-[224px]')}
                            color="#2E672F"
                            variant="filled"
                            name={loading ? '' : 'Submit'}
                            loading={loading}
                            disabled={loading}
                        />
                    </div>
                </div>
            </form>
        </>
    );
};

export default Paymodum;
