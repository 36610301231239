import React, { useState } from 'react';
import classNames from 'classnames';
import { PrimaryButton } from 'components/Common';
import { britePaymentPayOut } from 'services/api/api';
import { showToast } from 'data/utils/toast';

const Brite = () => {
    const [loading, setLoading] = useState<boolean>(false);

    const handleOnSubmit = () => {
        setLoading(true);
        britePaymentPayOut()
            .then((res) => {
                if (res.status === 'success') {
                    if (res.data.url) {
                        window.open(res.data.url, '_blank');
                    }
                    showToast(res?.message, 'success');
                    setLoading(false);
                }
            })
            .catch((err) => {
                showToast(
                    typeof err.message === 'string'
                        ? err.message
                        : 'something went wrong',
                    'error'
                );
                setLoading(false);
            });
    };

    return (
        <div className="mt-10">
            <PrimaryButton
                type="submit"
                isDrawerButton
                className={classNames('w-[224px]')}
                color="#2E672F"
                variant="filled"
                name={loading ? '' : 'Submit'}
                onClick={handleOnSubmit}
                disabled={loading}
                loading={loading}
            />
        </div>
    );
};

export default Brite;
