import { Drawer, PrimaryButton } from 'components/Common';
import AlertImage from 'assets/images/alert.png';
import './AudtiDrawer.css';

interface IAuditDrawer {
    openDrawer: () => void;
    isOpen: boolean;
}

const AuditDrawer = ({ isOpen, openDrawer }: IAuditDrawer) => (
    <Drawer
        drawerSize={400}
        isOpen={isOpen}
        toggleDrawer={openDrawer}
        drawerDirection="right"
        className="bg-pink-100 overflow-y-scroll !h-[100%] rounded-r-md "
    >
        <div className="flex flex-col justify-center items-center p-2 mt-28 ">
            <img height="30%" width="30%" src={AlertImage} alt="not found" />
            <div className="mt-5 flex justify-center text-xl w-[70%] !font-black text-green-100">
                Are you sure you want to revert to this version?
            </div>
            <div className="flex flex-col gap-3 w-[60%] mt-5">
                <PrimaryButton
                    className="!h-[45px] !font-black"
                    color="#2E672F"
                    variant="filled"
                    name="Yes Continue"
                />
                <PrimaryButton
                    className="!h-[45px] !font-black"
                    color="#8FB131"
                    variant="filled"
                    name="Cancel"
                />
            </div>
        </div>
    </Drawer>
);

export default AuditDrawer;
