import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from 'components/Common';
import {
    deleteRule,
    getGroupRule,
    getPreconditionRule,
    savePreconditionRule,
    updateRuleOrder,
} from 'services/api/api';
import { showToast } from 'data/utils/toast';
import LoadingSpinner from 'components/Common/Loader/Loader';
import { useAuthContext } from 'context/Auth/AuthContext';
import DraggableList from 'react-draggable-list';
import { GroupRuleCard } from 'components/Common/GroupRuleCard/GroupRuleCard';
import ConfirmationModal from 'components/Modal/ConfirmationModal/ConfirmationModal';
import Preconditions from './Precondition/Preconditions';
import {
    getOperatorSymbol,
    ICardPaymentRuleEngine,
    IPreConditionData,
} from '../RuleEngine.type';

const CardPaymentRuleEngine = ({
    groupId,
    handleDeleteGroupRule,
    groupRuleData,
}: ICardPaymentRuleEngine) => {
    const navigate = useNavigate();

    const [getPreConditionDataList, setGetPreConditionDataList] = useState<
        Array<IPreConditionData>
    >([]);
    const [showPreconditions, setShowPreconditions] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [groupCardData, setGroupCardData] = useState([]);
    const [groupRuleName, setGroupRuleName] = useState<string>('');
    const [error, setError] = useState('No Data Available');
    const { auth } = useAuthContext();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const getPreconditionRules = () => {
        setLoading(true);
        getPreconditionRule({
            groupId,
        })
            .then((res) => {
                const transformedData = res.preconditions?.map(
                    (item: IPreConditionData) => ({
                        key: item.key,
                        values: item.values,
                        operator: getOperatorSymbol(item.operator as string),
                    })
                );
                setGetPreConditionDataList(transformedData);
                setShowPreconditions(transformedData.length > 0);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    useEffect(() => {
        const hasSelectedCompanyId = !!auth.selectedCompany?._id;
        const hasCompanyId = !!auth?.authUser?.company;

        if (!hasSelectedCompanyId) {
            if (!hasCompanyId) {
                setGroupRuleName('');
                setError('Please select company or brand');
                setShowPreconditions(false);
            } else {
                setError(
                    getPreConditionDataList.length > 0 ||
                        groupCardData.length > 0
                        ? ''
                        : 'No data available'
                );
            }
        } else {
            const ruleName = groupRuleData.find(
                (data: any) => data._id === groupId
            );
            setGroupRuleName(ruleName?.name ?? '');
            setError(
                getPreConditionDataList.length > 0 || groupCardData.length > 0
                    ? ''
                    : 'No data available'
            );
        }
    }, [auth, groupRuleData, groupCardData, groupId, getPreConditionDataList]);

    const handleSubmit = () => {
        if (!groupId) return;
        const convertedArray = getPreConditionDataList?.map((item) => {
            const newObj: {
                [key: string]: string[] | string | number | undefined;
            } = {};
            if (Array.isArray(item.values)) {
                newObj[item.key] = item.values.map((value) => value);
            } else {
                newObj[item.key] = item.values;
            }
            newObj.operator = item.operator || '=';
            return newObj;
        });

        const hasInvalidData = convertedArray.some((item) => {
            return Object.values(item).some((value) => {
                return (
                    value === '' || // Check for empty string
                    (Array.isArray(value) && // Check for array
                        (value.length === 0 || // Array is empty
                            value[0] === '' || // 0th index is an empty string
                            value[1] === '')) // 1st index is an empty string
                );
            });
        });

        if (hasInvalidData) {
            showToast('Please fill all fields', 'error');
            return;
        }

        const payload = {
            preconditions: convertedArray,
            groupId,
        };
        savePreconditionRule(payload)
            .then(() => {
                showToast('Successfully saved precondition', 'success');
                getPreconditionRules();
            })
            .catch((err) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
        setShowPreconditions(false);
    };

    const getRuleData = () => {
        getGroupRule(groupId)
            .then((res) => {
                const sortedData = res?.rules.sort(
                    (a: any, b: any) => a.order - b.order
                );
                setGroupCardData(sortedData);
            })
            .catch((err) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    useEffect(() => {
        if (!groupId) return;
        const ruleName = groupRuleData.find(
            (data: any) => data._id === groupId
        );
        setGroupRuleName(ruleName?.name);
        getPreconditionRules();
        getRuleData();
    }, [groupId]);

    const containerRef = useRef<any>();
    const onListChange = (newList: any) => {
        newList.forEach((item: any, index: number) => {
            item.order = index;
        });
        const payload = newList.map((ele: any, i: number) => ({
            ruleId: ele?._id,
            order: i,
        }));

        const dataToUpdate = {
            rules: [...payload],
        };

        updateRuleOrder(dataToUpdate)
            .then((res) => {
                console.log(res);
            })
            .catch((err: any) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
        setGroupCardData(newList);
    };
    const [item, setItem] = useState<any>();
    const handleRuleDelete = () => {
        setIsLoading(true);
        deleteRule(item?._id)
            .then((res: any) => {
                showToast(res?.message, 'success');
                getRuleData();
            })
            .catch((err: any) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            })
            .finally(() => {
                setIsLoading(false);
                setIsDeleteModalOpen(false);
            });
    };

    const handlePreconditionClick = () => {
        if (!groupId) return;
        if (!showPreconditions && !!groupId) {
            setShowPreconditions(true);
        } else {
            handleSubmit();
        }
    };

    const handleRuleAdd = () => {
        if (!groupId) {
            showToast('Please create a group.', 'error');
            return;
        }
        navigate(
            `/workflows/orchestration/rule-builder?groupsId=${groupId}&&order=${groupCardData.length}`
        );
    };

    return (
        <div className="h-[calc(100vh-100px)] overflow-y-scroll">
            {isDeleteModalOpen && (
                <ConfirmationModal
                    onConfirmation={handleRuleDelete}
                    message={`Are you sure you want to delete ${
                        item?.name as string
                    } ?`}
                    setIsModalOpen={setIsDeleteModalOpen}
                    isLoading={isLoading}
                />
            )}
            <div className="sticky top-0 z-50 py-5 flex flex-wrap items-center justify-between bg-gray-700 px-5">
                <p className="m-0 text-lg font-poppins-bold text-[#8FB131]">
                    {groupRuleData.length > 0 && groupRuleName}
                </p>
                {(!!auth?.selectedCompany?._id ||
                    auth.isAdmin ||
                    auth.isUser) && (
                    <span className="flex items-center gap-3">
                        <PrimaryButton
                            color="#2E672F"
                            variant="outline"
                            type="button"
                            name={
                                showPreconditions && !!groupId
                                    ? 'Save Precondition'
                                    : '+ Preconditions'
                            }
                            className="border-solid !border-2 !font-bold !rounded-xl !px-3 !h-10"
                            onClick={handlePreconditionClick}
                        />
                        {groupRuleData.length > 0 && (
                            <PrimaryButton
                                color="#2E672F"
                                variant="outline"
                                type="button"
                                name="Delete Group"
                                icon="DELETE_ICON_2"
                                iconPosition="left"
                                iconStroke="#2E672F"
                                className="border-solid !border-2 !font-bold !rounded-xl !ps-5 !h-10 !w-36"
                                onClick={handleDeleteGroupRule}
                            />
                        )}
                        <PrimaryButton
                            color="#8FB131"
                            variant="filled"
                            type="button"
                            name=" + Add Rule"
                            onClick={handleRuleAdd}
                            className="border-solid !border-2 !font-bold !rounded-xl !px-3 !h-10 !w-28"
                        />
                    </span>
                )}
            </div>
            <div className="relative">
                {loading ? (
                    <div className="mt-5">
                        <LoadingSpinner />
                    </div>
                ) : (
                    <>
                        {showPreconditions && !!groupId && (
                            <Preconditions
                                showPreconditions={showPreconditions}
                                setGetPreConditionDataList={
                                    setGetPreConditionDataList
                                }
                                getPreConditionDataList={
                                    getPreConditionDataList
                                }
                            />
                        )}

                        {groupCardData.length > 0 &&
                        groupRuleData.length > 0 &&
                        !error ? (
                            <div
                                ref={containerRef}
                                style={{ touchAction: 'pan-y' }}
                            >
                                <DraggableList
                                    itemKey="_id"
                                    unsetZIndex
                                    template={GroupRuleCard as any}
                                    list={groupCardData}
                                    onMoveEnd={(newList: any) =>
                                        onListChange(newList)
                                    }
                                    commonProps={{
                                        getRuleData,
                                        setItem,
                                        setIsDeleteModalOpen,
                                    }}
                                    container={() =>
                                        containerRef.current as HTMLElement | null
                                    }
                                />
                            </div>
                        ) : (
                            error && (
                                <div className="flex items-center justify-center py-10 text-center">
                                    <div className="flex flex-col gap-6">
                                        <div className="text-4xl font-poppins-bold tracking-tight font-extrabold text-gray-500">
                                            {error}
                                        </div>
                                    </div>
                                </div>
                            )
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default CardPaymentRuleEngine;
