import { ChangeEvent, useState } from 'react';
import LoadingSpinner from 'components/Common/Loader/Loader';
import { Pagination, Table } from 'components/Common';
import { IApprovalTable } from '../Approval.type';
import { approvalTableColumn } from './ApprovalColumnUtill';

export const ApprovalTable = ({
    setCheckedApprovals,
    checkedApprovals,
    loading,
    currentPage,
    approvalData,
    setCurrentPage,
    setFilterData,
    filterData,
    totalPage,
}: IApprovalTable) => {
    const [pageLimit, setPageLimit] = useState<number>(10);

    const handleOnSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        if (approvalData?.length < parseInt(e.target.value)) {
            setCurrentPage(1);
        }
        setPageLimit(parseInt(e.target.value));
        setFilterData({
            ...filterData,
            limit: parseInt(e.target.value),
            page: 1,
        });
    };

    const isAllChecked =
        approvalData?.length > 0 &&
        approvalData?.length === checkedApprovals?.length;

    return (
        <div>
            <div className="max-h-[calc(100vh-284px)] !overflow-y-scroll !overflow-x-scroll dataTable rounded-xl mt-5">
                <div className="flex h-full w-full items-center">
                    {loading ? (
                        <div className="flex h-full w-full items-center justify-center">
                            <LoadingSpinner />
                        </div>
                    ) : (
                        <Table
                            isTransactionDetailsTable
                            data={approvalData}
                            columns={approvalTableColumn(
                                isAllChecked,
                                setCheckedApprovals,
                                approvalData,
                                checkedApprovals
                            )}
                            className="w-full"
                            filterData={filterData}
                            setFilterData={setFilterData}
                            colSpan={15}
                        />
                    )}
                </div>
            </div>
            <div className="mt-2">
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={totalPage || 0}
                    pageSize={pageLimit}
                    onPageChange={(page: number) => {
                        setFilterData({ ...filterData, page });
                        setCurrentPage(page);
                    }}
                    siblingCount={1}
                    handleOnSelect={handleOnSelect}
                    text="Show Approvals"
                />
            </div>
        </div>
    );
};
