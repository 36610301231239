import { PaymentProviderConstant } from '../constants/payment.constants';

export const getTransactionStatus = (transaction: any): string => {
    if (
        transaction.pspType === PaymentProviderConstant.CLEO ||
        transaction.pspType === PaymentProviderConstant.PAYMODUM
    ) {
        return (transaction.status || 'N/A') as string;
    }
    if (transaction.pspType === PaymentProviderConstant.TRUST) {
        switch (transaction.settlestatus) {
            case '0':
                return 'PENDING';
            case '1':
                return 'MANUAL';
            case '2':
                return 'SUSPENDED';
            case '3':
                return 'CANCELLED';
            case '10':
                return 'SETTING';
            case '100':
                return 'SUCCESS';
            default:
                return 'ERROR';
        }
    }
    if (transaction.pspType === PaymentProviderConstant.BRITE) {
        switch (transaction.transactionState) {
            case 0:
            case 1:
            case 2:
                return 'PENDING';
            case 10:
                return 'CANCELLED';
            case 11:
                return 'FAILED';
            case 12:
                return 'SUCCESS';
            default:
                return 'ERROR';
        }
    }
    return '';
};
