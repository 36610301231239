import React from 'react';
import { PrimaryButton } from 'components/Common';
import RFTextField from 'components/Common/RFTextField/RFTextField';
import { ICompaniesForm } from '../../Companies.type';

export const CompaniesForm = ({
    handleSubmit,
    control,
    companyLoading,
    company,
    isEditCompany,
}: ICompaniesForm) => (
    <form onSubmit={handleSubmit}>
        <div className="mt-5">
            <RFTextField
                control={control}
                name="companyName"
                fullWidth
                label="Company Name"
                type="text"
                placeholder="Enter Company Name"
                asterisk
            />
        </div>

        <div className="mt-4">
            <PrimaryButton
                disabled={companyLoading}
                isDrawerButton
                type="submit"
                className="w-full"
                color="#2E672F"
                variant="filled"
                name={
                    isEditCompany && company?._id
                        ? 'Update Company'
                        : 'Create New Company'
                }
            />
        </div>
    </form>
);
