import './loader.css';

const LoadingSpinner = () => (
    <div>
        <div className="main-loader" />
        <div className="text-[#2E672F] text-center m-auto w-[120px] mt-5">
            Loading...
        </div>
    </div>
);

export default LoadingSpinner;
