import React from 'react';
import { Control } from 'react-hook-form';
import { IPasswordData } from 'data/types/auth';
import { PrimaryButton } from 'components/Common';
import RFTextField from 'components/Common/RFTextField/RFTextField';
import SvgIcon from '../../Common/Icon/SvgIcon';

interface IResetPassword {
    isNewAdmin?: boolean;
    handleSubmit: () => void;
    control: Control<IPasswordData>;
}

export const ResetPassword = ({
    isNewAdmin,
    handleSubmit,
    control,
}: IResetPassword) => {
    return (
        <div className="flex flex-col justify-center items-start w-[500px] mx-auto">
            <div className="flex items-center text-[32px] text-green-100 font-merri-bold tracking-tighter w-full">
                {isNewAdmin ? 'Create your Password' : 'New Password'}
            </div>
            <div className="text-[16px] font-poppins-medium tracking-tight">
                {isNewAdmin
                    ? 'Enter your password to complete the onboarding process.'
                    : 'Enter your new password to begin again.'}{' '}
            </div>
            <form onSubmit={handleSubmit} className="w-full">
                <div className="flex flex-col w-[100%] pt-12">
                    <RFTextField
                        control={control}
                        name="password"
                        fullWidth
                        label="Enter Password"
                        type="password"
                        iconPosition="right"
                        asterisk
                    />
                    <div>
                        <RFTextField
                            control={control}
                            name="confirmPassword"
                            fullWidth
                            label="Confirm Password"
                            type="password"
                            iconPosition="right"
                            asterisk
                        />
                    </div>
                    <div>
                        <div className="flex items-center gap-2">
                            <SvgIcon icon="CHECK" />
                            <p className="text-xs text-[#C8C8C8] m-0">
                                Password must be at least 12 characters long
                            </p>
                        </div>
                        <div className="flex items-center gap-2">
                            <SvgIcon icon="CHECK" />
                            <p className="text-xs text-[#C8C8C8] m-0">
                                Password must contain both uppercase and
                                lowercase letters
                            </p>
                        </div>
                        <div className="flex items-center gap-2">
                            <SvgIcon icon="CHECK" />
                            <p className="text-xs text-[#C8C8C8] m-0">
                                Password must include at least one number
                            </p>
                        </div>
                        <div className="flex items-center gap-2">
                            <SvgIcon icon="CHECK" />
                            <p className="text-xs text-[#C8C8C8] m-0">
                                Password must include at least one special
                                character (e.g., !, @, #, $, %)
                            </p>
                        </div>
                    </div>
                    <PrimaryButton
                        type="submit"
                        name={isNewAdmin ? 'Set Password' : 'Reset Password'}
                        isDrawerButton
                        color="#2E672F"
                        variant="filled"
                        className="hover:bg-[#2E672F] hover:border-green-600 focus:border-green-600 mt-10 w-full font-medium pt-1"
                    />
                </div>
            </form>
        </div>
    );
};

export default ResetPassword;
