import React, { ChangeEvent } from 'react';
import { Control, Controller } from 'react-hook-form';
import {
    CardType,
    PaymentProviderConstant,
} from 'data/constants/payment.constants';
import { GenderList } from 'data/constants/common.constants';
import {
    DatePicker,
    RFSelectField,
    RFTextField,
    TagInput,
} from '../../../../Common';

export interface IControlTypes {
    control: Control<any>;
    pspType: string;
    setValue: any;
}

const UserInformation = ({ control, pspType, setValue }: IControlTypes) => (
    <>
        <div className="mt-3 mb-4 text-base font-bold tracking-tight text-black font-poppins-bold">
            User Information
        </div>
        <div className="grid grid-cols-2 items-center gap-x-6">
            <RFTextField
                control={control}
                name="firstName"
                fullWidth
                label="First Name"
                type="text"
                asterisk
            />
            <RFTextField
                control={control}
                name="lastName"
                fullWidth
                label="Last Name"
                type="text"
                asterisk
            />
            <RFTextField
                control={control}
                name="email"
                fullWidth
                label="Email"
                type="email"
                asterisk
            />
            {pspType === PaymentProviderConstant.PAYMODUM && (
                <RFSelectField
                    name="card.paymentMethod"
                    control={control}
                    fullWidth
                    bordered
                    asterisk
                    label="Payment Method"
                    options={[{ id: '', name: '-Select-' }, ...CardType]}
                />
            )}

            <RFTextField
                control={control}
                name="emailVerification"
                fullWidth
                label="Email Verification"
                type="text"
                placeholder="Email Verification"
                asterisk
            />
            <RFTextField
                control={control}
                name="phone"
                fullWidth
                label="Phone"
                type="number"
                asterisk
                noArrows
            />
            <Controller
                name="date_of_birth"
                control={control}
                render={({ field: { onChange, ...field }, fieldState }) => (
                    <DatePicker
                        label="Date of Birth"
                        asterisk
                        options={{
                            allowInput: true,
                            dateFormat: 'm-d-Y',
                            maxDate: 'today',
                        }}
                        placeholder="DD / MM / YYYY"
                        onChange={(_selectedDates: any, dateStr: any) => {
                            onChange(dateStr);
                        }}
                        isError={!!fieldState.error?.message}
                        errorMessage={fieldState.error?.message ?? ''}
                        {...field}
                    />
                )}
            />
            <Controller
                name="verificationDate"
                control={control}
                render={({ field: { onChange, ...field }, fieldState }) => (
                    <DatePicker
                        asterisk
                        label="Verification Date"
                        options={{
                            allowInput: true,
                            dateFormat: 'd-m-Y',
                        }}
                        placeholder="DD / MM / YYYY"
                        onChange={(_selectedDates: Date[], dateStr: string) => {
                            onChange(dateStr);
                        }}
                        isError={!!fieldState.error?.message}
                        errorMessage={fieldState.error?.message ?? ''}
                        {...field}
                    />
                )}
            />
            <RFTextField
                control={control}
                name="partnerName"
                fullWidth
                label="Partner Name"
                type="text"
                asterisk
                placeholder="Partner Name"
            />
            <RFTextField
                control={control}
                name="userBalance"
                fullWidth
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    setValue('userBalance', parseInt(e.target.value));
                }}
                label="User Balance"
                type="number"
                asterisk
                noArrows
                placeholder="User Balance"
            />
            <Controller
                name="tags"
                control={control}
                render={({ field: { value, ...field }, fieldState }) => (
                    <>
                        <TagInput
                            label="Categories"
                            placeholder="Press enter to add categories"
                            tags={value || []}
                            onTagsChange={(tags) => {
                                field.onChange(tags);
                            }}
                        />
                        {fieldState.error?.message ? (
                            <div className="mt-2 flex self-baseline text-xs font-medium text-red-200">
                                {fieldState.error?.message}
                            </div>
                        ) : null}
                    </>
                )}
            />
            <RFTextField
                control={control}
                name="bonusCode"
                fullWidth
                label="Bonus Code"
                type="number"
                noArrows
                placeholder="Bonus Code"
            />
            {pspType !== PaymentProviderConstant.ONBOARD_CASHIER && (
                <RFTextField
                    control={control}
                    name="baseamount"
                    fullWidth
                    label="Amount"
                    type="number"
                    noArrows
                    asterisk
                    maxLength={3}
                />
            )}

            <RFSelectField
                name="gender"
                bordered
                control={control}
                fullWidth
                asterisk
                label="Gender"
                options={GenderList}
            />
        </div>
    </>
);

export default UserInformation;
