import { z, ZodType } from 'zod';
import { IGroupDrawer } from './RuleEngine.type';

export const formSchemaValidation: ZodType<IGroupDrawer> = z
    .object({
        name: z
            .string({ required_error: 'Please enter your group' })
            .trim()
            .min(1, 'Please enter your group'),
    })
    .required();
